<template>
    <div class="separator-segment" />
    <div class="content-segment">
        <div class="container">
            <CourseFull :id="$route.params.id" />
        </div>
    </div>
</template>

<script>
import CourseFull from '@/components/CourseFull.vue';

export default {
    name: 'Person',
    components: {
        CourseFull,
    },
    computed: {
    },
    mounted() {
    }
}
</script>

