const messages = {
    en: {
        generic: {
            hidden: "Hidden",
            add: "Add",
            set: "Set",
            unset: "Unset",
            login: "Log In",
            cancel: "Cancel",
        },
        menu: {
            home: "Home",
            people: "People",
            publications: "Publications",
            teaching: "Teaching",
            settings: "Settings",
            heading: "Institute of Computer Science II",
            institute: "Visual Computing",
        },
        news: {
            title: "News",
        },
        jobs: {
            title: "Jobs",
        },
        people: {
            new: "New",
            person: "Person",
            groupleaders: "Group Leaders",
            adminoffice: "Administrative Office",
            researchstaff: "Research Staff",
            phdstudents: "PhD Students",
            alumni: "Alumni",
            formerstudentres: "Former Student Researchers",
            supervised: "Supervised Dissertations",
        },
        courses: {
            staff: "Staff",
            lecturers: "Lecturer(s)",
            tutors: "Tutor(s)",
            courses: "Courses",
            lecture: "Lecture",
            seminar: "Seminar",
            lab: "Lab Course",
            projectgroup: "Project Group",
            course: "Course",
            exercises: "Exercises",
            year: "Year",
            website: "Website",
            start: "Start",
            dates: "Dates",
            coursenr: "Course number",
            curriculum: "Curriculum",
            effort: "Effort",
            exams: "Exams",
            description: "Description",
            news: "News",
            literature: "Literature",
            requirements: "Requirements",
            diploma: "Diploma",
            noyear: "Year must not be left empty",
            auth: "Authentication",
            authwarn: "No user/password set. Materials can be downloaded by anyone!",
            autherruser: "Username must not be empty!",
            autherrpwd: "Password must not be empty!",
            authsuccess: "Password sucessfully set.",
            authunsetsuccess: "Password sucessfully unset.",
            slides: "Lecture Slides",
            sheets: "Exercise Sheets",
            miscmat: "Additional Materials",
            matlogininfo: "You must provide login credentials to see these materials:",
            del: "Delete Course",
        },
        publications: {
            publications: "Publications",
        },
        settings: {
            heading: "Site Settings",
            users: "Users",
            username: "Username",
            canedit: "Edit Pages",
            isadmin: "Admin",
        },
    },
    de: {
        generic: {
            hidden: "Versteckt",
            add: "Hinzufügen",
            set: "Setzen",
            unset: "Zurücksetzen",
            login: "Einloggen",
            cancel: "Abbrechen",
        },
        menu: {
            home: "Startseite",
            people: "Mitarbeiter",
            publications: "Veröffentlichungen",
            teaching: "Lehre",
            settings: "Einstellungen",
            heading: "Institut für Informatik II",
            institute: "Visual Computing",
        },
        news: {
            title: "Neuigkeiten",
        },
        jobs: {
            title: "Stellen",
        },
        people: {
            new: "Neue",
            person: "Person",
            groupleaders: "Gruppenleiter",
            adminoffice: "Sekretariat",
            researchstaff: "Wissenschaftliche Mitarbeiter",
            phdstudents: "Doktoranden",
            alumni: "Alumni",
            formerstudentres: "Ehemalige Studentische Mitarbeiter",
            supervised: "Betreute Dissertationen",
        },
        courses: {
            staff: "Mitarbeiter",
            lecturers: "Dozent(en)",
            tutors: "Tutor(en)",
            courses: "Lehrveranstaltungen",
            lecture: "Vorlesung",
            seminar: "Seminar",
            lab: "Praktikum",
            projectgroup: "Projektgruppe",
            course: "Veranstaltung",
            exercises: "Übungen",
            year: "Jahr",
            website: "Webseite",
            start: "Beginn",
            dates: "Zeiten",
            coursenr: "Veranstaltungsnummer",
            curriculum: "Studiengang",
            effort: "Aufwand",
            exams: "Prüfungen",
            description: "Beschreibung",
            news: "Neuigkeiten",
            literature: "Literatur",
            requirements: "Voraussetzungen",
            diploma: "Diplom",
            noyear: "Jahr darf nicht leer bleiben",
            auth: "Authentifizierung",
            authwarn: "Kein Benutzer/Passwort gesetzt. Jeder kann die Materialien herunterladen!",
            autherruser: "Benuztername darf nicht leer sein!",
            autherrpwd: "Passwort darf nicht leer sein!",
            authsuccess: "Passwort erfolgreich gesetzt.",
            authunsetsuccess: "Passwort erfolgreich zurückgesetzt.",
            slides: "Vorlesungsfolien",
            sheets: "Übungsblätter",
            miscmat: "Weitere Materialien",
            matlogininfo: "Für diese Materialien ist ein Login erforderlich:",
            del: "Veranstaltung Löschen",
        },
        publications: {
            publications: "Veröffentlichungen",
        },
        settings: {
            heading: "Webseiten-Einstellungen",
            users: "Benutzer",
            username: "Benutzername",
            canedit: "Seiten editieren",
            isadmin: "Admin",
        },
    },
};

module.exports = {
    messages,
};
