<template>
    <div class="people-person">
        <a :href="`/person/${person.person_id}`">
            <img v-if ="person.image" :src="$store.getters['persons/avatar'](person)" :style="{ width: (small ? 60 : 130) + 'px' }" />
            <div v-else class="initial-placeholder">&nbsp;</div>
        </a>
        <div class="person-brief">
            <div class="person-title">{{ person.title || "&nbsp;" }}</div>
            <div class="person-link">
                <a :href="`/person/${person.person_id}`">
                    {{ person.firstname }} {{ person.lastname }}
                </a>
                <span v-if="person.hidden" class="hidden">({{ $t('generic.hidden') }})</span>
            </div>
            <div v-if="$store.state.lang === 'de' && !small" class="person-role">{{ person.role_de || person.role_en }}</div>
            <div v-if="$store.state.lang === 'en' && !small" class="person-role">{{ person.role_en || person.role_de }}</div>
            <div v-if="!small" class="person-meta">
                <div class="person-meta-labels">
                    <div>Room:</div>
                    <div>E-Mail:</div>
                    <div>Tel:</div>
                </div>
                <div class="person-meta-values">
                    <div>{{ person.room }}</div>
                    <div><a :href="`mailto:${person.email}`">{{ person.email }}</a></div>
                    <div>{{ person.tel }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['person', 'small'],

    data() {
        return {
            publicPath: process.env.BASE_URL
        };
    },

    created() {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.people-person {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
}

.people-person img {
    margin-right: 20px;
}

.person-brief {
    color: black;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
    font-size: 1.2em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    padding: 10px;
}

.person-title {
}

.person-link {
    font-size: 1.25em;
}

.person-link a {
    color: var(--uni-blue);
}

.person-link a:hover {
    color: var(--uni-blue);
}

.person-link a:active {
    color: var(--uni-blue);
}

.person-role {
    margin-top: 5px;
    font-size: 0.9em;
}

.person-meta {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    font-size: 0.9em;
}

.person-meta-labels {
    color: var(--uni-grey);
    margin-right: 2em;
}
.person-meta-labels div {
    margin-left: auto;
    margin-right: 0;
}

.person-meta-values {
}

.initial-placeholder {
    width: 130px;
    min-width: 130px;
    height: 156px;
    min-height: 156px;
    border: 1px solid #999;
    margin-right: 20px;
}

span.hidden {
    color: var(--red);
    font-size: 0.8em;
    margin-left: 0.5em;
}
</style>
