<template>
    <div v-if="course" class="course-full">
        <div :class="{ infobox: true, error: error_msg, saved: !error_msg && saved, editing: (!error_msg && !saved && (saving || editing)) }">{{ status || '&nbsp;' }}</div>

        <div v-if="edit" class="visible-toggle">
            <label for="hidden">
                <input type="checkbox"
                       id="hidden"
                       :true-value="1"
                       :faĺse-value="0"
                       v-model="course.hidden"
                       @change="changed()" />
                {{ $t('generic.hidden') }}
            </label>
        </div>
        <select v-if="edit" class="course-type" v-model="course.type" @change="changed()">
            <option value="lecture">{{$t('courses.lecture')}}</option>
            <option value="seminar">{{$t('courses.seminar')}}</option>
            <option value="lab">{{$t('courses.lab')}}</option>
            <option value="projectgroup">{{$t('courses.projectgroup')}}</option>
        </select>
        <input v-if="edit && lang == 'de'"
               type="text"
               class="title"
               placeholder="Name der Veranstaltung"
               v-model="course.title_de"
               :size="30"
               @input="changed()">
        <input v-if="edit && lang == 'en'"
               type="text"
               class="title"
               placeholder="Name of the course"
               v-model="course.title_en"
               :size="30"
               @input="changed()">
        <h2 v-if="!edit" class="type">{{ course_type }}</h2>
        <h1 v-if="!edit" class="title">{{ course_title }}</h1>
        <a v-if="can_edit" href="#" @click.prevent="toggle_edit_mode">
            <edit-icon size="1.5x" />
        </a>

        <h2 class="section">{{ $t('courses.course') }}</h2>
        <div class="course-info">
            <div class="course-meta">
                <!-- Lecturer -->
                <div v-if="edit || lecturers.length" class="label">{{ $t('courses.lecturers') }}:</div>
                <div v-if="!edit && lecturers.length" class="person-list">
                    <div v-for="(lecturer, idx) of lecturers"
                         class="person"
                         v-html="person_link(lecturer)"
                         :style="{ 'margin-top': idx > 0 ? '0.5em' : '0' }"
                    />
                </div>
                <course-person-list v-if="edit"
                                    :id="course.course_id"
                                    @change="lecturers_changed"
                                    :lecturers="true"
                />
                <!-- Year -->
                <div v-if="edit" class="label">{{ $t('courses.year') }}:</div>
                <text-input v-if="edit" v-model="course.year" :fixed="50" class="meta" @changed="changed()" />

                <!-- Semester -->
                <div v-if="edit" class="label">Semester:</div>
                <select v-if="edit" class="semester" v-model="course.semester" @change="changed()">
                    <option value="ss">SS</option>
                    <option value="ws">WS</option>
                </select>

                <!-- Curriculum -->
                <div v-if="edit || curriculum" class="label">{{ $t('courses.curriculum') }}:</div>
                <div v-if="!edit && curriculum" class="value">{{ curriculum }}</div>
                <div v-if="edit">
                    <input type="checkbox" id="bachelor" value="bachelor" v-model="curriculum_values" @change="changed()" />
                    <label for="bachelor">Bachelor</label>
                    <br />
                    <input type="checkbox" id="master" value="master" v-model="curriculum_values" @change="changed()" />
                    <label for="master">Master</label>
                    <br />
                    <input type="checkbox" id="bit_master_mi" value="bit_master_mi" v-model="curriculum_values" @change="changed()" />
                    <label for="bit_master_mi">B-IT Master Media Informatics</label>
                    <br />
                    <input type="checkbox" id="bit_master_lsi" value="bit_master_lsi" v-model="curriculum_values" @change="changed()" />
                    <label for="bit_master_lsi">B-IT Master Life Science Informatics</label>
                    <br />
                    <input type="checkbox" id="diploma" value="diploma" v-model="curriculum_values" @change="changed()" />
                    <label for="diploma">{{ $t('courses.diploma') }}</label>
                    <br />
                </div>

                <!-- Website -->
                <div v-if="edit || course.website" class="label">{{ $t('courses.website') }}:</div>
                <div v-if="!edit && course.website" class="value"><a :href="course.website">{{ course.website }}</a></div>
                <text-input v-if="edit" v-model="course.website" :fixed="50" class="meta" @changed="changed()" />

                <!-- Lecture Begin -->
                <div v-if="edit || lecture_start" class="label">{{ $t('courses.start') }}:</div>
                <div v-if="!edit && lecture_start" class="value">{{ lecture_start }}</div>
                <text-input v-if="edit && lang == 'de'" v-model="course.lecture_start_de" :fixed="50" class="meta" @changed="changed()" />
                <text-input v-if="edit && lang == 'en'" v-model="course.lecture_start_en" :fixed="50" class="meta" @changed="changed()" />

                <!-- Lecture Dates -->
                <div v-if="edit || lecture_dates" class="label">{{ $t('courses.dates') }}:</div>
                <div v-if="!edit && lecture_dates" class="value">{{ lecture_dates }}</div>
                <text-input v-if="edit && lang == 'de'" v-model="course.lecture_dates_de" :fixed="50" class="meta" @changed="changed()" />
                <text-input v-if="edit && lang == 'en'" v-model="course.lecture_dates_en" :fixed="50" class="meta" @changed="changed()" />

                <!-- Course Number -->
                <div v-if="edit || course.course_number" class="label">{{ $t('courses.coursenr') }}:</div>
                <div v-if="!edit && course.course_number" class="value">{{ course.course_number }}</div>
                <text-input v-if="edit" v-model="course.course_number" :fixed="50" class="meta" @changed="changed()" />

                <!-- Effort -->
                <div v-if="edit || course.effort" class="label">{{ $t('courses.effort') }}:</div>
                <div v-if="!edit && course.effort" class="value">{{ course.effort }}</div>
                <text-input v-if="edit" v-model="course.effort" :fixed="50" class="meta" @changed="changed()" />

                <!-- Exams Dates -->
                <div v-if="edit || exams" class="label">{{ $t('courses.exams') }}:</div>
                <div v-if="!edit && exams" class="value">{{ exams }}</div>
                <text-input v-if="edit && lang == 'de'" v-model="course.exam_dates_de" :fixed="50" class="meta" @changed="changed()" />
                <text-input v-if="edit && lang == 'en'" v-model="course.exam_dates_en" :fixed="50" class="meta" @changed="changed()" />

                <!-- Requirements -->
                <div v-if="edit || requirements" class="label">{{ $t('courses.requirements') }}:</div>
                <div v-if="!edit && requirements" class="value" v-html="requirements" />
                <text-input v-if="edit && lang == 'de'" v-model="course.requirements_de" :fixed="50" class="meta" @changed="changed()" />
                <text-input v-if="edit && lang == 'en'" v-model="course.requirements_en" :fixed="50" class="meta" @changed="changed()" />
            </div>
        </div>

        <template v-if="edit || exercise_start || exercise_dates || exercise_info">
            <h2 class="section">{{ $t('courses.exercises') }}</h2>
            <div class="course-meta">
                <div v-if="edit || tutors.length" class="label">{{ $t('courses.tutors') }}:</div>
                <div v-if="!edit && tutors.length" class="person-list">
                    <div v-for="(tutor, idx) of tutors"
                         class="person"
                         v-html="person_link(tutor)"
                         :style="{ 'margin-top': idx > 0 ? '0.5em' : '0' }"
                    />
                </div>
                <course-person-list v-if="edit"
                                    :id="course.course_id"
                                    @change="tutors_changed"
                                    :lecturers="false"
                />
                <div v-if="edit || exercise_start" class="label">{{ $t('courses.start') }}:</div>
                <div v-if="!edit && exercise_start" class="value">{{ exercise_start }}</div>
                <text-input v-if="edit && lang == 'de'" v-model="course.exercise_start_de" :fixed="50" class="meta" @changed="changed()" />
                <text-input v-if="edit && lang == 'en'" v-model="course.exercise_start_en" :fixed="50" class="meta" @changed="changed()" />

                <div v-if="edit || exercise_dates" class="label">{{ $t('courses.dates') }}:</div>
                <div v-if="!edit && exercise_dates" class="value">{{ exercise_dates }}</div>
                <text-input v-if="edit && lang == 'de'" v-model="course.exercise_dates_de" :fixed="50" class="meta" @changed="changed()" />
                <text-input v-if="edit && lang == 'en'" v-model="course.exercise_dates_en" :fixed="50" class="meta" @changed="changed()" />
            </div>

            <textarea v-if="edit && lang == 'de'" class="text-entry" placeholder="Informationen über Übungen (unterstützt Markdown)" v-model="course.exercise_info_de" @input="changed()" />
            <textarea v-if="edit && lang == 'en'" class="text-entry" placeholder="Informations about exercises (supports Markdown)" v-model="course.exercise_info_en" @input="changed()" />
            <div class="exercise-info" v-html="exercise_info" />
        </template>

        <template v-if="edit || description">
            <h2 v-if="description_header" class="section">{{ $t('courses.description') }}</h2>

            <textarea v-if="edit && lang == 'de'" class="text-entry" placeholder="Beschreibung der Veranstaltung (unterstützt Markdown)" v-model="course.description_de" @input="changed()" />
            <textarea v-if="edit && lang == 'en'" class="text-entry" placeholder="Description of course (supports Markdown)" v-model="course.description_en" @input="changed()" />
            <div class="description" v-html="description" />
        </template>

        <template v-if="edit || literature">
            <h2 v-if="literature_header" class="section">{{ $t('courses.literature') }}</h2>

            <textarea v-if="edit && lang == 'de'" class="text-entry" placeholder="Zusätzliche Literaturangaben (unterstützt Markdown)" v-model="course.literature_de" @input="changed()" />
            <textarea v-if="edit && lang == 'en'" class="text-entry" placeholder="Additional literature (supports Markdown)" v-model="course.literature_en" @input="changed()" />
            <div class="description" v-html="literature" />
        </template>

        <h2 v-if="edit" class="section">{{ $t('courses.auth') }}</h2>
        <div v-if="edit && !has_user" class="auth-warn">{{ $t('courses.authwarn') }}</div>
        <form v-if="edit" class="user-form">
            <input v-model="user.username"
                   @input="user_fail_msg = ''; user_success_msg = ''"
                   @keypress="input_keypress"
                   size="14"
                   type="text"
                   autocomplete="username"
            />
            <input v-model="user.password"
                   @input="user_fail_msg = ''; user_success_msg = ''"
                   @keypress="input_keypress"
                   size="14"
                   type="password"
                   autocomplete="new-password"
            />
            <button class="set-button" @click.prevent="set_user">{{ $t('generic.set') }}</button>
            <button class="set-button" @click.prevent="unset_user">{{ $t('generic.unset') }}</button>
        </form>
        <div v-if="edit" class="auth-success">{{ user_success_msg }}</div>
        <div v-if="edit" class="auth-warn">{{ user_fail_msg }}</div>

        <input hidden type="file" ref="upload" @change="upload_material" />
        <h2 v-if="edit || (!edit && has_material_type('slides'))" class="section">{{ $t('courses.slides') }}</h2>
        <template v-if="!edit && !can_see && has_material_type('slides')">
            <div class="mat-login-info">{{ $t('courses.matlogininfo') }}</div>
            <form class="user-form">
                <input v-model="login_user.username"
                       @keypress="input_login_keypress"
                       @input="login_fail_msg = ''"
                       size="14"
                       type="text"
                       autocomplete="username"
                />
                <input v-model="login_user.password"
                       @keypress="input_login_keypress"
                       @input="login_fail_msg = ''"
                       size="14"
                       type="password"
                       autocomplete="current-password"
                />
                <button class="set-button" @click.prevent="login">{{ $t('generic.login') }}</button>
            </form>
            <div class="auth-warn">{{ login_fail_msg }}</div>
        </template>
        <div v-if="edit" class="upload-button">
            <progress-bar v-if="uploading" class="upload-proress-bar" :progress="upload_progress" :width="400" />
            <button v-if="!uploading" class="add-button" @click.prevent="trigger_upload('slides')"><plus-icon size="1x" /> {{ $t('generic.add') }}</button>
        </div>
        <div v-for="(mat, idx) of materials['slides']">
            <course-material v-model="materials['slides'][idx]"
                             :first="idx == 0"
                             :edit="edit"
                             @add-sub-material="belongs_to = mat.material_id; trigger_upload('sheets')"
                             @remove="remove_sub_material(mat)"
                             @remove-sub="remove_sub_material"
            />
        </div>

        <h2 v-if="edit || (!edit && has_material_type('sheets'))" class="section">{{ $t('courses.sheets') }}</h2>
        <template v-if="!edit && !can_see && has_material_type('sheets')">
            <div class="mat-login-info">{{ $t('courses.matlogininfo') }}</div>
            <form class="user-form">
                <input v-model="login_user.username"
                       @keypress="input_login_keypress"
                       @input="login_fail_msg = ''"
                       size="14"
                       type="text"
                       autocomplete="username"
                />
                <input v-model="login_user.password"
                       @keypress="input_login_keypress"
                       @input="login_fail_msg = ''"
                       size="14"
                       type="password"
                       autocomplete="current-password"
                />
                <button class="set-button" @click.prevent="login">{{ $t('generic.login') }}</button>
            </form>
            <div class="auth-warn">{{ login_fail_msg }}</div>
        </template>
        <div v-if="edit" class="upload-button">
            <progress-bar v-if="uploading" class="upload-proress-bar" :progress="upload_progress" :width="400" />
            <button v-if="!uploading" class="add-button" @click.prevent="trigger_upload('sheets')"><plus-icon size="1x" /> {{ $t('generic.add') }}</button>
        </div>
        <div v-for="(mat, idx) of materials['sheets']">
            <course-material v-model="materials['sheets'][idx]"
                             :first="idx === 0"
                             :edit="edit"
                             @add-sub-material="belongs_to = mat.material_id; trigger_upload('sheets')"
                             @remove="remove_sub_material(mat)"
                             @remove-sub="remove_sub_material"
            />
        </div>

        <h2 v-if="edit || (!edit && has_material_type('misc'))" class="section">{{ $t('courses.miscmat') }}</h2>
        <template v-if="!edit && !can_see && has_material_type('misc')">
            <div class="mat-login-info">{{ $t('courses.matlogininfo') }}</div>
            <form class="user-form">
                <input v-model="login_user.username"
                       @keypress="input_login_keypress"
                       @input="login_fail_msg = ''"
                       size="14"
                       type="text"
                       autocomplete="username"
                />
                <input v-model="login_user.password"
                       @keypress="input_login_keypress"
                       @input="login_fail_msg = ''"
                       size="14"
                       type="password"
                       autocomplete="current-password"
                />
                <button class="set-button" @click.prevent="login">{{ $t('generic.login') }}</button>
            </form>
            <div class="auth-warn">{{ login_fail_msg }}</div>
        </template>
        <div v-if="edit" class="upload-button">
            <progress-bar v-if="uploading" class="upload-proress-bar" :progress="upload_progress" :width="400" />
            <button v-if="!uploading" class="add-button" @click.prevent="trigger_upload('misc')"><plus-icon size="1x" /> {{ $t('generic.add') }}</button>
        </div>
        <div v-for="(mat, idx) of materials['misc']">
            <course-material v-model="materials['misc'][idx]"
                             :first="idx == 0"
                             :edit="edit"
                             @add-sub-material="belongs_to = mat.material_id; trigger_upload('misc')"
                             @remove="remove_sub_material(mat)"
                             @remove-sub="remove_sub_material"
            />
        </div>


        <button v-if="edit" class="delete-button" @click.prevent="delete_course">
            <trash-2-icon size="1x" /> {{ $t('courses.del') }}
        </button>
    </div>
</template>

<script>
import MarkdownIt from 'markdown-it';
import ImageCropper from './ImageCropper.vue';
import CourseMaterial from '@/components/CourseMaterial';
import CoursePersonList from '@/components/CoursePersonList';
import TextInput from '@/components/TextInput';
import ProgressBar from '@/components/ProgressBar';
import { EditIcon, PlusIcon, Trash2Icon } from '@zhuowenli/vue-feather-icons';

export default {
    components: {
        CourseMaterial,
        CoursePersonList,
        TextInput,
        ProgressBar,
        ImageCropper,
        EditIcon,
        PlusIcon,
        Trash2Icon,
    },

    props: [
        'id',
    ],

    data: function() {
        return {
            course: {},
            original: {},
            lecturers: [],
            tutors: [],
            original_lecturers: [],
            original_tutors: [],
            publicPath: process.env.BASE_URL,
            edit_mode: false,
            edit_timeout: undefined,
            saved_timeout: undefined,
            saved: false,
            editing: false,
            saving: false,
            error_msg: undefined,
            curriculum_values: [],
            materials: {
                slides: [],
                sheets: [],
                misc: [],
            },
            upload_type: 'slides',
            belongs_to: undefined,
            user: {
                username: '',
                password: '',
            },
            user_fail_msg: '',
            user_success_msg: '',
            has_user: false,
            login_user: {
                username: '',
                password: '',
            },
            login_fail_msg: '',
            upload_progress: undefined,
        }
    },

    computed: {
        lang: function() {
            return this.$store.state.lang;
        },
        edit: function() {
            return this.can_edit && this.edit_mode;
        },
        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
        can_see: function() {
            if (this.can_edit) {
                return true;
            }
            const state = this.$store.getters['auth/can_see'];
            return state !== undefined && state === parseInt(this.id);
        },
        status: function() {
            if (this.error_msg) return this.error_msg;
            if (this.saved) return "Saved.";
            if (this.saving) return "Saving...";
            if (this.editing) return "Editing...";
            return undefined;
        },
        person_link: () => ({firstname, lastname, title, person_id, type, hidden, email}) => {
            const name = `${title || ''} ${firstname} ${lastname}`;
            if (person_id !== undefined && type !== 'author' && !hidden) {
                return `<a href="/person/${person_id}">${name}</a>`;
            }
            if (email) {
                return `<a href="mailto:${email}">${name}</a>`;
            }
            return name;
        },
        course_type: function() {
            let type = "";
            if (this.course.type) {
                type = this.$t(`courses.${this.course.type}`);
            }
            return type;
        },
        course_title: function() {
            if (this.$store.state.lang == 'de') {
                return this.course.title_de || this.course.title_en;
            } else {
                return this.course.title_en || this.course.title_de;
            }
        },
        lecture_start: function() {
            if (this.$store.state.lang == 'de') {
                return this.course.lecture_start_de || this.course.lecture_start_en;
            } else {
                return this.course.lecture_start_en || this.course.lecture_start_de;
            }
        },
        lecture_dates: function() {
            if (this.$store.state.lang == 'de') {
                return this.course.lecture_dates_de || this.course.lecture_dates_en;
            } else {
                return this.course.lecture_dates_en || this.course.lecture_dates_de;
            }
        },
        description_header: function() {
            let desc = '';
            if (this.$store.state.lang == 'de') {
                desc = this.course.description_de || this.course.description_en;
            } else {
                desc = this.course.description_en || this.course.description_de;
            }
            if (desc === undefined) {
                return true;
            }
            desc = desc.replace(/^\s+|\s+$/g, '');
            return desc[0] !== '#';
        },
        literature_header: function() {
            let lit = '';
            if (this.$store.state.lang == 'de') {
                lit = this.course.literature_de || this.course.literature_en;
            } else {
                lit = this.course.literature_en || this.course.literature_de;
            }
            if (lit === undefined) {
                return true;
            }
            lit = lit.replace(/^\s+|\s+$/g, '');
            return lit[0] !== '#';
        },
        exercise_start: function() {
            if (this.$store.state.lang == 'de') {
                return this.course.exercise_start_de || this.course.exercise_start_en;
            } else {
                return this.course.exercise_start_en || this.course.exercise_start_de;
            }
        },
        exercise_dates: function() {
            if (this.$store.state.lang == 'de') {
                return this.course.exercise_dates_de || this.course.exercise_dates_en;
            } else {
                return this.course.exercise_dates_en || this.course.exercise_dates_de;
            }
        },
        exercise_info: function() {
            let info = '';
            if (this.$store.state.lang == 'de') {
                info = this.course.exercise_info_de || this.course.exercise_info_en;
            } else {
                info = this.course.exercise_info_en || this.course.exercise_info_de;
            }
            if (info === undefined) return undefined;
            return MarkdownIt().render(info || "");
        },
        exams: function() {
            if (this.$store.state.lang == 'de') {
                return this.course.exam_dates_de || this.course.exam_dates_en;
            } else {
                return this.course.exam_dates_en || this.course.exam_dates_de;
            }
        },
        description: function() {
            let desc = '';
            if (this.$store.state.lang == 'de') {
                desc = this.course.description_de || this.course.description_en;
            } else {
                desc = this.course.description_en || this.course.description_de;
            }
            if (desc === undefined) return undefined;
            return MarkdownIt().render(desc);
        },
        requirements: function() {
            let req = '';
            if (this.$store.state.lang == 'de') {
                req = this.course.requirements_de || this.course.requirements_en;
            } else {
                req = this.course.requirements_en || this.course.requirements_de;
            }
            if (req === undefined) return undefined;
            return MarkdownIt().render(req || "");
        },
        literature: function() {
            let lit = '';
            if (this.$store.state.lang == 'de') {
                lit = this.course.literature_de || this.course.literature_en;
            } else {
                lit = this.course.literature_en || this.course.literature_de;
            }
            if (lit === undefined) return undefined;
            return MarkdownIt().render(lit);
        },
        curriculum: function() {
            if (this.course.curriculum === undefined) {
                return undefined;
            }
            let tokens = this.course.curriculum.split(/\s*[,]\s*/).map((c) => {
                if (c === 'master') return 'Master';
                if (c === 'bachelor') return 'Bachelor';
                if (c === 'bit_master_mi') return 'B-IT Master Media Informatics';
                if (c === 'bit_master_lsi') return 'B-IT Master Life Science Informatics';
                if (c === 'diploma') return this.$t('courses.diploma');
                return c;
            });
            return tokens.join(' / ');
        },
        uploading() {
            return this.upload_progress !== undefined;
        },
    },

    methods: {
        changed: function() {
            this.course.curriculum = this.curriculum_values.join(',');
            if (this.course.year === '') {
                this.error_msg = this.$t('courses.noyear');
                return;
            }
            if (this.course.title_de === '') this.course.title_de = undefined;
            if (this.course.title_en === '') this.course.title_en = undefined;
            if (this.course.type === '') this.course.type = undefined;
            if (this.course.course_number === '') this.course.course_number = undefined;
            if (this.course.requirements_de === '') this.course.requirements_de = undefined;
            if (this.course.requirements_en === '') this.course.requirements_en = undefined;
            if (this.course.lecture_dates_de === '') this.course.lecture_dates_de = undefined;
            if (this.course.lecture_dates_en === '') this.course.lecture_dates_en = undefined;
            if (this.course.lecture_start_de === '') this.course.lecture_start_de = undefined;
            if (this.course.lecture_start_en === '') this.course.lecture_start_en = undefined;
            if (this.course.exercise_dates_de === '') this.course.exercise_dates_de = undefined;
            if (this.course.exercise_dates_en === '') this.course.exercise_dates_en = undefined;
            if (this.course.exercise_start_de === '') this.course.exercise_start_de = undefined;
            if (this.course.exercise_start_en === '') this.course.exercise_start_en = undefined;
            if (this.course.exam_dates_de === '') this.course.exam_dates_de = undefined;
            if (this.course.exam_dates_en === '') this.course.exam_dates_en = undefined;
            if (this.course.effort === '') this.course.effort = undefined;
            if (this.course.website === '') this.course.website = undefined;
            if (this.course.description_de === '') this.course.description_de = undefined;
            if (this.course.description_en === '') this.course.description_en = undefined;
            if (this.course.literature_de === '') this.course.literature_de = undefined;
            if (this.course.literature_en === '') this.course.literature_en = undefined;
            if (this.course.exercise_info_de === '') this.course.exercise_info_de = undefined;
            if (this.course.exercise_info_en === '') this.course.exercise_info_en = undefined;
            this.course.hidden = this.course.hidden ? 1 : 0;
            this.saved = false;
            this.saving = false;
            this.editing = true;
            this.error_msg = undefined;
            if (this.edit_timeout !== undefined) {
                clearTimeout(this.edit_timeout);
            }
            if (this.saved_timeout !== undefined) {
                clearTimeout(this.saved_timeout);
            }
            this.edit_timeout = setTimeout(() => {
                this.save();
                this.edit_timeout = undefined;
            }, 2000);
        },
        save: function() {
            this.editing = false;
            this.saving = true;
            const data = {
                course: this.course,
                lecturers: this.lecturers,
                tutors: this.tutors,
            };
            this.$store.dispatch('courses/update', data).then((c) => {
                this.course = c;
                this.original = { ...this.course };
                this.$store.dispatch('persons/fetch_for_course', this.id).then(() => {
                    const all_persons = this.$store.getters['persons/for_course'](this.id);
                    this.lecturers = all_persons ? all_persons.filter((a) => a.is_lecturer) : [];
                    this.tutors = all_persons ? all_persons.filter((a) => !a.is_lecturer) : [];
                    this.original_lecturers = [ ...this.lecturers ];
                    this.original_tutors = [ ...this.tutors ];
                });

                this.saving = false;
                this.saved = true;
                this.saved_timeout = setTimeout(() => {
                    this.saved = false;
                }, 3000);
            }).catch((e) => {
                this.course = this.original;
                this.saving = false;
                this.saved = false;
                this.error_msg = e;
            });
        },
        init_data: async function() {
            await this.$store.dispatch('courses/fetch');
            await this.$store.dispatch('courses/fetch_materials', this.id);
            this.course = this.$store.getters['courses/course'](this.id);
            this.original = { ... this.course };

            this.$store.dispatch('persons/fetch_for_course', this.id).then(() => {
                const all_persons = this.$store.getters['persons/for_course'](this.id);
                this.lecturers = all_persons ? all_persons.filter((a) => a.is_lecturer) : [];
                this.tutors = all_persons ? all_persons.filter((a) =>  !a.is_lecturer) : [];
                this.original_lecturers = [ ...this.lecturers ];
                this.original_tutors = [ ...this.tutors ];
            });

            this.curriculum_values = this.course.curriculum.split(/\s*[,]\s*/);

            this.materials['slides'] = [];
            this.materials['sheets'] = [];
            this.materials['misc'] = [];
            for (const mat of this.course.materials) {
                this.materials[mat.type].push(mat);
            }
            const crit = (a, b) => { return a.rank - b.rank; };
            this.materials['slides'].sort(crit);
            this.materials['sheets'].sort(crit);
            this.materials['misc'].sort(crit);

            if (this.can_edit) {
                this.$store.dispatch('users/fetch_for_course', this.id).then(() => {
                    const user = this.$store.state.users.for_course[this.id];
                    if (user !== undefined) {
                        this.user.username = user.username;
                        this.has_user = true;
                    }
                });
            }
       },
        trigger_upload(type) {
            this.upload_type = type;
            this.$refs.upload.click();
        },
        async upload_material(ev) {
            const file = this.$refs.upload.files[0];
            this.upload_progress = 0.0;
            const { material } = await this.$store.dispatch('courses/add_material', {
                file,
                material_type: this.upload_type,
                course_id: this.course.course_id,
                belongs_to: this.belongs_to,
                name: '',
                hidden: 1,
                progress_callback: (progress) => {
                    this.upload_progress = progress;
                },
            });
            this.init_data();
            this.upload_progress = undefined;
            this.belongs_to = undefined;
        },
        async remove_sub_material(sub) {
            await this.$store.dispatch('courses/remove_material', sub);
            this.init_data();
        },
        async delete_course() {
            await this.$store.dispatch('courses/remove', this.course.course_id);
            this.$router.push({ name: 'Courses' });
        },
        lecturers_changed: function(persons) {
            this.lecturers = persons;
            this.changed();
        },
        tutors_changed: function(persons) {
            this.tutors = persons;
            this.changed();
        },
        input_keypress(ev) {
            if (ev.key == 'Enter') {
                this.set_user();
            }
        },
        async set_user() {
            if (this.user.username === '') {
                this.user_fail_msg = this.$t('courses.autherruser');
                return;
            }
            if (this.user.password === '') {
                this.user_fail_msg = this.$t('courses.autherrpwd');
                return;
            }

            const user = await this.$store.dispatch('users/set_for_course', {
                course_id: this.course.course_id,
                user: this.user,
            });

            if (user !== undefined) {
                this.user.username = user.username;
                this.has_user = true;
            }

            this.user.password = '';
            this.user_success_msg = this.$t('courses.authsuccess');
        },
        async unset_user() {
            await this.$store.dispatch('users/unset_for_course', this.course.course_id);
            this.has_user = false;
            this.user.username = '';
            this.user.password = '';
            this.user_success_msg = this.$t('courses.authunsetsuccess');
        },
        toggle_edit_mode() {
            this.edit_mode = !this.edit_mode;
            this.init_data();
        },
        async login() {
            const data = {
                username: this.login_user.username,
                password: this.login_user.password,
            };
            this.$store.dispatch('auth/login', data).then(() => {
                this.init_data();
            }).catch(e => { this.login_fail_msg = e; });
        },
        input_login_keypress(ev) {
            if (ev.key == 'Enter') {
                this.login();
            }
        },
        has_material_type(type) {
            return this.course.has_materials !== undefined && this.course.has_materials[type];
        },
    },


    async mounted() {
        this.init_data();
        if (this.$route.params.edit) {
            this.edit_mode = true;
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.course-full {
    position: relative;
    padding-top: 50px;
}

h2.type {
    margin: 0;
}
h2.section {
    margin: 0;
    margin-top: 1.5em;
}
h1.title {
    display: inline;
    margin: 0 1em 0 0;
}

.course-info {
}

.course-meta {
    margin-top: 1em;
    margin-left: 2em;
    display: inline-grid;
    grid-template-columns: auto auto;
    column-gap: 1em;
    row-gap: 0.5em;
}

.course-meta .label {
    font-weight: bold;
}

.course-meta .value:deep(p) {
    display: inline;
    padding: 0;
    margin: 0;
}

.exercise-info {
    margin-left: 2em;
}

.description {
}

.description:deep(p) {
    margin-left: 2em;
}

.description:deep(ul) {
    margin-left: 1em;
}

.description:deep(h1) {
    font-size: 1.5em;
    margin-top: 1em;
}

.description:deep(h2) {
    font-size: 1.0em;
}

.description:deep(h3) {
    font-size: 0.83em;
}

.description:deep(h4) {
    font-size: 0.67em;
}

input {
    border: 1px solid #a0a0a0;
}

label {
    margin-left: 0.5em;
}

input.title {
    display: inline;
    font-size: 2em;
    margin-right: 5px;
    padding: 4px;
}

textarea.text-entry {
    width: 100%;
    height: 10em;
    padding: 5px;
    resize: none;
    margin-top: 2em;
}

select.course-type {
    display: block;
}

a {
    color: var(--uni-blue);
}

.add-button {
    background-color: var(--uni-blue);
    color: white;
    padding: 10px;
    border: none;
    line-height: 1em;
}

.upload-button {
    margin: 1em 0 2em 2em;
}

.visible-toggle {
    margin-bottom: 1em;
}

.auth-warn {
    color: var(--red);
    margin: 1em 0 0 2em;
}

.auth-success {
    color: var(--green);
    margin: 1em 0 0 2em;
}

.user-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 1em 0 0 2em;
}

.user-form:deep(input) {
    display: block;
    margin-right: 1em;
    border: 1px solid #aaa;
    line-height: 1em;
    padding: 2px 5px;
    font-size: 1em;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-light-weight);
}

.set-button {
    background-color: var(--uni-blue);
    color: white;
    border: 0;
    padding: 3px 12px;
    margin-right: 1em;
}

.mat-login-info {
    margin: 1em 0 0 2em;
}

.delete-button {
    display: block;
    background-color: var(--red);
    color: white;
    border: 0;
    padding: 10px;
    line-height: 1em;
    margin-top: 2em;
    margin-left: 2em;
}
</style>
