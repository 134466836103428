<template>
    <div class="address-list">
        <div :class="{ infobox: true, error: error_msg, saved: !error_msg && saved, editing: (!error_msg && !saved && (saving || editing)) }">{{ status || '&nbsp;' }}</div>
        <list-entry v-for="(address, key, idx) of addresses"
                    :first="idx == 0"
                    :deletable="edit_mode"
                    :selectable="false"
                    @delete="remove(key)">
            <div class="address">
                <div>
                    <span v-if="!edit_mode" class="address">{{ address.street }}</span>
                    <input v-if="edit_mode" type="text" class="address" v-model="address.street" :size="address.street ? (address.street.length-2) : 10" @input="changed(address)" />
                    <span v-if="!edit_mode" class="address-right">{{ address.street_number }},</span>
                    <input v-if="edit_mode" type="text" class="address address-right" v-model="address.street_number" :size="1" @input="changed(address)" />
                </div>
                <div>
                    <span v-if="!edit_mode" class="address">{{ address.zipcode }}</span>
                    <input v-if="edit_mode" type="text" class="address" v-model="address.zipcode" :size="address.zipcode ? (address.zipcode.length-2) : 10" @input="changed(address)" />
                    <span v-if="!edit_mode" class="address-right">{{ address.city }}</span>
                    <input v-if="edit_mode" type="text" class="address address-right" v-model="address.city" :size="address.city ? (address.city.length-2) : 10" @input="changed(address)" />
                </div>
                <div>
                    <span v-if="!edit_mode" class="address">{{ address.state }}</span>
                    <input v-if="edit_mode" type="text" class="address" v-model="address.state" :size="address.state ? (address.state.length-2) : 10" @input="changed(address)" />
                </div>
            </div>
        </list-entry>
        <list-entry v-if="edit_mode"
                    :first="true"
                    :deletable="false"
                    :selectable="false">
            <div>
                <input type="text" placeholder="Street" class="address new-address" v-model="street" :size="20" />
                <input type="text" placeholder="Nr." class="address address-right new-address" v-model="street_number" :size="1" />
            </div>
            <div>
                <input type="text" placeholder="Zipcode" class="address new-address" v-model="zipcode" :size="6" />
                <input type="text" placeholder="City" class="address address-right new-address" v-model="city" :size="15" />
            </div>
            <div>
                <input type="text" placeholder="State" class="address new-address" v-model="state" :size="24" />
            </div>
            <button class="add-button" @click="add">Add</button>
        </list-entry>
    </div>
</template>

<script>
import ListEntry from '@/components/ListEntry';
import { EditIcon } from '@zhuowenli/vue-feather-icons';

export default {
    components: {
        ListEntry,
        EditIcon,
    },

    props: ['edit'],

    data: function() {
        return {
            addresses: [],
            edit_timeout: undefined,
            saved_timeout: undefined,
            saved: false,
            editing: false,
            saving: false,
            error_msg: undefined,
            street: "",
            street_number: "",
            zipcode: "",
            city: "Bonn",
            state: "Germany",
        }
    },

    computed: {
        edit_mode: function() {
            return this.$store.getters['auth/can_edit'] && this.edit;
        },
        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
        status: function() {
            if (this.error_msg) return this.error_msg;
            if (this.saved) return "Saved.";
            if (this.saving) return "Saving...";
            if (this.editing) return "Editing...";
            return undefined;
        },
    },

    methods: {
        changed: function(address) {
            this.saved = false;
            this.saving = false;
            this.editing = true;
            this.error_msg = undefined;
            if (this.edit_timeout !== undefined) {
                clearTimeout(this.edit_timeout);
            }
            if (this.saved_timeout !== undefined) {
                clearTimeout(this.saved_timeout);
            }
            this.edit_timeout = setTimeout(() => {
                this.save(address);
                this.edit_timeout = undefined;
            }, 2000);
        },
        save: function(address) {
            this.editing = false;
            this.saving = true;
            this.$store.dispatch('addresses/update', address).then((a) => {
                address = a;
                this.saving = false;
                this.saved = true;
                this.saved_timeout = setTimeout(() => {
                    this.saved = false;
                }, 3000);
            }).catch((e) => {
                // this.person = this.original;
                this.saving = false;
                this.saved = false;
                this.error_msg = e;
            });
        },
        add: async function() {
            const address = {
                city: this.city,
                state: this.state,
                street: this.street,
                street_number: this.street_number,
                zipcode: this.zipcode,
            };
            await this.$store.dispatch('addresses/add', address);
            this.addresses = this.$store.state.addresses.addresses;
        },
        remove: async function(id) {
            await this.$store.dispatch('addresses/remove', id);
            this.addresses = this.$store.state.addresses.addresses;
        }
    },


    async mounted() {
        await this.$store.dispatch('addresses/fetch');
        this.addresses = this.$store.state.addresses.addresses;
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.infobox {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid white;
}

div.address {
    /* margin: 20px 0; */
}

input {
    border: 1px solid #a0a0a0;
}

input.address {
    display: inline;
}

.address-right {
    margin-left: 3px;
}

.add-button {
    margin-top: 0.5em;
    background-color: var(--green);
    color: white;
}
</style>
