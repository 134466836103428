import { createStore } from 'vuex'
import { auth } from '@/store/modules/auth'
import { users } from '@/store/modules/users'
import { persons } from '@/store/modules/persons'
import { authors } from '@/store/modules/authors'
import { publications } from '@/store/modules/publications'
import { addresses } from '@/store/modules/addresses'
import { news } from '@/store/modules/news'
import { jobs } from '@/store/modules/jobs'
import { courses } from '@/store/modules/courses'
import { dissertations } from '@/store/modules/dissertations'
import { get, post, put, api_url } from '@/services/backend'


export default createStore({
    state: {
        lang: localStorage.getItem('lang') || 'de',
        // lang: 'de',
    },
    getters: {
    },
    mutations: {
        set_lang(state, lang) {
            state.lang = lang;
            localStorage.setItem("lang", lang);
        },
    },
    actions: {
        async fetch_data({ dispatch }) {
            dispatch('persons/fetch');
            dispatch('addresses/fetch');
            dispatch('publications/fetch');
            dispatch('dissertations/fetch');
        },
    },
    modules: {
        auth,
        users,
        persons,
        authors,
        publications,
        addresses,
        news,
        jobs,
        courses,
        dissertations,
    },
})
