<template>
    <div class="admin-main-menu">
        <template v-if="!authenticated">
            <span class="fail-msg">{{ fail_msg }}</span>
            <form class="input-form">
                <input v-model="login_username"
                       @input="fail_msg = ''"
                       @keypress="input_keypress"
                       size="14"
                       type="text"
                       autocomplete="username"
                />
                <input v-model="login_password"
                       @input="fail_msg = ''"
                       @keypress="input_keypress"
                       size="14"
                       type="password"
                       autocomplete="current-password"
                />
            </form>
            <a href="#" @click.prevent="login()">
                <log-in-icon size="1.5x" />
            </a>
        </template>
        <template v-else>
            <div class="admin-main-menu-username">
                {{ $store.getters['auth/username'] }}
            </div>
            <a href="#" @click.prevent="logout()">
                <log-out-icon size="1.5x" />
            </a>
        </template>
    </div>
</template>

<script>
import { ToolIcon,
         LogInIcon,
         LogOutIcon,
       } from '@zhuowenli/vue-feather-icons';

export default {
    props: [],

    components: {
        ToolIcon,
        LogInIcon,
        LogOutIcon,
    },

    data() {
        return {
            login_username: "",
            login_password: "",
            fail_msg: "",
            // publicPath: process.env.BASE_URL
        };
    },

    computed: {
        authenticated: function() {
            return this.$store.getters['auth/authenticated'];
        },
    },

    methods: {
        login() {
            if (!this.login_username.length) {
                this.fail("No username provided!");
                return;
            }
            if (!this.login_password.length) {
                this.fail("No password provided!");
                return;
            }
            const username = this.login_username;
            const password = this.login_password;
            this.$store.dispatch('auth/login', { username, password }).then(() => {
                this.login_username = '';
                this.login_password = '';
            }).catch(e => { this.fail_msg = e; });
        },
        logout() {
            this.$store.dispatch('auth/logout');
        },
        fail(msg) {
            this.fail_msg = msg;
        },
        input_keypress(ev) {
            if (ev.key == 'Enter') {
                this.login();
            }
        },
    },

    // async mounted() {
    // },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.admin-main-menu {
    color: white;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.input-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.admin-main-menu:deep(input) {
    display: block;
    margin-right: 1em;
    background-color: var(--uni-blue);
    border: 1px solid #aaa;
    line-height: 1em;
    padding: 2px 5px;
    font-size: 1em;
    color: white;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-light-weight);
}

.admin-main-menu a {
    line-height: 1.5em;
    color: white;
}

span.fail-msg {
    color: var(--uni-yellow);
    margin-right: 1em;
    line-height: 1.5em;
}

.admin-main-menu-username {
    font-weight: bold;
    margin-right: 1em;
    line-height: 1.5em;
}
</style>
