<template>
    <div class="progress-bar" :style="{ width: this.width + 'px' }">
        <div class="progress-container">
            <div class="done" :style="{ width: done_width + 'px' }" />
            <div class="todo" :style="{ width: todo_width + 'px' }" />
        </div>
    </div>
</template>


<script>
export default {
    props: {
        'progress': {
            type: Number,
            default: 0,
        },
        'width': {
            type: Number,
            default: 400,
        },
    },
    computed: {
        done_width() {
            return Math.round(this.progress * this.width);
        },
        todo_width() {
            return this.width - this.done_width;
        },
    },
}
</script>

<style scoped>
.progress-container {
    display: flex;
    width: 400px;
    height: 40px;
    border: 1px solid #333;
    background-color: white;
    align-items: stretch;
}

.done {
    height: 100%;
    background-color: var(--uni-blue);
}

.todo {
    height: 100%;
}
</style>
