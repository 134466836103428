<template>
    <div :class="{ 'list-entry': true, 'first-entry': first, 'selectable-entry': selectable, 'active': active }"
         :style="{ width: width + 'px' }"
         @click="if (selectable) { this.$emit('select'); }"
    >
        <div class="list-entry-content">
            <slot></slot>
        </div>
        <div v-if="deletable" class="list-entry-delete-area" @click.prevent="$emit('delete')">
            <trash-2-icon size="1.5x" />
        </div>
        <div v-if="addable" class="list-entry-add-area" @click.prevent="$emit('add')">
            <plus-icon size="1.5x" />
        </div>
    </div>
</template>

<script>
import { PlusIcon, Trash2Icon } from '@zhuowenli/vue-feather-icons';

export default {
    components: {
        Trash2Icon,
        PlusIcon,
    },

    emits: ['select', 'delete', 'add'],

    props: ['deletable', 'selectable', 'first', 'active', 'addable', 'width'],

    data: function() {
        return {
        }
    },

    computed: {
    },

    methods: {
    },


    async mounted() {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.list-entry {
    /* width: 500px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #bbb;
    border-top: none;
}
.first-entry {
    border-top: 1px solid #aaa;
}
.selectable-entry:hover {
    background-color: var(--uni-light-yellow);
}
.selectable-entry.active {
    background-color: var(--uni-yellow);
    color: white;
}
.list-entry-content {
    padding: 1em;
}
.list-entry-delete-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ff5050;
    padding: 0 5px;
    color: white;
}
.list-entry-delete-area:hover {
    background-color: red;
}
.list-entry-add-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #5fa673;
    padding: 0 5px;
    color: white;
}
.list-entry-add-area:hover {
    background-color: var(--green);
}
</style>
