import { get, put, post, del } from '@/services/backend'

export const dissertations = {
    namespaced: true,

    state: {
        dissertations: {},
    },
    getters: {
        all: (state) => {
            return Object.values(state.dissertations);
        },
        for_supervisor: (state) => (id) => {
            return state.dissertations[id];
        },
    },
    mutations: {
        add(state, d) {
            const supervisor = d.supervisor_id;
            if (state.dissertations[supervisor] === undefined) {
                state.dissertations[supervisor] = [];
            }
            state.dissertations[supervisor].push(d);
        },
        set(state, diss) {
            for (const d of diss) {
                const supervisor = d.supervisor_id;
                if (state.dissertations[supervisor] === undefined) {
                    state.dissertations[supervisor] = [];
                }
                state.dissertations[supervisor].push(d);
            }
        },
        update(state, data) {
            const s = data.supervisor_id;
            if (state.dissertations[s] === undefined) return;
            const idx = state.dissertations[s].findIndex(d => d.person_id === data.person_id);
            if (idx >= 0) {
                state.dissertations[s][idx] = data;
            }
        },
    },
    actions: {
        async fetch({ commit, state }) {
            const diss = await get(`/dissertations`);
            if (diss === undefined || diss.dissertations === undefined || !Object.values(diss.dissertations).length) return;
            commit('set', diss.dissertations);
        },
        async add({commit, state, rootGetters}, data) {
            const token = rootGetters['auth/token'];
            let updated = await post(`/dissertations`, data, token);
            commit('add', updated.dissertation);
            return updated.dissertation;
        },
        async update({commit, state, rootGetters}, data) {
            const token = rootGetters['auth/token'];
            const pid = data.person_id;
            let updated = await put(`/dissertations/${pid}`, data, token);
            commit('update', updated.dissertation);
            return updated.dissertation;
        },
        async remove({ commit }, id) {
            const token = rootGetters['auth/token'];
            await del(`/dissertations/${id}`);
        },
    },
};
