<template>
    <div :class="{ infobox: true, error: error_msg, saved: !error_msg && saved, editing: (!error_msg && !saved && saving) }">{{ status || '&nbsp;' }}</div>
    <div class="user-list">
        <div class="header">{{ $t('settings.username') }}</div>
        <div class="header">{{ $t('settings.canedit') }}</div>
        <div class="header">{{ $t('settings.isadmin') }}</div>
        <template v-for="user of users">
            <div :class="{ username: true, 'can-edit': !user.is_admin && user.can_edit, 'is-admin': user.is_admin }">{{ user.username }} {{ full_name(user) }}</div>
            <input type="checkbox" v-model="user.can_edit" @change="changed(user)" />
            <input type="checkbox" v-model="user.is_admin" @change="changed(user)" />
        </template>
    </div>
</template>

<script>

export default {
    components: {
    },

    props: [],

    data: function() {
        return {
            users: [],
            saved_timeout: undefined,
            saved: false,
            saving: false,
            error_msg: undefined,
            changes: [],
            // street: "",
            // street_number: "",
            // zipcode: "",
            // city: "Bonn",
            // state: "Germany",
        }
    },

    computed: {
        is_admin: function() {
            return this.$store.getters['auth/is_admin'];
        },
        status: function() {
            if (this.error_msg) return this.error_msg;
            if (this.saved) return "Saved.";
            if (this.saving) return "Saving...";
            return undefined;
        },
    },

    methods: {
        full_name(user) {
            let name = user.firstname + " " + user.lastname;
            name = name.trim();
            if (name.length > 0) {
                name = '(' + name + ')';
            }
            return name;
        },
        changed: function(user) {
            this.saved = false;
            this.saving = true;
            this.error_msg = undefined;
            this.saved_timeout = setTimeout(() => {
                this.saved = false;
            }, 3000);
            this.$store.dispatch('users/update', user).then((u) => {
                this.saving = false;
                this.saved = true;
                this.saved_timeout = setTimeout(() => {
                    this.saved = false;
                }, 2000);
            }).catch((e) => {
                this.saving = false;
                this.saved = false;
                this.error_msg = e;
            });
        },
    },


    async mounted() {
        await this.$store.dispatch('users/fetch');
        this.users = this.$store.state.users.users;
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.infobox {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid white;
}

div.error {
    background: #cb5858;
    border: 2px solid var(--red);
}

div.saved {
    background: #a1ffa4;
    border: 2px solid #5bd15e;
}

div.editing {
    background: #ffce71;
    border: 2px solid #d1a85b;
}

div.user-list {
    display: inline-grid;
    grid-template-columns: minmax(300px, auto) 100px 100px;
    margin-top: 1em;
    margin-left: 2em;
    margin-bottom: 2em;
    gap: 0.5em;
}

div.header {
    font-weight: bold;
}

div.can-edit {
    font-weight: bold;
    color: green;
}

div.is-admin {
    font-weight: bold;
    color: magenta;
}

</style>
