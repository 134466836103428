<template>
    <div class="content-segment">
        <div class="container">
            <PublicationFull :id="$route.params.id" />
        </div>
    </div>
</template>

<script>
import PublicationFull from '@/components/PublicationFull.vue';

export default {
    name: 'Publication',
    components: {
        PublicationFull,
    },
    computed: {
    },
    mounted() {
    }
}
</script>

