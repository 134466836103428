
<template>
<div class="person_edit">
    <div class="person-edit-list">
        <template v-for="(person, idx) of persons">
            <div class="person-edit-list-name" v-html="person_link(person)" />
            <a href="#" v-if="idx < (persons.length - 1)" @click.prevent="move_person(idx, false)">
                <chevron-down-icon size="1.5x" />
            </a>
            <div v-if="idx == (persons.length - 1)" />
            <a href="#" v-if="idx > 0" @click.prevent="move_person(idx, true)">
                <chevron-up-icon size="1.5x" />
            </a>
            <div v-if="idx == 0" />
            <a href="#" @click.prevent="remove_person(idx)">
                <user-x-icon class="remove-person" size="1.5x" />
            </a>
        </template>
    </div>
    <div class="add-person-line">
        <input class="search-person" placeholder="Search" v-model="query" @input="update_search" />
        <span class="oradd"> or add </span>
        <input class="add-person-name" placeholder="First Name" v-model="add_firstname" />
        <input class="add-person-name" placeholder="Last Name" v-model="add_lastname" />
        <input class="add-person-name" placeholder="Email" v-model="add_email" />
        <a href="#" @click.prevent="add_person({firstname: add_firstname, lastname: add_lastname, email: add_email}, false)">
            <user-plus-icon class="add-person-button" size="1.5x" />
        </a>
    </div>
    <div v-if="search_results.length" class="search-results">
        <div v-for="person of search_results">
            <a href="#" @click.prevent="add_person(person, false)">{{ person.firstname }} {{ person.lastname }}</a>
        </div>
    </div>
</div>
</template>

<script>
import { ChevronUpIcon,
         ChevronDownIcon,
         UserXIcon,
         UserPlusIcon,
       } from '@zhuowenli/vue-feather-icons';

export default {
    props: [
        'id',
        'lecturers',
    ],

    components: {
        ChevronUpIcon,
        ChevronDownIcon,
         UserXIcon,
         UserPlusIcon,
    },

    emits: ['change'],

    data: function() {
        return {
            persons: [],
            searchable_persons: [],
            query: "",
            search_results: [],
            add_firstname: "",
            add_lastname: "",
            add_email: "",
        }
    },

    computed: {
        person_link: () => ({firstname, lastname, title, person_id, type, hidden, email}) => {
            const name = `${title || ''} ${firstname} ${lastname}`;
            if (person_id !== undefined && type !== 'author' && !hidden) {
                return `<a href="/person/${person_id}">${name}</a>`;
            }
            if (email) {
                return `<a href="mailto:${email}">${name}</a>`;
            }
            return name;
        },
    },

    methods: {
        move_person: function(idx, up) {
            const idx_a = idx;
            const idx_b = up ? idx - 1 : idx + 1;
            const a = this.persons[idx_a];
            const b = this.persons[idx_b];

            // swap ranks
            const tmp_rank = b.rank;
            b.rank = a.rank;
            a.rank = tmp_rank;

            // swap positions
            this.persons[idx_b] = a;
            this.persons[idx_a] = b;

            this.$emit('change', this.persons);
        },
        move_lecturer: function(idx, up) {
            const idx_a = idx;
            const idx_b = up ? idx - 1 : idx + 1;
            const a = this.persons[idx_a];
            const b = this.persons[idx_b];

            // swap ranks
            const tmp_rank = b.rank;
            b.rank = a.rank;
            a.rank = tmp_rank;

            // swap positions
            this.persons[idx_b] = a;
            this.persons[idx_a] = b;

            this.$emit('change', this.persons);
        },
        remove_person: function(idx) {
            let crt_rank = this.persons[idx].rank;
            for (let i = idx + 1; i < this.persons.length; i++) {
                const next_rank = this.persons[i].rank;
                this.persons[i].rank = crt_rank;
                crt_rank = next_rank;
            }
            this.persons.splice(idx, 1);
            this.$emit('change', this.persons);
        },
        update_search() {
            if (this.query.length < 2) {
                this.search_results = [];
                return;
            }
            this.search_results = this.searchable_persons.filter((person) => {
                const q = this.query.toLowerCase();
                return (person.firstname || "").toLowerCase().includes(q)
                    || (person.lastname || "").toLowerCase().includes(q);
            });
        },
        add_person: function(person, is_lecturer) {
            if (!person.firstname.length || !person.lastname.length) {
                return;
            }
            if (person.email === '') {
                person.email = undefined;
            }
            const next_rank = this.persons.length ? (this.persons[this.persons.length - 1].rank + 1) : 1;
            this.persons.push({ ...person, rank: next_rank, is_lecturer: this.lecturers });
            this.query = '';
            this.search_results = [];
            this.add_firstname = "";
            this.add_lastname = "";
            this.$emit('change', this.persons);
        },
        init_data() {
            const all_persons = this.$store.getters['persons/for_course'](this.id);
            const is_lecturer = this.lecturers ? 1 : 0;
            this.persons = all_persons ? all_persons.filter((a) => (a.is_lecturer || 0) === is_lecturer) : [];
            this.searchable_persons = this.$store.state.persons.persons;
        },
    },

    async mounted() {
        this.init_data();
        // this component might be mounted before any data arrived, so on arrival
        // reset data state
        this.$watch(() => this.$store.state.persons.persons,
            (newVal, oldVal) => {
                this.init_data();
            }
        );
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.person-edit-list {
    display: grid;
    grid-template-columns: fit-content(800px) 1em 1em 1em;
    column-gap: 1em;
    row-gap: 0.5em;
    margin-left: 0em;
}

.move-person {
    color: var(--uni-blue);
}
.remove-person {
    color: var(--red);
}

div.person-edit-list-name {
    padding: 0;
    padding-right: 50px;
}

input.search-person {
    margin-left: 0em;
}

span.oradd {
    margin: 0 1em;
}

.add-person-name {
    margin-right: 1em;
}

.add-person-line {
    margin-top: 0.5em;
    height: 1.5em;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
}

.add-person-button {
    color: var(--green);
}

div.search-results {
    max-height: 400px;
    border: 1px solid #333;
    overflow: auto;
    margin-left: 2em;
    margin-top: 0.5em;
    width: 400px;
}
</style>
