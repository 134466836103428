<template>
    <div v-if="publication" class="publication-full">
        <div :class="{ infobox: true, error: error_msg, saved: !error_msg && saved, editing: (!error_msg && !saved && (saving || editing)) }">{{ status || '&nbsp;' }}</div>

        <div v-if="edit" class="visible-toggle">
            <label for="hidden">
                <input type="checkbox"
                       id="hidden"
                       :true-value="1"
                       :faĺse-value="0"
                       v-model="publication.hidden"
                       @change="changed()" />
                {{ $t('generic.hidden') }}
            </label>
        </div>
        <h1 v-if="!edit" class="title">{{ publication.title }}</h1>
        <input v-if="edit"
               type="text"
               class="title"
               placeholder="Title"
               v-model="publication.title"
               :size="publication.title ? (publication.title.length-2) : 30"
               @input="changed()">
        <a v-if="can_edit"
           :class="{ 'edit-toggle': true, 'active': edit, }"
           href="#"
           @click.prevent="edit_mode = !edit_mode">
            <edit-icon size="1.5x" />
        </a>

        <div v-if="!edit" class="author_line" v-html="author_line" />
        <div v-if="!edit" class="author_line" v-html="editor_line" />

        <h2 v-if="edit">Authors</h2>
        <authors v-if="edit"
                 :id="id"
                 @change="authors_changed"
                 :editors="false"
        />

        <h2 v-if="edit">Editors</h2>
        <authors v-if="edit"
                 :id="id"
                 @change="editors_changed"
                 :editors="true"
        />

        <div v-if="!edit" class="pub-meta"><i>{{ pubmeta }}</i></div>

        <div class="section">
            <h2 v-if="publication.abstract">Abstract</h2>
            <div class="abstract-section">
                <textarea v-if="edit"
                          class="abstract"
                          placeholder="Abstract"
                          v-model="publication.abstract"
                          @input="changed()"
                />
                <div v-if="!edit" class="abstract">
                    {{ publication.abstract }}
                </div>
                <image-cropper :aspectRatio="1.33"
                               :width="200"
                               :editable="edit"
                               :initial="$store.getters['publications/teaser'](publication)"
                />
            </div>
            <div v-if="!edit && publication.note" v-html="publication.note" class="notes" />
            <div v-if="!edit && publication.miscnotes" v-html="publication.miscnotes" class="notes" />
            <textarea v-if="edit"
                      class="notes"
                      placeholder="Additional notes"
                      v-model="publication.miscnotes"
                      @input="changed()"
            />
        </div>

        <div v-if="edit" class="section">
            <h2>Metadata</h2>
            <div class="metadata-edit-list">
                <div class="metadata-edit-list-label">Year:</div>
                <input class="metadata-edit-list-entry" placeholder="Year" v-model="publication.year" @input="changed()" />
                <div class="metadata-edit-list-label">Publication Type:</div>
                <div class="metadata-edit-list-selectable">
                    <a href="#"
                       :class="{ selected: publication.type == 'Article' }"
                       @click.prevent="publication.type = 'Article'; changed()">
                       Journal Article
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Inproceedings' }"
                       @click.prevent="publication.type = 'Inproceedings'; changed()">
                       Conference Paper
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Book' }"
                       @click.prevent="publication.type = 'Book'; changed()">
                       Book
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Inbook' }"
                       @click.prevent="publication.type = 'Inbook'; changed()">
                       Part of Book (e.g. a chapter)
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Incollection' }"
                       @click.prevent="publication.type = 'Incollection'; changed()">
                       Part of Collection (e.g. a chapter)
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Booklet' }"
                       @click.prevent="publication.type = 'Booklet'; changed()">
                       Booklet / Pamphlet
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Phdthesis' }"
                       @click.prevent="publication.type = 'Phdthesis'; changed()">
                       PhD Thesis
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Mastersthesis' }"
                       @click.prevent="publication.type = 'Mastersthesis'; changed()">
                       Master's Thesis
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Bachelorsthesis' }"
                       @click.prevent="publication.type = 'Bachelorsthesis'; changed()">
                       Bachelor's Thesis
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Proceedings' }"
                       @click.prevent="publication.type = 'Proceedings'; changed()">
                        Proceedings
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Techreport' }"
                       @click.prevent="publication.type = 'Techreport'; changed()">
                       Technical Report
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Unpublished' }"
                       @click.prevent="publication.type = 'Unpublished'; changed()">
                       Unpublished (e.g. arXiv publication)
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Standard' }"
                       @click.prevent="publication.type = 'Standard'; changed()">
                       Standard / Legislation
                    </a>
                    <a href="#"
                       :class="{ selected: publication.type == 'Misc' }"
                       @click.prevent="publication.type = 'Misc'; changed()">
                       Miscellaneous
                    </a>
                </div>
                <div class="metadata-edit-list-label">Journal:</div>
                <input class="metadata-edit-list-entry" placeholder="Journal" v-model="publication.journal" @input="changed()" />
                <div class="metadata-edit-list-label">Series:</div>
                <input class="metadata-edit-list-entry" placeholder="Series" v-model="publication.series" @input="changed()" />
                <div class="metadata-edit-list-label">Book Title:</div>
                <input class="metadata-edit-list-entry" placeholder="Book Title" v-model="publication.booktitle" @input="changed()" />
                <div class="metadata-edit-list-label">Publisher:</div>
                <input class="metadata-edit-list-entry" placeholder="Publisher" v-model="publication.publisher" @input="changed()" />
                <div class="metadata-edit-list-label">Institution:</div>
                <input class="metadata-edit-list-entry" placeholder="University of Bonn" v-model="publication.institution" @input="changed()" />
                <div class="metadata-edit-list-label">School:</div>
                <input class="metadata-edit-list-entry" placeholder="University of Bonn" v-model="publication.school" @input="changed()" />
                <div class="metadata-edit-list-label">Issue:</div>
                <input class="metadata-edit-list-entry" placeholder="Issue" v-model="publication.issue" @input="changed()" />
                <div class="metadata-edit-list-label">Volume:</div>
                <input class="metadata-edit-list-entry" placeholder="Volume" v-model="publication.volume" @input="changed()" />
                <div class="metadata-edit-list-label">Edition:</div>
                <input class="metadata-edit-list-entry" placeholder="Edition" v-model="publication.edition" @input="changed()" />
                <div class="metadata-edit-list-label">Chapter:</div>
                <input class="metadata-edit-list-entry" placeholder="Chapter" v-model="publication.chapter" @input="changed()" />
                <div class="metadata-edit-list-label">Number:</div>
                <input class="metadata-edit-list-entry" placeholder="Number" v-model="publication.number" @input="changed()" />
                <div class="metadata-edit-list-label">First Page:</div>
                <input class="metadata-edit-list-entry" placeholder="Page Number" v-model="publication.firstpage" @input="changed()" />
                <div class="metadata-edit-list-label">Last Page:</div>
                <input class="metadata-edit-list-entry" placeholder="Page Number" v-model="publication.lastpage" @input="changed()" />
                <div class="metadata-edit-list-label">DOI:</div>
                <input class="metadata-edit-list-entry" placeholder="DOI" v-model="publication.doi" @input="changed()" />
                <div class="metadata-edit-list-label">URL:</div>
                <input class="metadata-edit-list-entry" placeholder="https://dl.acm.org/doi/10.1145/2499931.2499932" v-model="publication.url" @input="changed()" />
                <div class="metadata-edit-list-label">Location:</div>
                <input class="metadata-edit-list-entry" placeholder="Bonn, Germany" v-model="publication.location" @input="changed()" />
                <div class="metadata-edit-list-label">Address:</div>
                <input class="metadata-edit-list-entry" placeholder="Bonn, Germany" v-model="publication.address" @input="changed()" />
                <div class="metadata-edit-list-label">ISBN:</div>
                <input class="metadata-edit-list-entry" placeholder="978-3-905674-34-7" v-model="publication.isbn" @input="changed()" />
                <div class="metadata-edit-list-label">ISSN:</div>
                <input class="metadata-edit-list-entry" placeholder="1645-7641" v-model="publication.issn" @input="changed()" />
                <div class="metadata-edit-list-label">BibTeX ID:</div>
                <input class="metadata-edit-list-entry" placeholder="klein2020shortname (leave empty to auto-generate)" v-model="publication.bibtex_id" @input="changed()" />
                <div class="metadata-edit-list-label">Keywords:</div>
                <input class="metadata-edit-list-entry" placeholder="Comma-Separated,Keyword,List" v-model="publication.keywords" @input="changed()" />
                <div class="metadata-edit-list-label">Note:</div>
                <input class="metadata-edit-list-entry" placeholder='Additional notes (e.g. "won best-paper award")' v-model="publication.note" @input="changed()" />
            </div>
        </div>

        <div class="section">
            <h2>BibTeX</h2>
            <pre class="bibtex">{{ $store.getters['publications/bibtex'](publication, authors, editors) }}</pre>
        </div>
    </div>
</template>

<script>
import MarkdownIt from 'markdown-it';
import PublicationAuthorList from './PublicationAuthorList.vue';
import ImageCropper from './ImageCropper.vue';
import { EditIcon } from '@zhuowenli/vue-feather-icons';
import Req from '@citation-js/plugin-bibtex/lib/input/required.json';

function to_link({person_id, firstname, lastname, type, hidden}) {
    const name = `${firstname} ${lastname}`;
    return person_id !== undefined && type !== 'author' && !hidden
         ? `<a href="/person/${person_id}">${name}</a>`
         : name;
};


export default {
    components: {
        'authors': PublicationAuthorList,
        EditIcon,
        ImageCropper,
    },

    props: [
        'id',
    ],

    data: function() {
        return {
            publication: undefined,
            publicPath: process.env.BASE_URL,
            edit_mode: false,
            edit_timeout: undefined,
            saved_timeout: undefined,
            saved: false,
            editing: false,
            saving: false,
            authors: [],
            editors: [],
            error_msg: undefined,
        }
    },

    computed: {
        edit: function() {
            return this.$store.getters['auth/can_edit'] && this.edit_mode;
        },
        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
        status: function() {
            if (this.error_msg) return this.error_msg;
            if (this.saved) return "Saved.";
            if (this.saving) return "Saving...";
            if (this.editing) return "Editing...";
            return undefined;
        },
        notes: function() {
            return MarkdownIt().render(this.publication.notes || "");
        },
        pubmeta: function() {
            let tokens = [];
            if (this.publication.booktitle) {
                tokens.push(`In proceedings of ${this.publication.booktitle}`);
            }
            if (this.publication.journal) {
                tokens.push(`In: ${this.publication.journal}`);
            }
            if (this.publication.institution) {
                tokens.push(this.publication.institution);
            }
            if (this.publication.type && this.publication.type == 'Techreport') {
                let token = 'Technical report';
                if (this.publication.number) {
                    token += ` number ${this.publication.number}`;
                }
                tokens.push(token);
            }
            if (this.publication.firstpage && this.publication.lastpage) {
                tokens.push(`pages ${this.publication.firstpage}-${this.publication.lastpage}`);
            }
            if (this.publication.publisher) {
                tokens.push(this.publication.publisher);
            }
            if (this.publication.year) {
                tokens.push(this.publication.year);
            }
            return tokens.join(', ');
        },
        person_link: () => (person) => {
            return to_link(person);
        },
        author_line: function() {
            let authors = [...this.authors];
            authors.sort((a,b) => { return a.rank - b.rank; });
            return authors.map(to_link).join(' and ');
        },
        editor_line: function() {
            let editors = [...this.editors];
            editors.sort((a,b) => { return a.rank - b.rank; });
            let line = editors.map(to_link).join(' and ');
            if (this.editors.length) {
                line += ' <i>(Editors)</i>';
            }
            return line;
        },
    },

    methods: {
        changed: function() {
            // this.update_bibtex();
            this.saved = false;
            this.saving = false;
            this.editing = true;
            this.error_msg = undefined;
            if (this.edit_timeout !== undefined) {
                clearTimeout(this.edit_timeout);
            }
            if (this.saved_timeout !== undefined) {
                clearTimeout(this.saved_timeout);
            }
            this.edit_timeout = setTimeout(() => {
                this.save();
                this.edit_timeout = undefined;
            }, 2000);
        },
        save: function() {
            this.editing = false;
            this.saving = true;
            const data = {
                publication: this.publication,
                authors: this.authors,
                editors: this.editors,
            };
            this.$store.dispatch('publications/update', data).then((pub) => {
                this.publication = pub;
                this.$store.dispatch('authors/fetch_for_publication', this.id).then(() => {
                    const all_authors = this.$store.getters['authors/for_publication'](this.id);
                    this.authors = all_authors ? all_authors.filter((a) => !a.is_editor) : [];
                    this.editors = all_authors ? all_authors.filter((a) =>  a.is_editor) : [];
                });

                this.saving = false;
                this.saved = true;
                this.saved_timeout = setTimeout(() => {
                    this.saved = false;
                }, 3000);
            }).catch((e) => {
                this.saving = false;
                this.saved = false;
                this.error_msg = e;
            });
            setTimeout(() => {
                this.saving = false;
                this.saved = true;
                this.saved_timeout = setTimeout(() => {
                    this.saved = false;
                }, 3000);
            }, 1000);
        },
        authors_changed: function(authors) {
            this.authors = authors;
            this.changed();
        },
        editors_changed: function(authors) {
            this.editors = authors;
            this.changed();
        },
        init_data() {
            this.publication = this.$store.getters['publications/single'](this.id);
            if (this.publication?.authors) {
                this.authors = this.publication.authors.filter((a) => !a.is_editor);
                this.editors = this.publication.authors.filter((a) =>  a.is_editor);
                this.authors.sort((a,b) => b.rank - a.rank);
                this.editors.sort((a,b) => b.rank - a.rank);
            }
        },
    },

    async mounted() {
        this.init_data();
        // this component might be mounted before any data arrived, so on arrival
        // reset data state
        this.$watch(() => this.$store.state.publications.publications,
            (newVal, oldVal) => {
                this.init_data();
            }
        );
        if (this.$route.params.edit) {
            this.edit_mode = this.$route.params.edit === 'true';
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.infobox {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid white;
}

div.error {
    background: #cb5858;
    border: 2px solid var(--red);
}

h1.title {
    display: inline;
    margin: 0 1em 0 0;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
}

a.edit-toggle {
    color: var(--uni-blue);
}

a.edit-toggle.active {
    color: var(--green);
}

div.saved {
    background: #a1ffa4;
    border: 2px solid #5bd15e;
}

div.editing {
    background: #ffce71;
    border: 2px solid #d1a85b;
}

.publication-full {
    position: relative;
    padding-top: 50px;
}

div.section {
    margin-top: 50px;
}

div.notes {
    margin-top: 1em;
}

pre.bibtex {
    padding-left: 2em;
    white-space: pre-wrap;
}

input.title {
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
    display: inline;
    font-size: 2em;
    margin-bottom: 5px;
    margin-right: 1em;
    padding: 4px;
    max-width: 1400px;
}

textarea.abstract {
    width: 100%;
    height: 10em;
    padding: 5px;
    resize: none;
}

textarea.notes {
    width: 100%;
    height: 10em;
    padding: 5px;
    resize: none;
    margin-top: 1em;
    box-sizing: border-box;
}

div.abstract-section {
    display: grid;
    grid-template-columns: auto 200px;
    grid-gap: 2em;
}

div.metadata-edit-list {
    display: grid;
    grid-template-columns: fit-content(500px) fit-content(1200px);
    column-gap: 2.5em;
    row-gap: 0.5em;
    margin-left: 2em;
}

div.metadata-edit-list-label {
    font-weight: bold;
    padding-top: 0.1em;
}

input.metadata-edit-list-entry {
    width: 25em;
}

.metadata-edit-list-selectable {
    width: 25.5em;
    border: 1px solid #333;
}

.metadata-edit-list-selectable a {
    display: block;
    color: black;
    padding: 3px;
}

.metadata-edit-list-selectable a:hover {
    color: var(--uni-blue);
}

.metadata-edit-list-selectable a.selected {
    color: white;
    background-color: var(--uni-blue);
}

.visible-toggle {
    margin-bottom: 1em;
}
</style>
