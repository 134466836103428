<template>
    <div>
        <div class="lang-segment">
            <div class="container">
                <div class="lang-spread">
                    <div class="lang-selector">
                        <a href="#"
                           :class="{ 'lang-selector-link': true, active: $store.state.lang === 'de'}"
                           @click.prevent="set_lang('de')"
                        >
                           DE
                        </a>
                        <a href="#"
                           :class="{ 'lang-selector-link': true, active: $store.state.lang === 'en'}"
                           @click.prevent="set_lang('en')"
                        >
                           EN
                        </a>
                    </div>
                    <admin-main-menu />
                </div>
            </div>
        </div>
        <div class="navbar-segment">
            <div class="container navbar">
                <div class="navbar-logo-section">
                    <img src="./images/logo_desktop.svg" class="main-logo" />
                    <div class="institute-name">
                        {{ $t('menu.heading') }}<br />
                        {{ $t('menu.institute') }}
                    </div>
                </div>
                <div class="main-menu">
                    <router-link class="main-menu-entry" to="/">{{ $t('menu.home') }}</router-link>
                    <router-link class="main-menu-entry" to="/people">{{ $t('menu.people') }}</router-link>
                    <router-link class="main-menu-entry" to="/publications">{{ $t('menu.publications') }}</router-link>
                    <router-link class="main-menu-entry" to="/courses">{{ $t('menu.teaching') }}</router-link>
                    <router-link class="main-menu-entry" to="/jobs">{{ $t('jobs.title') }}</router-link>
                    <!-- <router-link class="main-menu-entry" to="/activities">Activities</router-link> -->
                    <!-- <router-link class="main-menu-entry" to="/projects">Projects</router-link> -->
                    <!-- <router-link class="main-menu-entry" to="/publications">Publications</router-link> -->
                    <!-- <router-link class="main-menu-entry" to="/jobs">Jobs</router-link> -->
                    <router-link v-if="$store.getters['auth/authenticated']" class="main-menu-entry" to="/settings">{{ $t('menu.settings') }}</router-link>
                </div>
            </div>
        </div>

        <router-view/>
    </div>

    <div class="footer-segment">
        <div class="container">
            <div v-if="$store.state.lang === 'de'" class="footer">
                <div class="footer-address-segment">
                    <h3>Besuchsaddresse</h3>
                        <p>
                            Rheinische Friedrich-Wilhelms-Universität Bonn<br />
                            Institut für Informatik II<br />
                            Friedrich-Hirzebruch-Allee 8<br />
                            D-53115 Bonn<br />
                            Deutschland
                        </p>
                    <h3>Kontakt</h3>
                        <p>
                            Telefon: +49 228 73-4191<br />
                            Fax: +49 228 73-4212<br />
                            E-Mail: secret2@cs.uni-bonn.de
                        </p>
                </div>
                <div class="footer-resp-segment">
                    <h3>Postanschrift</h3>
                        <p>
                            Rheinische Friedrich-Wilhelms-Universität Bonn<br />
                            Institut für Informatik II<br />
                            Friedrich-Hirzebruch-Allee 5<br />
                            D-53115 Bonn<br />
                            Deutschland
                        </p>
                </div>
                <div class="footer-further-segment">
                    <h3>Verantwortlichkeiten</h3>
                        <p>
                            Das Webangebot der Universität Bonn setzt sich aus vielen verschiedenen Quellen zusammen.<br />
                            Die Webseiten werden inhaltlich verantwortlich gemäß § 6 MDStV wie folgt betreut:
                        </p>
                        <p>
                            Die Seiten der Informatik II:<br />
                            <span style="margin-left: 1em">Prof. Dr. Reinhard Klein</span><br />
                            <span style="margin-left: 1em">Telefon: +49 228-73-4201, Fax: +49 228-73-4212</span><br />
                            <span style="margin-left: 1em">E-Mail: <a href="mailto:rk@cs.uni-bonn.de">rk@cs.uni-bonn.de</a></span><br />
                        </p>
                        <p>
                            Die zentralen Webseiten:<br />
                            <span style="margin-left: 1em">Department 8.2 Media and Communication</span><br />
                            <span style="margin-left: 1em">Dr. Andreas Archut (head of department)</span><br />
                            <span style="margin-left: 1em">Phone: +49 228-73-7647, Fax: +49 228-73-7451</span><br />
                            <span style="margin-left: 1em">E-Mail: <a href="mailto:andreas.archut@uni-bonn.de">andreas.archut@uni-bonn.de</a></span><br />
                        </p>
                    <h3>Weitere Angaben</h3>
                        <p>
                            Die Rheinische Friedrich-Wilhelms-Universität Bonn ist eine Körperschaft des öffentlichen Rechts.<br />
                            Sie wird durch den <a href="http://www.uni-bonn.de/Die_Universitaet/Rektorat.html">amtierenden Rektor</a> gesetzlich vertreten.
                        </p>
                        <p>
                            Zuständige Aufsichtsbehörde:<br />
                            Ministerium für Wissenschaft und Forschung des Landes Nordrhein-Westfalen,<br />
                            Völklinger Straße 49, 40221 Düsseldorf.
                        </p>
                        <p>
                            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE 122119125.
                        </p>
                </div>
            </div>
            <div v-else class="footer">
                <div class="footer-address-segment">
                    <h3>Street address</h3>
                        <p>
                            University of Bonn<br />
                            Institute of Computer Science II<br />
                            Friedrich-Hirzebruch-Allee 8<br />
                            D-53115 Bonn<br />
                            Germany
                        </p>
                    <h3>Contact</h3>
                        <p>
                            Phone: +49 228 73-4191<br />
                            Fax: +49 228 73-4212<br />
                            E-Mail: secret2@cs.uni-bonn.de
                        </p>
                </div>
                <div class="footer-resp-segment">
                    <h3>Postal address</h3>
                        <p>
                            University of Bonn<br />
                            Institute of Computer Science II<br />
                            Friedrich-Hirzebruch-Allee 5<br />
                            D-53115 Bonn<br />
                            Germany
                        </p>
                </div>
                <div class="footer-further-segment">
                    <h3>Responsibilities</h3>
                        <p>
                            The web site of the University of Bonn consists of many different sources.<br />
                            According to § 6 MDStV the contents are supervised as follows:
                        </p>
                        <p>
                            The web site of the Institute of Computer Science II:<br />
                            <span style="margin-left: 1em">Prof. Dr. Reinhard Klein</span><br />
                            <span style="margin-left: 1em">Phone: +49 228-73-4201, Fax: +49 228-73-4212</span><br />
                            <span style="margin-left: 1em">E-Mail: <a href="mailto:rk@cs.uni-bonn.de">rk@cs.uni-bonn.de</a></span><br />
                        </p>
                        <p>
                            The central web sites:<br />
                            <span style="margin-left: 1em">Department 8.2 Media and Communication</span><br />
                            <span style="margin-left: 1em">Dr. Andreas Archut (head of department)</span><br />
                            <span style="margin-left: 1em">Phone: +49 228-73-7647, Fax: +49 228-73-7451</span><br />
                            <span style="margin-left: 1em">E-Mail: <a href="mailto:andreas.archut@uni-bonn.de">andreas.archut@uni-bonn.de</a></span><br />
                        </p>
                    <h3>Further Information</h3>
                        <p>
                            The University of Bonn is a foundation under public law.
                            Lawfully it is represented by the <a href="http://www.uni-bonn.de/Die_Universitaet/Rektorat.html">vice chancellor</a>.
                        </p>
                        <p>
                            Competent supervisory authority:<br />
                            German Federal Ministry of Research North Rhine-Westphalia,<br />
                            Völklinger Straße 49, 40221 Düsseldorf.
                        </p>
                        <p>
                            Tax identification number (§27a UStG): DE 122119125.
                        </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminMainMenu from './components/AdminMainMenu.vue';

export default {
    components: {
        'admin-main-menu': AdminMainMenu
    },

    async created() {
        this.$root.$i18n.locale = this.$store.state.lang;
        await this.$store.dispatch('auth/init');
        await this.$store.dispatch('fetch_data');
    },

    methods: {
        set_lang(lang) {
            this.$store.commit('set_lang', lang);
            this.$root.$i18n.locale = lang;
        },
    },
}
</script>

<style>
:root {
    /* base extends */
    --page-width: 1536px;
    --banner-height: 600px;
    /* fonts */
    --running-font: 'Roboto', sans-serif;
    --running-font-weight: 400;
    --heading-font: 'Exo 2', sans-serif;
    --heading-font-weight: 600;
    --heading-font-light-weight: 400;
    /* colors */
    --uni-blue: #07529a;
    --uni-yellow: #eab90c;
    --uni-light-yellow: #edd687;
    --uni-grey: #909085;
    --dark-grey: #1A181B;
    --red: #af0000;
    --green: #30a050;
    --light-green: #5edb5e;
}

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: var(--running-font);
    font-weight: var(--running-font-weight);
    background-color: var(--uni-blue);
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

a {
    color: var(--uni-blue);
    text-decoration: none;
}

h1 {
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
}

h2 {
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
}

h3 {
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
}

.container {
    width: 100% !important;
    max-width: 1536px !important;
    margin: 0 auto;
}

.lang-segment {
    width: 100%;
    background-color: var(--uni-blue);
    padding-top: 20px;
    padding-bottom: 20px;
}

.lang-spread {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

a.lang-selector-link {
    font-family: var(--heading-font);
    font-weight: var(--heading-font-light-weight);
    font-size: 19px;
    margin-right: 10px;
    color: white;
}

a.lang-selector-link.active {
    font-weight: var(--heading-font-weight) !important;
}

.navbar-segment {
    width: 100%;
    background-color: white;
    padding: 0;
}

.navbar {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.navbar-logo-section {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}

.main-logo {
    margin: 10px 0;
    padding: 0;
    height: 78px;
}

.institute-name {
    font-size: 1.8em;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-light-weight);
    padding: 0.4em 1em;
}

.main-menu {
}

a.main-menu-entry {
    line-height: 98px;
    display: inline-block;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-light-weight);
    font-size: 1em;
    height: 78px;
    color: var(--dark-grey);
    margin: 0px 15px;
    border: 0;
    padding: 0;
    min-height: 100%;
    box-sizing: border-box;
    text-transform: uppercase;
}

a.main-menu-entry.router-link-active {
    font-weight: var(--heading-font-weight);
    color: var(--uni-blue);
    border-bottom: 5px solid;
    border-color: var(--uni-blue);
}

.banner-segment {
    background-image: url("./images/banner.jpg");
    background-position-x: 30%;
    background-position-y: 30%;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-size: cover;
    color: black;
}

.banner-shadow {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 600px;
    color: white;
    text-transform: uppercase;
}

.banner-segment h1 {
    font-size: 5em;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
    margin: 20px 0 0 0;
}

.banner-segment h2 {
    font-size: 1.5em;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-light-weight);
    margin: 0;
}

.separator-segment {
    width: 100%;
    background-color: var(--uni-grey);
    min-height: 20px;
}

.separator-line {
    width: 100%;
    background-color: var(--uni-grey);
    margin: 10px 0;
    min-height: 2px;
}

.admin-menu {
    position: absolute;
    top: 112px;
    right: 20px;
}

.admin-menu svg {
    color: var(--uni-blue);
    font-size: 1.5em;
}

.admin-menu svg.admin-menu-active {
    color: var(--green);
    font-size: 1.5em;
}

.infobox {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid white;
}

div.error {
    background: #cb5858;
    border: 2px solid var(--red);
}

div.saved {
    background: #a1ffa4;
    border: 2px solid #5bd15e;
}

div.editing {
    background: #ffce71;
    border: 2px solid #d1a85b;
}

.card-info .person-contact {
    display: grid;
    grid-template-columns: auto auto;
}

.content-segment {
    background-color: white;
    padding: 2em 0;
    min-height: 700px;
}

.footer-segment {
    width: 100%;
    min-height: 200px;
    background-color: var(--uni-blue);
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
}

.footer {
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2em 0;
}

.footer a {
    color: var(--uni-yellow);
}
</style>
