import { get, put, post, del } from '@/services/backend'

export const addresses = {
    namespaced: true,

    state: {
        addresses: {},
    },
    getters: {
    },
    mutations: {
        set(state, addresses) {
            for (const address of addresses) {
                const id = address.address_id;
                state.addresses[id] = address;
            }
        },
        set_by_id(state, { id, address }) {
            state.addresses[parseInt(id)] = address;
        },
        remove_by_id(state, id) {
            delete state.addresses[parseInt(id)];
        },
    },
    actions: {
        async fetch({ commit, state }) {
            if (state.addresses.length) return;
            const addresses = await get('/addresses');
            commit('set', addresses.addresses);
        },
        async update({ commit, state, rootGetters }, address) {
            const token = rootGetters['auth/token'];
            const res = await put(`/addresses/${address.address_id}`, address, token);
            commit('set_by_id', { id: parseInt(res.address.address_id),
                                  address: res.address });
        },
        async remove({ commit, state, rootGetters }, id) {
            const token = rootGetters['auth/token'];
            await del(`/addresses/${id}`, {}, token);
            commit('remove_by_id', id);
            dispatch('fetch_persons');
        },
        async add({ commit, state, rootGetters }, address) {
            const token = rootGetters['auth/token'];
            const res = await post(`/addresses`, address, token);
            commit('set_by_id', { id: parseInt(res.address.address_id),
                                  address: res.address });
        },
    },
};
