<template>
    <div class="separator-segment" />
    <div class="content-segment">
        <div class="container">
            <CoursesBySemester />
        </div>
    </div>
</template>

<script>
import CoursesBySemester from '@/components/CoursesBySemester.vue';

export default {
    name: 'Courses',
    components: {
        CoursesBySemester,
    },
}
</script>
