<template>
    <div :class="{ infobox: true, error: error_msg, saved: !error_msg && saved, editing: (!error_msg && !saved && (saving || editing)) }">{{ status || '&nbsp;' }}</div>
    <div v-if="can_edit" class="settings">
        <h1>{{ $t('settings.heading') }}</h1>

        <template v-if="$store.getters['auth/is_admin']">
            <h2 class="user-list">{{ $t('settings.users') }}</h2>
            <user-list />
        </template>

        <div class="separator-line" />

        <h2>Addresses</h2>
        <a href="#" @click.prevent="edit_addresses = !edit_addresses">
            <edit-icon size="1.5x" />
        </a>

        <addresses :edit="edit_addresses" />
    </div>
</template>

<script>
import { EditIcon } from '@zhuowenli/vue-feather-icons';
import Addresses from '@/components/Addresses.vue';
import UserList from '@/components/UserList.vue';

export default {
    components: {
        Addresses,
        EditIcon,
        UserList,
    },

    props: [],

    data: function() {
        return {
            edit_addresses: false,
            edit_timeout: undefined,
            saved_timeout: undefined,
            saved: false,
            editing: false,
            saving: false,
            error_msg: undefined,
        }
    },

    computed: {
        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
        status: function() {
            if (this.error_msg) return this.error_msg;
            if (this.saved) return "Saved.";
            if (this.saving) return "Saving...";
            if (this.editing) return "Editing...";
            return undefined;
        },
    },

    methods: {
    },


    async created() {
        // await this.$store.dispatch('fetch_author_publications', this.id);
        // this.person = this.$store.getters.person(this.id);
        // this.original = { ...this.person };
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infobox {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid white;
}

div.error {
    background: #cb5858;
    border: 2px solid var(--red);
}

div.saved {
    background: #a1ffa4;
    border: 2px solid #5bd15e;
}

div.editing {
    background: #ffce71;
    border: 2px solid #d1a85b;
}
div.address-list {
    padding-left: 2em;
}

h2 {
    display: inline;
    margin-right: 1em;
}

h2.user-list {
    display: block;
}
</style>
