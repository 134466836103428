<template>
    <input type="text"
           :class="class"
           v-model="modelValue"
           :size="input_size"
    />
</template>


<script>
export default {
    props: {
        'modelValue': {
            type: String,
        },
        'class': {
            type: String,
        },
        'length': {
            type: Number,
            default: 30,
        },
        'fixed': {
            type: Number,
        },
    },
    emits: ['update:modelValue', 'changed'],
    data: function() {
        return {
            input_size: this.length,
        };
    },
    watch: {
        modelValue() {
            this.$emit('update:modelValue', this.modelValue);
            this.$emit('changed');
        }
    },
    mounted() {
        if (this.$props.fixed) {
            this.input_size = this.$props.fixed;
            return;
        }
        if (this.$props.modelValue) {
            this.input_size = this.$props.modelValue.length-2;
        }
    },
}
</script>
