import { get, put, post, del } from '@/services/backend'

export const jobs = {
    namespaced: true,

    state: {
        jobs: {},
    },
    getters: {
        jobs: (state, getters, rootState, rootGetters) => {
            return state.jobs;
        },
    },
    mutations: {
        set(state, jobs) {
            for (const post of jobs) {
                state.jobs[parseInt(post.job_id)] = post;
            }
        },
        set_by_id(state, { id, jobs }) {
            state.jobs[id] = jobs;
        },
        remove_by_id(state, id) {
            if (id in state.jobs) {
                delete state.jobs[id];
            }
        },
    },
    actions: {
        async fetch({ commit, state, rootGetters }) {
            const token = rootGetters['auth/token'];
            const jobs = await get('/jobs', token);
            commit('set', jobs.jobs);
        },
        async update({ commit, state, rootGetters }, jobs) {
            const token = rootGetters['auth/token'];
            const res = await put(`/jobs/${jobs.job_id}`, jobs, token);
            commit('set_by_id', { id: parseInt(res.jobs.job_id),
                                  jobs: res.jobs });
        },
        async remove({ commit, state, rootGetters }, id) {
            const token = rootGetters['auth/token'];
            await del(`/jobs/${id}`, {}, token);
            commit('remove_by_id', parseInt(id));
        },
        async add({ commit, state, rootGetters }, jobs) {
            const token = rootGetters['auth/token'];
            const res = await post(`/jobs`, jobs, token);
            commit('set_by_id', { id: parseInt(res.jobs.job_id),
                                  jobs: res.jobs });
        },
    },
};
