<template>
<div class="contain" :style="{ 'padding-top':  edit ? '0' : '2em' }">
    <list-entry v-if="edit"
                :first="first"
                :deletable="true"
                :selectable="false"
                :addable="false"
                :active="false"
                @delete="$emit('remove')"
                :width="800">
        <div :class="{ infobox: true, error: error_msg, saved: !error_msg && saved, editing: (!error_msg && !saved && (saving || editing)) }">{{ status || '&nbsp;' }}</div>
        <div class="content">
            <a :href="$store.getters['courses/material_download'](modelValue)">
                <img v-if="modelValue.thumbs.length" class="thumb" :src="$store.getters['courses/material_thumbnail'](modelValue)" />
            </a>

            <div class="meta">
                <text-input v-model="modelValue.name" class="name-input" :fixed="20" @changed="changed()" />
                <div class="file-spec">({{ (modelValue.size / (1024*1024)).toFixed(2) }}MiB, {{ modelValue.ext }})</div>

                <div class="sub-materials">
                    <div v-for="sub in modelValue.sub_materials" class="sub-material">
                        <a :href="$store.getters['courses/material_download'](sub)" class="sub-file-name">
                            <file-icon size="1.0x" />
                            {{ sub.name }}
                        </a>
                        <span class="sub-file-spec">({{ format_size(sub.size) }}, {{ sub.ext }})</span>
                        <a href="#" class="sub-remove" @click.prevent="$emit('remove-sub', sub)">
                            <trash-2-icon size="1.0x" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="controls">
                <label for="hidden">
                    <input type="checkbox"
                           id="hidden"
                           :true-value="1"
                           :faĺse-value="0"
                           v-model="modelValue.hidden"
                           @change="changed()" />
                    {{ $t('generic.hidden') }}
                </label>
                <a href="#" @click.prevent="$emit('add-sub-material')">
                    <file-plus-icon size="1.5x" />
                </a>
            </div>
        </div>
    </list-entry>
    <div v-else class="content">
        <a :href="$store.getters['courses/material_download'](modelValue)">
            <img class="thumb" :src="$store.getters['courses/material_thumbnail'](modelValue)" />
        </a>

        <div class="meta">
            <a class="name" :href="$store.getters['courses/material_download'](modelValue)">{{ modelValue.name }}</a>
            <span v-if="modelValue.hidden" class="hidden-tag">(hidden)</span>
            <div class="file-spec">({{ format_size(modelValue.size) }}, {{ modelValue.ext }})</div>

            <div class="sub-materials">
                <div v-for="sub in modelValue.sub_materials" class="sub-material">
                    <a :href="$store.getters['courses/material_download'](sub)" class="sub-file-name">
                        <file-icon size="1.0x" />
                        {{ sub.name }}
                    </a>
                    <span class="sub-file-spec">({{ format_size(sub.size) }}, {{ sub.ext }})</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { FileIcon, FilePlusIcon, Trash2Icon } from '@zhuowenli/vue-feather-icons';
import TextInput from '@/components/TextInput';
import ListEntry from '@/components/ListEntry';

export default {
    components: {
        ListEntry,
        TextInput,
        FileIcon,
        FilePlusIcon,
        Trash2Icon,
    },

    props: {
        'modelValue': {
            type: Object,
            required: true,
        },
        'first': {
            type: Boolean,
        },
        'edit': {
            type: Boolean,
        },
    },

    emits: ['update:modelValue', 'changed', 'add-sub-material', 'remove', 'remove-sub'],

    data: function() {
        return {
            edit_timeout: undefined,
            saved_timeout: undefined,
            saved: false,
            editing: false,
            saving: false,
            error_msg: undefined,
        }
    },

    watch: {
        modelValue() {
            this.$emit('update:modelValue', this.modelValue);
            this.$emit('changed');
        }
    },

    computed: {
        status: function() {
            if (this.error_msg) return this.error_msg;
            if (this.saved) return "Saved.";
            if (this.saving) return "Saving...";
            if (this.editing) return "Editing...";
            return undefined;
        },
    },

    methods: {
        changed: function() {
            this.saved = false;
            this.saving = false;
            this.editing = true;
            this.error_msg = undefined;
            if (this.edit_timeout !== undefined) {
                clearTimeout(this.edit_timeout);
            }
            if (this.saved_timeout !== undefined) {
                clearTimeout(this.saved_timeout);
            }
            this.edit_timeout = setTimeout(() => {
                this.save();
                this.edit_timeout = undefined;
            }, 2000);
        },
        save: function() {
            this.editing = false;
            this.saving = true;
            let data = { ...this.$props.modelValue };
            data.hidden = data.hidden ? 1 : 0;
            this.$store.dispatch('courses/update_material', this.$props.modelValue).then((m) => {
                this.saving = false;
                this.saved = true;
                this.saved_timeout = setTimeout(() => {
                    this.saved = false;
                }, 3000);
            }).catch((e) => {
                this.saving = false;
                this.saved = false;
                this.error_msg = e;
            });
        },
        format_size: function(size) {
            let unit = "B";
            if (size > 1024) {
                size /= 1024.0;
                unit = "KiB";
            }
            if (size > 1024) {
                size /= 1024.0;
                unit = "MiB";
            }
            return size.toFixed(1) + unit
        },
    },
}
</script>

<style scoped>
.contain {
    padding-left: 2em;
    /* padding-top: 2em; */
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 1em;
    align-items: stretch;
}

img.thumb {
    width: 50px;
    border: 1px solid #bbb;
    filter: drop-shadow(3px 3px 2px rgb(0, 0, 0, 0.3));
}

.name-input {
    width: 450px;
    font-size: 1.0em;
}

a.name {
    font-size: 1.0em;
}

.file-spec {
    color: var(--uni-grey);
    font-size: 0.8em;
    margin-top: 0.2em;
    margin-left: 0.2em;
}

.sub-file-spec {
    color: var(--uni-grey);
    font-size: 0.8em;
}

.infobox {
    margin: 0;
    padding: 2px 5px;
    margin-bottom: 2px;
    border-width: 1px;
}

.controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

input#hidden {
    display: inline;
}

label {
    white-space: nowrap;
}

.sub-materials {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.5em 0 0 0;
}

.sub-material {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: flex-start;
}

.sub-file-name {
    margin-left: 0.2em;
    margin-right: 0.5em;
}

.sub-remove {
    color: red;
    margin-left: 1em;
}

.hidden-tag {
    margin-left: 1em;
    color: var(--red);
}
</style>
