import axios from 'axios';

export const api_url = 'https://cgsite.oglaroon.cc/backend/v1';
export const file_url = 'https://cgsite.oglaroon.cc/backend/v1/files';
// export const api_url = 'http://78.47.91.15:3000/backend/v1';

export async function
request(data) {
    try {
        const response = await axios(data, { withCredentials: true });

        if (!response.data) {
            throw('Answer does not contain response data');
        }
        if (response.data.error) {
            throw(response.data.error.messages);
        }

        return response.data;
    } catch(e) {
        if (e.response?.status === 401) {
            throw('Invalid credentials');
        }
        if (e.response) {
            throw(`${e.response.statusText} (${e.response.status})`);
        }
        throw(e);
    }
}

export async function
get(path, token) {
    let headers = {
    };
    if (token !== undefined) {
        headers.Authorization = `Bearer ${token}`;
    }
    return request({
        url: api_url + path,
        method: 'get',
        headers,
    })
}

export async function
post(path, data, token, content_type = 'application/json') {
    let headers = {
        'Content-Type': content_type,
    };
    if (token !== undefined && token !== null) {
        headers.Authorization = `Bearer ${token}`;
    }
    return request({
        url: api_url + path,
        method: 'post',
        data,
        headers,
    })
}

export async function
put(path, data, token, content_type = 'application/json') {
    let headers = {
        'Content-Type': content_type,
    };
    if (token !== undefined && token !== null) {
        headers.Authorization = `Bearer ${token}`;
    }
    return request({
        url: api_url + path,
        method: 'put',
        data,
        headers,
    })
}

export async function
del(path, data, token, content_type = 'application/json') {
    let headers = {
        'Content-Type': content_type,
    };
    if (token !== undefined && token !== null) {
        headers.Authorization = `Bearer ${token}`;
    }
    return request({
        url: api_url + path,
        method: 'delete',
        data,
        headers,
    })
}

export async function
upload(path, data, token, content_type = 'application/json', progress_callback = undefined) {
    let headers = {
        'Content-Type': content_type,
    };
    if (token !== undefined && token !== null) {
        headers.Authorization = `Bearer ${token}`;
    }
    return request({
        url: api_url + path,
        method: 'post',
        data,
        headers,
        onUploadProgress: (p) => {
            if (progress_callback !== undefined) {
                const progress = p.loaded / p.total;
                progress_callback(progress);
            }
        },
    })
}
