<template>
    <div class="separator-segment" />
    <div class="content-segment">
        <div class="container">
            <job-postings />
        </div>
    </div>
</template>

<script>
import JobPostings from '@/components/JobPostings';

export default {
    name: 'Jobs',
    components: {
        JobPostings,
    },
}
</script>

<style scoped>
</style>
