<template>
    <div v-if="pubs.length" class="publications">
        <div v-if="collapsed" class="year-selector-bar">
            <template v-for="year of years">
                <span v-if="year === current" class="year-selector">
                    {{ year }}
                </span>
                <a href="#" v-if="year != current" class="year-selector" @click.prevent="active_year = year">
                    {{ year }}
                </a>
            </template>
        </div>
        <div v-for="year of years" class="publication-year">
            <h3 v-if="!collapsed">{{ year }}</h3>
            <div v-if="!collapsed || (year && year == current)" class="publication-list">
                <template v-for="pub of per_year[year]">
                    <a href="#" @click.prevent="navigate_to(pub)">
                        <div class="teaser">
                            <img class="teaser" v-if="pub.teaser" :src="$store.getters['publications/teaser'](pub)" />
                        </div>
                    </a>
                    <div class="publication-data">
                        <div class="publication-meta">
                            <b>{{ pub.title }}</b><br />
                            <div v-html="editor_line(pub.pub_id)" />
                            <div v-html="author_line(pub.pub_id)" />
                            <i>{{ pubmeta(pub) }}</i>
                        </div>
                        <div class="publication-links">
                            <a class="publication-link" href="#" @click.prevent="navigate_to(pub)">
                                <b>[Details]</b>
                            </a>
                            <a class="publication-link" v-if="pub.pdf" :href="$store.getters['publications/paper'](pub)">
                                <b>[PDF]</b>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },

    props: [
        'id',
    ],

    data: function() {
        return {
            // publication_years: [],
            // publications: {},
            // author_lines: {},
            // editor_lines: {},
            // collapsed: false,
            active_year: undefined,
        }
    },

    computed: {
        pubs() {
            if (this.id) {
                return this.$store.getters['publications/for_author'](this.id);
            } else {
                return this.$store.getters['publications/all'];
            }
        },
        per_year() {
            let map = {};
            for (const pub of this.pubs) {
                if (!pub.year) {
                    continue;
                }
                const year = parseInt(pub.year);
                if (map[year] == undefined) {
                    map[year] = [];
                }
                map[year].push(pub);
            }
            return map;
        },
        years() {
            let years = Object.keys(this.per_year);
            years.sort((a,b) => b - a);
            return years;

        },
        current() {
            return this.active_year || this.years[0];
        },
        collapsed() {
            return this.pubs?.length > 30
        },
    },

    methods: {
        editor_line(id) {
            let all = this.$store.state.publications.publications[id].authors;

            if (all !== undefined) {
                all.sort((a,b) => { return a.rank - b.rank; });
                const to_link = ({person_id, firstname, lastname, type, hidden}) => {
                    const name = `${firstname} ${lastname}`;
                    return person_id !== undefined && type !== 'author' && !hidden
                         ? `<a href="/person/${person_id}">${name}</a>`
                         : name;
                };
                const editors = all.filter((a) => a.is_editor);
                if (!editors.length) return "";
                return editors.map(to_link).join(' and ') + ' <i>(Editors)</i>';
            }
            return "";
        },
        author_line(id) {
            let all = this.$store.state.publications.publications[id].authors;
            if (all !== undefined) {
                all.sort((a,b) => { return a.rank - b.rank; });
                const to_link = ({person_id, firstname, lastname, type, hidden}) => {
                    const name = `${firstname} ${lastname}`;
                    return person_id !== undefined && type !== 'author' && !hidden
                         ? `<a href="/person/${person_id}">${name}</a>`
                         : name;
                };
                return all.filter((a) => !a.is_editor).map(to_link).join(' and ');
            }
            return "";
        },
        pubmeta(pub) {
            let tokens = [];
            if (pub.booktitle) {
                tokens.push(`In proceedings of ${pub.booktitle}`);
            }
            if (pub.journal) {
                tokens.push(`In: ${pub.journal}`);
            }
            if (pub.institution) {
                tokens.push(pub.institution);
            }
            if (pub.type && pub.type == 'Techreport') {
                let token = 'Technical report';
                if (pub.number) {
                    token += ` number ${pub.number}`;
                }
                tokens.push(token);
            }
            if (pub.firstpage && pub.lastpage) {
                tokens.push(`pages ${pub.firstpage}-${pub.lastpage}`);
            }
            if (pub.publisher) {
                tokens.push(pub.publisher);
            }
            if (pub.year) {
                tokens.push(pub.year);
            }
            return tokens.join(', ');
        },
        navigate_to(pub) {
            this.$router.push({ name: 'Publication', params: { id: pub.pub_id, edit: false, } });
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.teaser {
    height: 120px;
    width: 160px;
    min-height: 100px;
    border: 1px solid #bbb;
}

img.teaser {
    height: 120px;
    width: 160px;
    /* height: 100%; */
}

div.publication-year {
    padding-left: 20px;
}

div.publication-list {
    display: grid;
    grid-template-columns: 150px auto;
    grid-gap: 1.5em;
    padding: 1em 1em;
}

div.publication-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
}

a.publication-link {
    margin-right: 10px;
}

.year-selector-bar {
    display: flex;
    flex-wrap: wrap;
}

.year-selector {
    margin-bottom: 0.5em;
    margin-left: 20px;
    font-weight: 600;
}

span.year-selector {
    color: var(--uni-yellow);
}
</style>

