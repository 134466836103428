<template>
    <div class="people">
        <div class="people-section">
            <h2>{{ $t('people.groupleaders') }}</h2>
            <PersonBrief v-for="person of group_leaders" :person="person" />
        </div>
        <div class="people-section">
            <h2>{{ $t('people.adminoffice') }}</h2>
            <PersonBrief v-for="person of admin_office" :person="person" />
        </div>
        <div class="people-section">
            <h2>{{ $t('people.researchstaff') }}</h2>
            <PersonBrief v-for="person of research_staff" :person="person" />
        </div>
        <div class="people-section">
            <h2>{{ $t('people.phdstudents') }}</h2>
            <PersonBrief v-for="person of phd_students" :person="person" />
        </div>

    </div>
    <h2>{{ $t('people.alumni') }}</h2>
    <div class="alumni">
        <PersonBrief v-for="person of alumni" :person="person" :small="true" />
    </div>

    <button v-if="can_edit" class="add" @click="add">
        <plus-icon size="1x" /> {{ $t('generic.add') }}
    </button>
</template>

<script>
import PersonBrief from './PersonBrief.vue';
import { PlusIcon } from '@zhuowenli/vue-feather-icons';

function name_cmp(a, b) {
    const na = a.lastname.toUpperCase();
    const nb = b.lastname.toUpperCase();
    if (na < nb) return -1;
    if (na > nb) return 1;
    return 0;
}

export default {
    components: {
        PersonBrief,
        PlusIcon,
    },

    computed: {
        group_leaders() {
            const persons =  this.$store.state.persons.persons.filter((person) => {
                return person.type === "Group Leaders";
            });
            persons.sort(name_cmp);
            return persons;
        },

        admin_office() {
            const persons = this.$store.state.persons.persons.filter((person) => {
                return person.type === "Administrative Office";
            });
            persons.sort(name_cmp);
            return persons;
        },

        research_staff() {
            const persons = this.$store.state.persons.persons.filter((person) => {
                return person.type === "Research Staff";
            });
            persons.sort(name_cmp);
            return persons;
        },

        phd_students() {
            const persons = this.$store.state.persons.persons.filter((person) => {
                return person.type === "PhD Students" || person.type === "Graduate School Members";
            });
            persons.sort(name_cmp);
            return persons;
        },

        alumni() {
            const persons = this.$store.state.persons.persons.filter((person) => {
                return person.type === "Alumni" || person.type === "Graduate School Alumni";
            });
            persons.sort(name_cmp);
            return persons;
        },

        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
    },

    methods: {
        async add() {
            let new_person = {
                type: "Research Staff",
                title: "M. Sc.",
                fax: "+49 (0) 228 73-4212",
                hidden: 1,
            };
            const { person_id } = await this.$store.dispatch('persons/add', new_person);
            this.$router.push({ name: 'Person', params: { id: person_id, edit: true, } });
        },
    },

    data() {
        return {
            publicPath: process.env.BASE_URL
        };
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.people {
    padding-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-bottom: 3em;
}

.alumni {
    padding-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
}

.people-section {
    /* background-color: red; */
}

h2 {
    color: black;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
    font-size: 2em;
}

button.add {
    width: 400px;
    margin-top: 5em;
    background-color: var(--uni-blue);
    padding: 10px;
    color: white;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

button.add:deep(svg) {
    margin-right: 0.3em;
}
</style>
