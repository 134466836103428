import { get, put, post } from '@/services/backend'

export const authors = {
    namespaced: true,

    state: {
        authors: [],
        publication_authors: {},
    },
    getters: {
        for_publication: (state) => (id) => {
            return state.publication_authors[id];
        },
        publication_authors_line: (state) => (id) => {
            let authors = getters.for_publication(id)
            if (authors == undefined) {
                return [];
            }
            authors = authors.filter((a) => !a.is_editor);
            return authors.map((a) => {
                return {
                    id: a.person_id,
                    name:`${a.firstname} ${a.lastname}`
                };
            });
        },
        publication_editors_line: (state) => (id) => {
            let authors = getters.for_publication(id)
            if (authors == undefined) {
                return [];
            }
            authors = authors.filter((a) => a.is_editor);
            return authors.map((a) => {
                return {
                    id: a.person_id,
                    name:`${a.firstname} ${a.lastname}`
                };
            });
        },
    },
    mutations: {
        set(state, authors) {
            state.authors = authors;
        },
        set_for_publication(state, {id, authors}) {
            state.publication_authors[id] = authors;
            state.publication_authors[id].sort((a, b) => {
                return a.rank - b.rank;
            });
        },
    },
    actions: {
        async fetch({ commit, state }) {
            if (state.authors.length) return;
            const authors = await get('/authors', state.token);
            commit('set', authors.authors);
        },
        async fetch_for_publication({ commit }, id) {
            const authors = await get(`/authors/for-publication/${id}`);
            commit('set_for_publication', {id, authors:authors.authors});
        },
    },
};
