import { get, put, post, del } from '@/services/backend'

export const news = {
    namespaced: true,

    state: {
        news: {},
        news_for_course: {},
    },
    getters: {
        news: (state, getters, rootState, rootGetters) => {
            return state.news;
        },
        for_course: (state, getters, rootState, rootGetters) => (id) => {
            return state.news_for_course[parseInt(id)];
        },
    },
    mutations: {
        set(state, news) {
            for (const post of news) {
                state.news[parseInt(post.news_id)] = post;
            }
        },
        set_for_course(state, { news, course_id }) {
            state.news_for_course[parseInt(course_id)] = news;
        },
        set_by_id(state, { id, news }) {
            state.news[id] = news;
        },
        remove_by_id(state, id) {
            if (id in state.news) {
                delete state.news[id];
            }
        },
    },
    actions: {
        async fetch({ commit, state }) {
            const news = await get('/news');
            commit('set', news.news);
        },
        async fetch_for_course({ commit, state }, id) {
            const news = await get(`/news/for-course/${id}`);
            commit('set_for_course', { news: news.news, course_id: id });
        },
        async update({ commit, state, rootGetters }, news) {
            const token = rootGetters['auth/token'];
            const res = await put(`/news/${news.news_id}`, news, token);
            commit('set_by_id', { id: parseInt(res.news.news_id),
                                  news: res.news });
        },
        async remove({ commit, state, rootGetters }, id) {
            const token = rootGetters['auth/token'];
            await del(`/news/${id}`, {}, token);
            commit('remove_by_id', parseInt(id));
        },
        async add({ commit, state, rootGetters }, news) {
            const token = rootGetters['auth/token'];
            const res = await post(`/news`, news, token);
            commit('set_by_id', { id: parseInt(res.news.news_id),
                                  news: res.news });
        },
    },
};
