<template>
    <div :class="{ infobox: true, error: error_msg, saved: !error_msg && saved, editing: (!error_msg && !saved && (saving || editing)) }">{{ status || '&nbsp;' }}</div>
    <h2>{{ $t('jobs.title') }}</h2>
    <a href="#" v-if="can_edit" @click.prevent="edit_mode = !edit_mode">
        <edit-icon size="1.5x" />
    </a>

    <div class="jobs">
        <template v-for="(post, key, idx) of jobs">
            <list-entry v-if="edit"
                        :first="idx == 0"
                        :deletable="true"
                        :selectable="false"
                        @delete="delete_post(key)">
                <template v-if="$store.state.lang == 'de'">
                    <input type="text"
                           class="title"
                           placeholder="Titel"
                           v-model="post.title_de"
                           :size="post.title_de ? (post.title_de.length) : 10"
                           @input="changed(post)"
                    />
                    <textarea class="content"
                              placeholder="Inhalt"
                              v-model="post.content_de"
                              @input="changed(post)"
                    />
                </template>
                <template v-else>
                    <input type="text"
                           class="title"
                           placeholder="Title"
                           v-model="post.title_en"
                           :size="post.title_en ? (post.title_en.length) : 10"
                           @input="changed(post)"
                    />
                    <textarea class="content"
                              placeholder="Content"
                              v-model="post.content_en"
                              @input="changed(post)"
                    />
                </template>
            </list-entry>
            <div v-if="!edit && post.hidden !== 1" class="jobs-post">
                <template v-if="$store.state.lang == 'de'">
                    <h3>{{ post.title_de || post.title_en  }}
                        <span v-if="can_edit && !post.title_de" style="color: red; margin-left: 2em; font-size:0.6em">German translation missing!</span>
                        <span v-if="can_edit && !post.title_en" style="color: red; margin-left: 2em; font-size:0.6em">English translation missing!</span>
                    </h3>
                    <div v-html="markdownify(post.content_de || post.content_en)" />
                </template>
                <template v-else>
                    <h3>{{ post.title_en || post.title_de }}
                        <span v-if="can_edit && !post.title_de" style="color: red; margin-left: 2em; font-size:0.6em">German translation missing!</span>
                        <span v-if="can_edit && !post.title_en" style="color: red; margin-left: 2em; font-size:0.6em">English translation missing!</span>
                    </h3>
                    <div v-html="markdownify(post.content_en || post.content_de)" />
                </template>
            </div>
        </template>
        <list-entry v-if="edit && $store.state.lang == 'de'"
                    :first="Object.keys(jobs).length === 0"
                    :deletable="false"
                    :selectable="false"
                    :addable="true"
                    @add="add">
            <input type="text"
                   class="title"
                   placeholder="Title"
                   v-model="add_title_de"
                   :size="10"
            />
            <textarea class="content"
                      placeholder="Content"
                      v-model="add_content_de"
            />
        </list-entry>
        <list-entry v-if="edit && $store.state.lang == 'en'"
                    :first="Object.keys(jobs).length === 0"
                    :deletable="false"
                    :selectable="false"
                    :addable="true"
                    @add="add">
            <input type="text"
                   class="title"
                   placeholder="Title"
                   v-model="add_title_en"
                   :size="10"
            />
            <textarea class="content"
                      placeholder="Content"
                      v-model="add_content_en"
            />
        </list-entry>
    </div>
</template>

<script>
import ListEntry from '@/components/ListEntry';
import { EditIcon } from '@zhuowenli/vue-feather-icons';
import MarkdownIt from 'markdown-it';

export default {
    name: 'JobPostings',
    components: {
        ListEntry,
        EditIcon,
    },
    data: function() {
        return {
            edit_mode: false,
            edit_timeout: undefined,
            saved_timeout: undefined,
            saved: false,
            editing: false,
            saving: false,
            error_msg: undefined,
            add_title_de: '',
            add_title_en: '',
            add_content_de: '',
            add_content_en: '',
        };
    },
    computed: {
        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
        edit: function() {
            return this.can_edit && this.edit_mode;
        },
        jobs: function() {
            return this.$store.getters['jobs/jobs'];
        },
        status: function() {
            if (this.error_msg) return this.error_msg;
            if (this.saved) return "Saved.";
            if (this.saving) return "Saving...";
            if (this.editing) return "Editing...";
            return undefined;
        },
    },
    methods: {
        markdownify: function(text) {
            return MarkdownIt().render(text || "");
        },
        changed(post) {
            this.saved = false;
            this.saving = false;
            this.editing = true;
            this.error_msg = undefined;
            if (this.edit_timeout !== undefined) {
                clearTimeout(this.edit_timeout);
            }
            if (this.saved_timeout !== undefined) {
                clearTimeout(this.saved_timeout);
            }
            this.edit_timeout = setTimeout(() => {
                this.save(post);
                this.edit_timeout = undefined;
            }, 2000);
        },
        save: function(post) {
            this.editing = false;
            this.saving = true;
            this.$store.dispatch('jobs/update', post).then((p) => {
                this.saving = false;
                this.saved = true;
                this.saved_timeout = setTimeout(() => {
                    this.saved = false;
                }, 3000);
            }).catch((e) => {
                this.saving = false;
                this.saved = false;
                this.error_msg = e;
            });
        },
        async add(post) {
            const data = {
                lang: this.$store.state.lang,
                title_de: this.add_title_de,
                title_en: this.add_title_en,
                content_de: this.add_content_de,
                content_en: this.add_content_en,
            };
            await this.$store.dispatch('jobs/add', data);
        },
        async delete_post(key) {
            await this.$store.dispatch('jobs/remove', key);
        },
    },
    async mounted() {
        await this.$store.dispatch('jobs/fetch');
    },
}
</script>

<style scoped>
.infobox {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid white;
}

div.error {
    background: #cb5858;
    border: 2px solid var(--red);
}

div.saved {
    background: #a1ffa4;
    border: 2px solid #5bd15e;
}

div.editing {
    background: #ffce71;
    border: 2px solid #d1a85b;
}

h2 {
    display: inline;
    margin-right: 1em;
}

.jobs {
    margin-top: 2em;
    margin-left: 2em;
    width: 100%;
}

.jobs-post {
    margin-top: 4em;
    width: 100%;
}

h2 {
    color: black;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
    font-size: 2em;
}

.jobs-post h3 {
    margin: 0;
    font-size: 1.5em;
}

input {
    border: 1px solid #a0a0a0;
}

input.title {
    display: block;
    font-size: 1.5em;
    padding: 4px;
    font-family: var(--heading-font);
    font-weight: var(--heading-font-weight);
}

textarea.content {
    width: 900px;
    height: 10em;
    padding: 5px;
    resize: none;
    margin-top: 1em;
}

.list-entry {
    width: 1000px !important;
}
</style>
