<template>
    <!-- css_props defined variables, so hook it into the root element for them to be
         defined in all child elements -->
    <div class="cropperwrapper" :style="css_props">
        <template v-if="file === undefined">
            <input hidden type="file" ref="upload" @change="select_image" />
            <template v-if="editable">
                <a href="#" v-if="editable" @click.prevent="trigger_upload()">
                    <img v-if="has_initial" :src="initial" class="initial-image" />
                    <div v-else class="initial-placeholder">&nbsp;</div>
                </a>
            </template>
            <template v-else>
                <img v-if="has_initial" :src="initial" class="initial-image" />
                <div v-else class="initial-placeholder">&nbsp;</div>
            </template>
        </template>
        <template v-else>
            <div class="cropper">
                <div class="preview">
                    <img ref="orig_img" class="orig-img" />
                    <div class="overlay" @mousedown="start_drag" />
                    <a href="#" @click.prevent="$emit('accept', crop_params); reset();">
                        <button><check-icon size="2x" class="accept-icon" /></button>
                    </a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { CheckIcon } from '@zhuowenli/vue-feather-icons';

export default {
    components: {
        CheckIcon
    },
    props: {
        'editable': {
            type: Boolean
        },
        'initial': {
            type: String
        },
        'aspectRatio': {
            type: Number,
            default: 0.75,
        },
        'width': {
            type: Number,
        },
        'height': {
            type: Number,
        },
        'padding': {
            type: Number,
            default: 30
        },
    },

    data() {
        return {
            file: undefined,
            zoom: 1,
            img_pos: {
                x: 0,
                y: 0,
            },
            native_size: {
                width: 1,
                height: 1,
            },
            drag_start: undefined,
            drag_delta: {
                x: 0,
                y: 0,
            },
        };
    },

    computed: {
        has_initial() {
            return this.$props.initial !== undefined;
        },
        margin() {
            return this.padding;
        },
        aspect() {
            // default to 3:4
            return this.aspectRatio ? Number(this.aspectRatio) : 0.75;
        },
        cropped_size() {
            if (this.width === undefined && this.height === undefined) {
                // really not smart to not pass any sizes, but we'll just pick 200
                return {
                    width: 200,
                    height: 200 / this.aspect,
                };
            }
            if (this.width === undefined) {
                return {
                    width: Number(this.height) * this.aspect,
                    height: Number(this.height)
                };
            }
            if (this.height === undefined) {
                return {
                    width: Number(this.width),
                    height: Number(this.width) / this.aspect
                };
            }
            return {
                width: Number(this.width),
                height: Number(this.height)
            };
        },
        padded_size() {
            return {
                width: this.cropped_size.width + 2 * this.margin,
                height: this.cropped_size.height + 2 * this.margin
            };
        },
        scale_factor() {
            // necessary scale factor to have the scaled native image
            // fill out the crop area entirely
            const scale = Math.max(this.cropped_size.width / this.native_size.width,
                                   this.cropped_size.height / this.native_size.height);
            return scale * this.zoom;
        },
        scaled_size() {
            return {
                width: this.scale_factor * this.native_size.width,
                height: this.scale_factor * this.native_size.height,
            };
        },
        center_offset() {
            // in case pos = (0,0) (the initial position),
            // return offset, s.t. (pos - offset) centers the image in padded_size area
            return {
                x: 0.5 * (this.scaled_size.width - this.padded_size.width),
                y: 0.5 * (this.scaled_size.height - this.padded_size.height),
            }
        },
        wiggle_room() {
            // determine the range [-w,w] such that (pos + w) does not move the image
            // outside of the padded_size area
            return {
                width: this.margin + this.center_offset.x,
                height: this.margin + this.center_offset.y,
            }
        },
        current_position() {
            return {
                x: this.img_pos.x + this.drag_delta.x,
                y: this.img_pos.y + this.drag_delta.y,
            };
        },
        crop_params() {
            const range_x = this.wiggle_room.width;
            const range_y = this.wiggle_room.height;
            let pos = {
                x: Math.max(Math.min(this.current_position.x, range_x), -range_x),
                y: Math.max(Math.min(this.current_position.y, range_y), -range_y),
            }
            // current_position is actually a delta from (-center_offset)
            pos.x -= this.center_offset.x;
            pos.y -= this.center_offset.y;
            let x = -pos.x + this.margin;
            let y = -pos.y + this.margin;
            let w = this.cropped_size.width;
            let h = this.cropped_size.height;
            x /= this.scale_factor;
            y /= this.scale_factor;
            w /= this.scale_factor;
            h /= this.scale_factor;

            return {
                file: this.file,
                x,
                y,
                w,
                h,
            };
        },
        css_props() {
            // constrain current position to accepted bounds
            const range_x = this.wiggle_room.width;
            const range_y = this.wiggle_room.height;
            let pos = {
                x: Math.max(Math.min(this.current_position.x, range_x), -range_x),
                y: Math.max(Math.min(this.current_position.y, range_y), -range_y),
            }
            // current_position is actually a delta from (-center_offset)
            pos.x -= this.center_offset.x;
            pos.y -= this.center_offset.y;

            const vars = {
                '--image-width': this.cropped_size.width+'px',
                '--image-height': this.cropped_size.height+'px',
                '--border-width': this.margin+'px',
                '--padded-width': this.padded_size.width + 'px',
                '--padded-height': this.padded_size.height + 'px',
                '--scaled-width': this.scaled_size.width + 'px',
                '--scaled-height': this.scaled_size.height + 'px',
                '--scaled-left': pos.x + 'px',
                '--scaled-top': pos.y + 'px',
            };
            return vars;
        },
        loaded() {
            return this.native_size !== undefined;
        },
        dragging() {
            return this.drag_start !== undefined;
        },
    },

    methods: {
        trigger_upload() {
            this.$refs.upload.click();
        },
        select_image(ev) {
            this.file = this.$refs.upload.files[0];
            if (this.file === undefined) {
                return;
            }
            if (!this.file.type.match(/image.*/)) {
                console.error("Non-image file selected");
                this.file = undefined;
                return;
            }

            const reader = new FileReader();
            reader.onload = (evt) => {
                let preload = new Image();
                preload.onload = () => {
                    this.$data.native_size = {
                        width: preload.width,
                        height: preload.height,
                    };
                    this.$refs.orig_img.src = preload.src;
                }
                preload.src = evt.target.result;
            }
            reader.readAsDataURL(this.file);

            // this.$store.dispatch('upload', { avatar: this.file });
        },
        reset() {
            this.file = undefined;
            this.zoom = 1;
            this.img_pos = {
                x: 0,
                y: 0,
            };
            this.native_size = {
                width: 1,
                height: 1,
            };
            this.drag_start = undefined;
            this.drag_delta = {
                x: 0,
                y: 0,
            };
        },
        start_drag(evt) {
            if (evt.which === 1) {
                this.drag_start = {
                    x: evt.pageX,
                    y: evt.pageY,
                }
                evt.preventDefault();
            }
        },
        move_mouse(evt) {
            // this event gets called on all mouse move events,
            // so ignore unless we started dragging the image
            if (this.dragging) {
                this.drag_delta = {
                    x: evt.pageX - this.drag_start.x,
                    y: evt.pageY - this.drag_start.y,
                }
                evt.preventDefault();
            }
        },
        stop_drag(evt) {
            // this event gets called on all mouse move events,
            // so ignore unless we started dragging the image
            if (this.dragging) {
                this.img_pos.x += this.drag_delta.x;
                this.img_pos.y += this.drag_delta.y;
                this.drag_start = undefined;
                this.drag_delta = {
                    x: 0,
                    y: 0,
                };
                evt.preventDefault();
            }
        },
    },

    created() {
        window.addEventListener('mousemove', this.move_mouse);
        window.addEventListener('mouseup', this.stop_drag);
    },

    destroyed: function() {
        window.removeEventListener('mousemove', this.move_mouse);
        window.removeEventListener('mouseup', this.stop_drag);
    }
}
</script>

<style scoped>
.initial-image {
    width: var(--image-width);
    /* height: var(--image-height); */
}
.initial-placeholder {
    width: var(--image-width);
    min-width: var(--image-width);
    height: var(--image-height);
    min-height: var(--image-height);
    /* border: 1px solid var(--uni-blue); */
}
.orig-img {
    position: absolute;
    left: var(--scaled-left);
    top: var(--scaled-top);
    width: var(--scaled-width);
    height: var(--scaled-height);
    cursor: not-allowed;
}
.preview {
    /* do not change these */
    box-sizing: content-box;
    position: relative;
    overflow: hidden;

    /* styling */
    border: 1px solid #c8c8c8;
    border-color: #aaa;      /* bootstrap state-success */
    border-radius: 12px;
    background: url('data:image/gif;base64,R0lGODlhEAAQAKEAAISChPz+/P///wAAACH5BAEAAAIALAAAAAAQABAAAAIfhG+hq4jM3IFLJhoswNly/XkcBpIiVaInlLJr9FZWAQA7');
    margin-bottom: 1em;

    width: var(--padded-width);
    height: var(--padded-height);
}

/* .preview.sjaakp-state-loaded { */
/*     border-color: #3c763d;   */
/* } */

.preview.sjaakp-state-nocrop {
    background: #e4e4e4;
}

.sjaakp-state-nocrop .sjaakp-img    {
    /* do not change these */
    max-width: 90%;
    max-height: 90%;
    right: 0;
    bottom: 0;
    margin: auto;
}

.overlay {
    /* do not change these */
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    border: solid rgba(255, 255, 255, .75);
    cursor: move;
    border-width: var(--border-width);
}

.cropper button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: var(--light-green);
    position: absolute;
    right: 10px;
    bottom: 10px;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 0.3);
}

.accept-icon {
    color: white;
    stroke-width: 5px;
    width: 20px;
    height: 20px;
}


/* .sjaakp-slider    { */
/*     margin-bottom: 1em; */
/* } */
/*  */
/* .sjaakp-spos-left .preview, */
/* .sjaakp-spos-left .sjaakp-slider, */
/* .sjaakp-spos-right .preview, */
/* .sjaakp-spos-right .sjaakp-slider   { */
/*     display: inline-block; */
/*     margin-right: 1em; */
/* } */
</style>
