<template>
    <div class="separator-segment" />
    <div class="content-segment">
        <div class="container">
            <h1>BTFDBB: BTF Database Bonn and Measurement Lab</h1>

            <h2>Old Lab Setup</h2>

            <ul>
                <li>14 MP digital camera (Kodak DCS Pro 14n SLR)</li>
                <li>computer-controlled robot (Intellitek Scorbot ER-4u) with sample</li>
                <li>570W HMI lamp</li>
            </ul>

            <img class="old-lab-image" src="https://cg.cs.uni-bonn.de/btf/fileadmin/uploads/pics/background_oldlab.jpg" />

            <h2>DOME Setup</h2>

            <p>
                The camera Dome is a prototypical setup for the rapid synchronous acquisition of BTF materials. The hardware consists of 151 digital consumer cameras mounted on a rack and is completely computer-controlled.
            </p>
            <p>
                The functionality of this setup is demonstrated in the following video, showing the example acquisition of a 3D object with BTF:
            </p>
            <p>
                <a href="https://cg.cs.uni-bonn.de/btf/fileadmin/Downloads/Videos/Multiview%20Dome%203D%20Object%20Acquisition.mpg">Multiview Dome 3D Object Acquisition.mpg</a>
            </p>
            <p>
                Originally, the Dome was equipped with 151 Canon PowerShot A75 cameras, evenly distributed over the hemisphere with an angular spacing of 15 degrees. As of mid 2009 these have been completely replaced by the better Canon PowerShot G9 model, exhibiting a spatial resolution of 12 Megapixels.
            </p>
            <p>
                The cameras are remotely controlled via USB2.0 and are connected to a total of 8 control-computers, facilitating the direct transfer of the cameras' data to the computer's hardrives in a matter of seconds.
            </p>
            <p>
                The main reasoning behind this setup is the simultaneous acquisition of 151 different view directions, improving capture times considerably with respect to the old measurement device. The cameras are also used as light sources, illuminating the material-sample with their internal flashes. Thus, a bidirectional angular sampling of 151 x 151 is achieved.
            </p>
            <p>
                From August 2011 on, the dome is also equipped with 9 additional LG HS200G projectors, allowing for the automated acquisition of whole 3D objects.
            </p>

            <div class="dome-images">
                <div><img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/b_d609799c3c.jpg" />Dome setup from outside.</div>
                <div><img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/b_dc9aadc8ac.jpg" />One side of the camera-hemisphere with<br />sample holder (bottom).</div>
                <div><img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/b_8eca03d703.jpg" />Camera-hemisphere as seen through the<br />empty sample holder.</div>
                <div><img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/b_d11ca9790d.jpg" />Close up of the cameras installed on the<br />gantry.</div>
            </div>

            <h2>UBO2003 Datasets</h2>

            <div class="dataset-description">
                <p>
                    Here you can download the BTF data captured in 2003/2005 using the measurement device from our old lab.
                </p>
                <p>
                    This data first appeared in<br/><i>Mirko Sattler, Ralf Sarlette and Reinhard Klein &quot;<a href="https://web.archive.org/web/20210416104548/http://cg.cs.uni-bonn.de/de/publikationen/paper-details/sattler-2003-efficient/">Efficient and Realistic Visualization of Cloth</a>&quot;, </i>EGSR 2003<i>.</i><br/>Please cite this work when using the datasets.
                </p>
                <!-- <p><b>General information:</b><br/>you can access the data either using your webbrowser and download directly from this page:<br/><a href="https://web.archive.org/web/20210416104548/http://cg.cs.uni-bonn.de/btf" class="external-link-new-window"><img src="/web/20210416104548im_/https://cg.cs.uni-bonn.de/fileadmin/img/icons/external_link_new_window.gif" alt="extern"/>http://cg.cs.uni-bonn.de/btf</a>. -->
                <!-- </p> -->
                <p><b>Image name composition:</b><br/>Samplename_POSNr_theta(light)_phi(light)_theta(view)_phi(view).jpg
                </p>
                <p><b>Example:</b><br/>WoolPKNT256_00084_tl015_pl000_tv015_pv120.jpg
                </p>
                <p>
                    <i>Note:</i> to reduce the amount of data all former BMP images were converted to JPG (least compression, highest quality)
                </p>
            </div>
            <div class="sample-list">
                <h3>CORDUROY sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/c_3c88513f8e.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/c_779e0843f1.jpg" />
                    <div class="sample-description">
                        <p>The CORDUROY sample consists of brown corduroy.</p>

                        <p>22.01mm x 22.01mm, 6561 images, 256x256 resolution, 81 view and 81 light directions.</p>

                        <p>Download ONE File (332 MB): <a href="https://cg.cs.uni-bonn.de/btf/UBO2003/CORDUROY/ONEFILE/UBO_CORDUROY256.zip">UBO_CORDUROY256.zip</a></p>
                        <!-- Browse all images: ￼CORDUROY browser -->
                    </div>
                </div>

                <h3>IMPALLA sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/i_5a532c596d.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/i_1f92c2b7c6.jpg" />
                    <div class="sample-description">
                        <p>The IMPALLA sample consists of graved granite stone.</p>

                        <p>80mm x 80mm, 6561 images, 256x256 resolution, 81 view and 81 light directions. </p>

                        <p>Download ONE File (320 MB): <a href="https://cg.cs.uni-bonn.de/btf/UBO2003/IMPALLA/ONEFILE/UBO_IMPALLA256.zip">UBO_IMPALLA256.zip</a></p>
                        <!-- Browse all images: ￼IMPALLA browser -->
                    </div>
                </div>

                <h3>PROPOSTE sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_9a6c82081f.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_945ab76a8f.jpg" />
                    <div class="sample-description">
                        <p>The PROPOSTE sample consists of white-yellow upholstery.</p>

                        <p>55mm x 55mm, 6561 images, 256x256 resolution, 81 view and 81 light directions. </p>

                        <p>Download ONE File (342 MB): <a href="https://cg.cs.uni-bonn.de/btf/UBO2003/PROPOSTE/ONEFILE/UBO_PROPOSTE256.zip">UBO_PROPOSTE256.zip</a></p>
                        <!-- Browse all images: ￼PROPOSTE browser -->
                    </div>
                </div>

                <h3>PULLI sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_7a0a7a059b.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_ebd788f539.png" />
                    <div class="sample-description">
                        <p>The PULLI sample consists of brown woven 100% polyacryl.</p>

                        <p>11.8mm x 11.8mm, 6561 images, 256x256 resolution, 81 view and 81 light directions. </p>

                        <p>Download ONE File (294 MB): <a href="https://cg.cs.uni-bonn.de/btf/UBO2003/PULLI/ONEFILE/UBO_PULLI256.zip">UBO_PULLI256.zip</a></p>
                        <!-- Browse all images: ￼PULLI browser -->
                    </div>
                </div>

                <h3>WALLPAPER sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/w_c1a67b5d71.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/w_76d26a7890.jpg" />
                    <div class="sample-description">
                        <p>The WALLPAPER sample consists of red-yellow-copper wallpaper.</p>

                        <p>70mm x 70mm, 6561 images, 256x256 resolution, 81 view and 81 light directions. </p>

                        <p>Download ONE File (360 MB): <a href="https://cg.cs.uni-bonn.de/btf/UBO2003/WALLPAPER/ONEFILE/UBO_WALLPAPER256.zip">UBO_WALLPAPER256.zip</a></p>
                        <!-- Browse all images: ￼WALLPAPER browser -->
                    </div>
                </div>

                <h3>WOOL sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/w_6bad16f6b7.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/w_dfd0ce7b81.jpg" />
                    <div class="sample-description">
                        <p>The WOOL sample consists of blue wool.</p>

                        <p>60mm x 60mm, 6561 images, 256x256 resolution, 81 view and 81 light directions. </p>

                        <p>Download ONE File (353 MB): <a href="https://cg.cs.uni-bonn.de/btf/UBO2003/WOOL/ONEFILE/UBO_WOOL256.zip">UBO_WOOL256.zip</a></p>
                        <!-- Browse all images: ￼WOOL browser -->
                    </div>
                </div>
            </div>

            <h2>ATRIUM Datasets</h2>
            <div class="dataset-description">
                <div class="with-image-on-the-right">
                    <div>
                        <p>
                            This section contains materials from the picture on the right.<br />
                            See <a href="http://www.mpi-inf.mpg.de/resources/atrium/glass.html">http://www.mpi-inf.mpg.de/resources/atrium/glass.html</a> for details.
                        </p>
                        <p>
                            The BTF data was captured in 2005 using the measurement device from our old lab.
                        </p>
                        <p>
                            <b>Image name composition:</b><br />
                            RunningNo_tl(theta light)_pl(phi light)_tv(theta view)_pv(phi view).ext
                        </p>
                        <p>
                            <b>Example:</b><br />
                            00175 tl015 pl060 tv030 pv180.jpg
                        </p>
                        <p>
                            <i>Note:</i> Datasets are also available in as high dynamic range data in the <a href="http://radsite.lbl.gov/radiance/">RADIANCE</a> Synthetic Imaging System image format.
                        </p>
                    </div>
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/atrium_overview.jpg" width="400" />
                </div>
            </div>
            <div class="sample-list">
                <h3>CEILING sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/c_85720be440.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/c_753ab9a628.png" />
                    <div class="sample-description">
                        <p>The CEILING sample consists of 6561 images, 800x800 resolution, 81 view and 81 light directions.</p>
                        <p>Download ONE File (2.16&nbsp;GB): <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/JPG/CEILING/CEILING.zip">CEILING.zip</a></p>
                        <p>Download ONE File HDR (988 MB): <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/HDR/CEILING_HDR.zip">CEILING_HDR.zip</a></p>
                        <!-- <p>Browse all images: <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/JPG/CEILING/">CEILING browser</a></p> -->
                    </div>
                </div>
                <h3>WALKWAY sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/w_bfb0faa0c3.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/w_5899d85632.png" />
                    <div class="sample-description">
                        <p>The WALKWAY sample consists of 6561 images, 800x800 resolution, 81 view and 81 light directions.</p>
                        <p>Download ONE File (1.86 GB): <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/JPG/WALKWAY/WALKWAY.zip">WALKWAY.zip</a></p>
                        <p>Download ONE File HDR (0.98 GB): <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/HDR/WALKWAY_HDR.zip">WALKWAY_HDR.zip</a></p>
                        <!-- <p>Browse all images: <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/JPG/WALKWAY/">WALKWAY browser</a></p> -->
                    </div>
                </div>
                <h3>FLOORTILE sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/f_e979d2f4df.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/f_f765d05a72.png" />
                    <div class="sample-description">
                        <p>The FLOORTILE sample consists of 6561 images, 800x800 resolution, 81 view and 81 light directions.</p>
                        <p>Download ONE File (2.57 GB): <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/JPG/FLOORTILE/FLOORTILE.zip">FLOORTILE.zip</a></p>
                        <p>Download ONE File HDR (1.05 GB): <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/HDR/FLOORTILE_HDR.zip">FLOORTILE_HDR.zip</a></p>
                        <!-- <p>Browse all images: <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/JPG/FLOORTILE">FLOORTILE browser</a></p> -->
                    </div>
                </div>
                <h3>PINKTILE sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_08c6da8f01.jpg" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_ede94ec180.png" />
                    <div class="sample-description">
                        <p>The PINKTILE sample consists of 6561 images, 800x800 resolution, 81 view and 81 light directions.</p>
                        <p>Download ONE File (1.54 GB): <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/JPG/PINKTILE/PINKTILE.zip">PINKTILE.zip</a></p>
                        <p>Download ONE File HDR (674 MB): <a href="https://cg.cs.uni-bonn.de/btf/ATRIUM/HDR/PINKTILE_HDR.zip">PINKTILE_HDR.zip</a></p>
                    </div>
                </div>
            </div>

            <h2>SPECTRAL Datasets</h2>
            <div class="dataset-description">
                <p>
                    Here you can download the spectral BTFs acquired during our project on efficient spectral BTF measurements. The data is freely usable for research purposes. Please cite our CGIV paper when using one of the datasets:
                </p>
                <p>
                    Martin Rump, Ralf Sarlette and Reinhard Klein: <i>"Groundtruth Data for Multispectral Bidirectional Texture Functions"</i>, CGIV 2010
                </p>
                <p>
                    <b>Documentation:</b><br />
                </p>
                <p>
                    The BTFs have been measured using our gonioreflectometer setup. Measurement took about 7 days per sample. 
                    The BTFs are given as a set of registered OpenEXR files. The files are multi-channel where the channel names correspond to the respective wavelength in nm. The file names encode the viewing and lighting angle and are of the following form:
                </p>
                <p>
                    <pre>
                        tv&lt;theta_view&gt;_pv&lt;phi_view&gt;_tl&lt;theta_light&gt;_pl&lt;phi_light&gt;.exr
                    </pre>
                </p>
                <p>
                    where the theta angles are measured from the normal (0,0,1) and phi angle 0° points towards (1,0,0). The angles are measured in degrees.
                </p>
                <p>
                    The BTFs have been cropped and downsampled to have 300x300 pixel resolution. If you want to access the higher resolution data (800x800) please contact Martin Rump.
                </p>
                <p>
                    <i>Known defects:</i>
                </p>
                <ul>
                    <li>
                        As only one exposure time per direction pair and spectral band was used, a few images (especially those showing the fresnel effects) suffer from over-exposure.
                    </li>
                    <li>
                        A few images might be not registered with subpixel accuracy since it is very hard to detect the white border around the target at the presence of strong fresnel reflection at shallow angles.
                    </li>
                </ul>
            </div>
            <div class="sample-list">
                <h3>Colorchecker sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_f583f8cdff.png" />
                    <div class="sample-description">
                        <p>This is a small hand-painted color checker. Besides "standard" paints in different colors also two fluorescent and two metallic paints were used.</p>
                        <p>Download all images as one huge zip file: <a href="https://cg.cs.uni-bonn.de/btf/SPECTRAL/Colorchecker.zip">Colorchecker.zip</a></p>
                    </div>
                </div>
                <h3>Red fabric sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_24f6308115.png" />
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_c1b2b2e85c.png" />
                    <div class="sample-description">
                        <p>This is a complex red fabric consisting of four different jarns: two kinds of red, one greenish and one being glossy silver.</p>
                        <p>Download all images as one huge zip file: <a href="https://cg.cs.uni-bonn.de/btf/SPECTRAL/RedFabric.zip">RedFabric.zip</a></p>
                    </div>
                </div>
                <h3>Wallpaper sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/p_da57bfc333.png" />
                    <div class="sample-description">
                        <p>A small piece of wallpaper showing lots of interesting effects due to the embossed structure.</p>
                        <p>Download all images as one huge zip file: <a href="https://cg.cs.uni-bonn.de/btf/SPECTRAL/Wallpaper.zip">Wallpaper.zip</a></p>
                    </div>
                </div>
                <h3>Lego Bricks</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/L_fdd62f1eb2.jpg" />
                    <div class="sample-description">
                        <p>Some colored Lego bricks including a transparent one showing a lot of parallax, shadowing and interreflections.</p>
                        <p>Download all images as one huge zip file: <a href="https://cg.cs.uni-bonn.de/btf/SPECTRAL/Lego.zip">Lego.zip</a></p>
                    </div>
                </div>
            </div>

            <h2>OBJECTS2011 Datasets</h2>
            <div class="dataset-description">
                <p>
                    Here you can download the BTF and structured light data of the four objects presented as examples in:
                </p>
                <p>
                    Christopher Schwartz, Michael Weinmann, Roland Ruiters and Reinhard Klein: <i>"Integrated High-Quality Acquisition of Geometry and Appearance for Cultural Heritage"</i>, VAST 2011
                </p>
                <p>
                    <b>Please acknowledge or cite this work</b> when using the datasets.<br />
                </p>
                <p>
                    All datasets are free to use, modify and re-distribute for research and scholarship purposes. Furthermore, it is allowed to publish images made using the models or the BTF data in a scholary article or book, as long as proper credit is given to the University of Bonn's BTF Database. The Buddha dataset represent an artifact with religious and cultural significance to hundreds of millions of people and improper use might affront them. Please respect this fact and try to avoid potentially offending usage.
Any usage of the datasets for commercial purposes is not allowed without our permission and in some cases the permission of third parties.
                </p>
                <p>
                    <b>Documentation:</b><br />
                </p>
                <p>
                    Documentation about filenames and formats can be found in the <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/README.txt">README.txt</a>.
                    Source code for loading the compressed BTF data into matlab can be downloaded here as a zip file: <a href="https://cg.cs.uni-bonn.de/btf/Downloads/ReadDFMFBTF.zip">ReadDFMFBTF.zip</a>
                </p>
            </div>
            <div class="sample-list">
                <h3>BUDDHA sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/B_7492065b68.png" />
                    <div class="sample-description">
                        <p>The BUDDHA sample consists of a wooden Buddha statue that is covered with red paint and gold leaf.</p>
                        <p>Download HDR appearance images (326GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Buddha/hdr/hdr-appearance.zip">hdr-appearance.zip</a></p>
                        <p>Download HDR geometry images (241GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Buddha/hdr/hdr-geometry.zip">hdr-geometry.zip</a></p>
                        <p>Download geometry (14MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Buddha/Buddha.obj.zip">Buddha.obj.zip</a></p>
                        <p>Download uncompressed BTF dataset (197GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Buddha/hdr/hdr-resampled.zip">hdr-resampled.zip</a></p>
                        <p>Download compressed BTF (816MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Buddha/Buddha.btf.zip">Buddha.btf.zip</a></p>
                        <p>Download calibration XML (1.8MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Buddha/Buddha.xml.zip">Buddha.xml.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Buddha/Buddha.txt">Buddha.txt</a></p>
                    </div>
                </div>
                <h3>DONKEY sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/D_dd656f6a68.png" />
                    <div class="sample-description">
                        <p>The DONKEY sample consists of a metal Donkey statue, presumably made of brass.</p>
                        <p>Download HDR appearance images (271GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Donkey/hdr/hdr-appearance.zip">hdr-appearance.zip</a></p>
                        <p>Download HDR geometry images (158GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Donkey/hdr/hdr-geometry.zip">hdr-geometry.zip</a></p>
                        <p>Download geometry (13.3MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Donkey/Donkey.obj.zip">Donkey.obj.zip</a></p>
                        <p>Download uncompressed BTF dataset (137GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Donkey/hdr/hdr-resampled.zip">hdr-resampled.zip</a></p>
                        <p>Download compressed BTF (600MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Donkey/Donkey.btf.zip">Donkey.btf.zip</a></p>
                        <p>Download calibration XML (1.8MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Donkey/Donkey.xml.zip">Donkey.xml.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Donkey/Donkey.txt">Donkey.txt</a></p>
                    </div>
                </div>
                <h3>MINOTAUR sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/M_61f81f48ac.png" />
                    <div class="sample-description">
                        <p>The MINOTAUR sample consists of a bronze Minotaur statue, covered with green paint and mounted on a marble block.</p>
                        <p>Download HDR appearance images (135GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Minotaur/hdr/hdr-appearance.zip">hdr-appearance.zip</a></p>
                        <p>Download HDR geometry images (78.5GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Minotaur/hdr/hdr-geometry.zip">hdr-geometry.zip</a></p>
                        <p>Download geometry (9.8MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Minotaur/Minotaur.obj.zip">Minotaur.obj.zip</a></p>
                        <p>Download uncompressed BTF dataset (178GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Minotaur/hdr/hdr-resampled.zip">hdr-resampled.zip</a></p>
                        <p>Download compressed BTF (797MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Minotaur/Minotaur.btf.zip">Minotaur.btf.zip</a></p>
                        <p>Download calibration XML (1.7MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Minotaur/Minotaur.xml.zip">Minotaur.xml.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Minotaur/Minotaur.txt">Minotaur.txt</a></p>
                    </div>
                </div>
                <h3>TERRACOTTA SOLDIER sample</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/S_9bb42040a2.png" />
                    <div class="sample-description">
                        <p>The TERRACOTTA SOLDIER sample consists of a replic statuette of a soldier of the terracotta army. Note: this dataset was formerly erroneously called SAMURAI. Some papers might still refer to this dataset by its old name.</p>
                        <p>Download HDR appearance images (134GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Samurai/hdr/hdr-appearance.zip">hdr-appearance.zip</a></p>
                        <p>Download HDR geometry images (139GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Samurai/hdr/hdr-geometry.zip">hdr-geometry.zip</a></p>
                        <p>Download geometry (21MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Samurai/Samurai.obj.zip">Samurai.obj.zip</a></p>
                        <p>Download uncompressed BTF dataset (165GB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Samurai/hdr/hdr-resampled.zip">hdr-resampled.zip</a></p>
                        <p>Download compressed BTF (714MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Samurai/Samurai.btf.zip">Samurai.btf.zip</a></p>
                        <p>Download calibration XML (1.8MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Samurai/Samurai.xml.zip">Samurai.xml.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2011/Samurai/Samurai.txt">Samurai.txt</a></p>
                    </div>
                </div>
            </div>

            <h2>OBJECTS2012 Datasets</h2>
            <div class="dataset-description">
                <p>
                    Here you can download the 3D geometry as triangle meshes and corresponding appearance as compressed BTF files for a number of objects that have been captured using the Dome setups of our department.
                </p>
                <p>
                    All datasets are free to use, modify and re-distribute for research and scholarship purposes. Furthermore, it is allowed to publish images made using the models or the BTF data in a scholary article or book, as long as proper credit is given to the University of Bonn's BTF Database. Some of the datasets (Ganesh, Ivory Figurine, Santa) represent artifacts with religious or cultural significance and improper use might affront certain groups of people. Please respect this fact and try to avoid potentially offending usage.
                    Any usage of the datasets for commercial purposes is not allowed without our permission and in some cases the permission of third parties.
                </p>
                <p>
                    The data presented here has been captured and processed using the methods described in
                </p>
                <p>
                    Christopher Schwartz, Michael Weinmann, Roland Ruiters and Reinhard Klein: <i>"Integrated High-Quality Acquisition of Geometry and Appearance for Cultural Heritage"</i>, VAST 2011
                </p>
                <p>
                    <b>Please acknowledge or cite this work</b> when using the datasets.<br />
                </p>
                <p>
                    In case you are using the food objects you may instead also refer to
                </p>
                <p>
                    Christopher Schwartz, Michael Weinmann, Roland Ruiters, Arno Zinke, Ralf Sarlette and Reinhard Klein: <i>"Capturing Shape and Reflectance of Food"</i>, SIGGRAPH Asia 2011 Sketches
                </p>
                <p>
                    You can find four more captured objects that in addition to the processed BTF and geometry include the measured raw image data and HDR combined input data in the OBJECTS2011 dataset section.
                </p>
                <p>
                    <b>Documentation:</b><br />
                </p>
                <p>
                    Documentation about filenames and formats can be found in the <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/README.txt">README.txt</a>.
                    Source code for loading the compressed BTF data into matlab can be downloaded here as a zip file: <a href="https://cg.cs.uni-bonn.de/btf/Downloads/ReadDFMFBTF.zip">ReadDFMFBTF.zip</a>
                </p>
            </div>
            <div class="sample-list">
                <h3>Almond Horn</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/A_096d4df60d.png" />
                    <div class="sample-description">
                        <p>The ALMOND HORN dataset is acquired from a piece of pastry, partially covered with chocolate.</p>
                        <p>Download geometry (1.3MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/AlmondHorn/AlmondHorn.obj.zip">AlmondHorn.obj.zip</a></p>
                        <p>Download compressed BTF (504MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/AlmondHorn/AlmondHorn.btf.zip">AlmondHorn.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/AlmondHorn/AlmondHorn.txt">AlmondHorn.txt</a></p>
                    </div>
                </div>
                <h3>Apple</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/A_1daccc9426.png" />
                    <div class="sample-description">
                        <p>The APPLE dataset is acquired from a slice of a fresh apple.</p>
                        <p>Download geometry (1MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Apple/Apple.obj.zip">Apple.obj.zip</a></p>
                        <p>Download compressed BTF (438MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Apple/Apple.btf.zip">Apple.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Apple/Apple.txt">Apple.txt</a></p>
                    </div>
                </div>
                <h3>Chess Piece</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/C_fb57f29538.png" />
                    <div class="sample-description">
                        <p>The CHESS PIECE dataset is acquired from a resin replica of a knight from a chess game.</p>
                        <p>Download geometry (9.6MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/ChessPiece/ChessPiece.obj.zip">ChessPiece.obj.zip</a></p>
                        <p>Download compressed BTF (169MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/ChessPiece/ChessPiece.btf.zip">ChessPiece.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/ChessPiece/ChessPiece.txt">ChessPiece.txt</a></p>
                    </div>
                </div>
                <h3>Crispy Roast Pork</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/C_ec8f1cf92d.png" />
                    <div class="sample-description">
                        <p>The CRISPY ROAST PORK dataset is acquired from a slice of a crispy roast pork.</p>
                        <p>Download geometry (1MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/CrispyRoastPork/CrispyRoastPork.obj.zip">CrispyRoastPork.obj.zip</a></p>
                        <p>Download compressed BTF (478MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/CrispyRoastPork/CrispyRoastPork.btf.zip">CrispyRoastPork.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/CrispyRoastPork/CrispyRoastPork.txt">CrispyRoastPork.txt</a></p>
                    </div>
                </div>
                <h3>Ganesh</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/G_b4bf40d7e8.png" />
                    <div class="sample-description">
                        <p>The GANESH dataset is acquired from a small Ganesh figurine carved out of a labradorite block.</p>
                        <p>Download geometry (2.5MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Ganesh/Ganesh.obj.zip">Ganesh.obj.zip</a></p>
                        <p>Download compressed BTF (196MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Ganesh/Ganesh.btf.zip">Ganesh.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Ganesh/Ganesh.txt">Ganesh.txt</a></p>
                    </div>
                </div>
                <h3>Ivory Figurine</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/I_59f7c30f97.png" />
                    <div class="sample-description">
                        <p>The IVORY FIGURINE dataset is acquired from a ivory figurine of Shao Lao.</p>
                        <p>Download geometry (11.7MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/IvoryFigurine/IvoryFigurine.obj.zip">IvoryFigurine.obj.zip</a></p>
                        <p>Download compressed BTF (382MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/IvoryFigurine/IvoryFigurine.btf.zip">IvoryFigurine.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/IvoryFigurine/IvoryFigurine.txt">IvoryFigurine.txt</a></p>
                    </div>
                </div>
                <h3>Moulage</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/M_d6cacc2628.png" />
                    <div class="sample-description">
                        <p>The MOULAGE dataset is acquired from a wax moulage of a hand showing the desease "Psoriasis". The original object is part of the medical moulage collection of the <a href="http://www.ukb.uni-bonn.de/quick2web/internet/internet.nsf/vwWebPagesByID/0AF84065CAB122E9C1257378003FD707">Department of Dermatology of the University Bonn</a></p>
                        <p>Download geometry (2.0MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Moulage/Moulage.obj.zip">Moulage.obj.zip</a></p>
                        <p>Download compressed BTF (192MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Moulage/Moulage.btf.zip">Moulage.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Moulage/Moulage.txt">Moulage.txt</a></p>
                    </div>
                </div>
                <h3>Moulage 2</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/m_e3f7ddf668.png" />
                    <div class="sample-description">
                        <p>The MOULAGE dataset is acquired from a wax moulage of a hand showing the desease "Psoriasis". The original object is part of the medical moulage collection of the <a href="http://www.ukb.uni-bonn.de/quick2web/internet/internet.nsf/vwWebPagesByID/0AF84065CAB122E9C1257378003FD707">Department of Dermatology of the University Bonn</a></p>
                        <p>Download geometry (2.0MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Moulage2/Moulage2.obj.zip">Moulage2.obj.zip</a></p>
                        <p>Download compressed BTF (192MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Moulage2/Moulage2.btf.zip">Moulage2.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Moulage2/Moulage2.txt">Moulage2.txt</a></p>
                    </div>
                </div>
                <h3>Pudding Pastry</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/P_bac0a95674.png" />
                    <div class="sample-description">
                        <p>The PUDDING PASTRY dataset is acquired from a piece of pastry.</p>
                        <p>Download geometry (1MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/PuddingPastry/PuddingPastry.obj.zip">PuddingPastry.obj.zip</a></p>
                        <p>Download compressed BTF (647MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/PuddingPastry/PuddingPastry.btf.zip">PuddingPastry.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/PuddingPastry/PuddingPastry.txt">PuddingPastry.txt</a></p>
                    </div>
                </div>
                <h3>Santa</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/S_d0474f2f1f.png" />
                    <div class="sample-description">
                        <p>The SANTA dataset is acquired from a terra cotta figurine depicting Santa Claus.</p>
                        <p>Download geometry (3.8MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Santa/Santa.obj.zip">Santa.obj.zip</a></p>
                        <p>Download compressed BTF (104MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Santa/Santa.btf.zip">Santa.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Santa/Santa.txt">Santa.txt</a></p>
                    </div>
                </div>
                <h3>Shoe</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/S_b1f5178082.png" />
                    <div class="sample-description">
                        <p>The SHOE dataset is acquired from a small childrens shoe.</p>
                        <p>Download geometry (2MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Shoe/Shoe.obj.zip">Shoe.obj.zip</a></p>
                        <p>Download compressed BTF (217MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Shoe/Shoe.btf.zip">Shoe.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Shoe/Shoe.txt">Shoe.txt</a></p>
                    </div>
                </div>
                <h3>Strawberry</h3>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/typo3temp/pics/S_92d0ba8499.png" />
                    <div class="sample-description">
                        <p>The STRAWBERRY dataset is acquired from a fresh strawberry.</p>
                        <p>Download geometry (1.2MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Strawberry/Strawberry.obj.zip">Strawberry.obj.zip</a></p>
                        <p>Download compressed BTF (459MB): <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Strawberry/Strawberry.btf.zip">Strawberry.btf.zip</a></p>
                        <p>Display object specific README: <a href="https://cg.cs.uni-bonn.de/btf/OBJECTS2012/Strawberry/Strawberry.txt">Strawberry.txt</a></p>
                    </div>
                </div>
            </div>



            <h2>UBO2014 Datasets</h2>
            <div class="dataset-description">
                <p>
                    Here you can download the material appearance as compressed BTF files for a number of material samples that have been captured using the Dome I setup of our department. Per material sample, a full BTF has been measured using the bi-directional sampling of 151 viewing directions and 151 lighting directions, where the cameras are uniformly distributed on a hemispherical gantry. For lighting, we use the flashes of the cameras.
                </p>
                <p>
                    Currently, our database consists of 7 semantic categories. Each of these 7 material categories contains measurements of 12 different material instances for being capable to represent the corresponding intra-class variances. Per material sample, a full BTF has been measured using the bi-directional sampling of 151 viewing directions and 151 lighting directions. Consequently, a total of 22,801 images have been acquired for material samples with a size of 5 cm x 5 cm with spatial resolution of 512 x 512 texels. The available BTFs contain the central 400 x 400 texels (corresponding to an area of approx. 3.9 cm x 3.9 cm).
                </p>
                <p>
                    We offer two types of BTFs: Decorrelated Full-Matrix-Factorization (DFMF)-BTFs and BTFs resampled with respect to the surface geometry which has been measured using structured light. This allows rendering realistic silhouettes and helps to reduce compression artifacts which are caused by a blurring of surface details. This blurring results from parallax effects at regions significantly protruding from the modeled reference surface.
                </p>
                <p>
                    Besides the BTF data, there are archives of synthetic renderings for each of the materials under various environmental illuminations. Per material there is an uncompressed tar archive containing folders for each of the five used environments. Inside these subfolders there are high dynamic range renderings in the OpenEXR format, whose filenames have the form: <b>img_phi_theta_distance_envrot.exr</b>
                </p>
                <p>
                    <b>Description of the parameters:</b><br />
                </p>
                <ul>
                    <li><b>phi</b> (azimuth angle) in {0.0°; 22.5°; 45.0°} and <b>theta</b> (inclination angle) in {-67.5°;-45.0°;-22.5°; 0.0°; 22.5°; 45.0°; 67.5°} represent the rotation of the material sample w.r.t. the camera</li>
                    <li><b>distance</b> represents the distance of the material sample to the camera</li>
                    <li><b>envrot</b> represents the rotation of the environments (used to increase the intra-class variances)</li>
                </ul>
                <p>
                    All datasets are free to use, modify and re-distribute for research and scholarship purposes. Furthermore, permission is granted to publish images made using the BTF data in a scholarly article or book, as long as proper credit is given to the University of Bonn's BTF Database. Any usage of the datasets for commercial purposes is not allowed without our permission and in some cases the permission of third parties. For more information about the databases, please refer to:
                </p>
                <p>
                    Michael Weinmann, Juergen Gall and Reinhard Klein. <i>"Material Classification based on Training Data Synthesized Using a BTF Database"</i>, accepted at ECCV 2014.
                </p>
                <p>
                    <b>Please cite this work</b> when using the datasets.<br />
                </p>
                <p>
                    The data is provided in a compressed binary format. Examplary code for reading and decoding these files is available on <a href="https://github.com/cgbonn/btflib">github</a>.
                </p>
            </div>
            <div class="sample-list">
                <h3>CARPET</h3>

                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet01_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet01_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet01_W400xH400_L151xV151.btf">carpet01.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet01_resampled_W400xH400_L151xV151.btf">carpet01_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet01_renderings.tar">carpet01_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet02_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet02_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet02_W400xH400_L151xV151.btf">carpet02.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet02_resampled_W400xH400_L151xV151.btf">carpet02_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet02_renderings.tar">carpet02_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet03_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet03_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet03_W400xH400_L151xV151.btf">carpet03.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet03_resampled_W400xH400_L151xV151.btf">carpet03_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet03_renderings.tar">carpet03_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet04_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet04_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet04_W400xH400_L151xV151.btf">carpet04.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet04_resampled_W400xH400_L151xV151.btf">carpet04_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet04_renderings.tar">carpet04_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet05_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet05_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet05_W400xH400_L151xV151.btf">carpet05.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet05_resampled_W400xH400_L151xV151.btf">carpet05_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet05_renderings.tar">carpet05_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet06_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet06_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet06_W400xH400_L151xV151.btf">carpet06.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet06_resampled_W400xH400_L151xV151.btf">carpet06_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet06_renderings.tar">carpet06_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet07_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet07_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet07_W400xH400_L151xV151.btf">carpet07.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet07_resampled_W400xH400_L151xV151.btf">carpet07_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet07_renderings.tar">carpet07_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet08_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet08_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet08_W400xH400_L151xV151.btf">carpet08.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet08_resampled_W400xH400_L151xV151.btf">carpet08_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet08_renderings.tar">carpet08_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet09_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet09_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet09_W400xH400_L151xV151.btf">carpet09.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet09_resampled_W400xH400_L151xV151.btf">carpet09_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet09_renderings.tar">carpet09_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet10_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet10_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet10_W400xH400_L151xV151.btf">carpet10.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet10_resampled_W400xH400_L151xV151.btf">carpet10_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet10_renderings.tar">carpet10_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet11_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet11_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet11_W400xH400_L151xV151.btf">carpet11.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet11_resampled_W400xH400_L151xV151.btf">carpet11_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet11_renderings.tar">carpet11_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/carpet12_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/carpet12_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet12_W400xH400_L151xV151.btf">carpet12.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet12_resampled_W400xH400_L151xV151.btf">carpet12_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/carpet/carpet12_renderings.tar">carpet12_renderings.tar</a></p>
                    </div>
                </div>

                <h3>FABRIC</h3>

                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric01_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric01_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric01_W400xH400_L151xV151.btf">fabric01.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric01_resampled_W400xH400_L151xV151.btf">fabric01_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric01_renderings.tar">fabric01_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric02_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric02_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric02_W400xH400_L151xV151.btf">fabric02.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric02_resampled_W400xH400_L151xV151.btf">fabric02_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric02_renderings.tar">fabric02_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric03_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric03_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric03_W400xH400_L151xV151.btf">fabric03.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric03_resampled_W400xH400_L151xV151.btf">fabric03_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric03_renderings.tar">fabric03_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric04_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric04_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric04_W400xH400_L151xV151.btf">fabric04.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric04_resampled_W400xH400_L151xV151.btf">fabric04_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric04_renderings.tar">fabric04_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric05_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric05_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric05_W400xH400_L151xV151.btf">fabric05.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric05_resampled_W400xH400_L151xV151.btf">fabric05_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric05_renderings.tar">fabric05_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric06_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric06_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric06_W400xH400_L151xV151.btf">fabric06.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric06_resampled_W400xH400_L151xV151.btf">fabric06_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric06_renderings.tar">fabric06_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric07_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric07_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric07_W400xH400_L151xV151.btf">fabric07.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric07_resampled_W400xH400_L151xV151.btf">fabric07_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric07_renderings.tar">fabric07_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric08_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric08_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric08_W400xH400_L151xV151.btf">fabric08.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric08_resampled_W400xH400_L151xV151.btf">fabric08_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric08_renderings.tar">fabric08_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric09_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric09_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric09_W400xH400_L151xV151.btf">fabric09.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric09_resampled_W400xH400_L151xV151.btf">fabric09_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric09_renderings.tar">fabric09_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric10_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric10_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric10_W400xH400_L151xV151.btf">fabric10.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric10_resampled_W400xH400_L151xV151.btf">fabric10_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric10_renderings.tar">fabric10_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric11_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric11_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric11_W400xH400_L151xV151.btf">fabric11.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric11_resampled_W400xH400_L151xV151.btf">fabric11_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric11_renderings.tar">fabric11_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/fabric12_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/fabric12_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric12_W400xH400_L151xV151.btf">fabric12.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric12_resampled_W400xH400_L151xV151.btf">fabric12_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/fabric/fabric12_renderings.tar">fabric12_renderings.tar</a></p>
                    </div>
                </div>

                <h3>FELT</h3>

                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt01_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt01_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt01_W400xH400_L151xV151.btf">felt01.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt01_resampled_W400xH400_L151xV151.btf">felt01_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt01_renderings.tar">felt01_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt02_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt02_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt02_W400xH400_L151xV151.btf">felt02.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt02_resampled_W400xH400_L151xV151.btf">felt02_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt02_renderings.tar">felt02_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt03_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt03_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt03_W400xH400_L151xV151.btf">felt03.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt03_resampled_W400xH400_L151xV151.btf">felt03_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt03_renderings.tar">felt03_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt04_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt04_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt04_W400xH400_L151xV151.btf">felt04.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt04_resampled_W400xH400_L151xV151.btf">felt04_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt04_renderings.tar">felt04_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt05_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt05_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt05_W400xH400_L151xV151.btf">felt05.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt05_resampled_W400xH400_L151xV151.btf">felt05_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt05_renderings.tar">felt05_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt06_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt06_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt06_W400xH400_L151xV151.btf">felt06.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt06_resampled_W400xH400_L151xV151.btf">felt06_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt06_renderings.tar">felt06_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt07_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt07_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt07_W400xH400_L151xV151.btf">felt07.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt07_resampled_W400xH400_L151xV151.btf">felt07_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt07_renderings.tar">felt07_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt08_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt08_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt08_W400xH400_L151xV151.btf">felt08.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt08_resampled_W400xH400_L151xV151.btf">felt08_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt08_renderings.tar">felt08_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt09_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt09_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt09_W400xH400_L151xV151.btf">felt09.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt09_resampled_W400xH400_L151xV151.btf">felt09_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt09_renderings.tar">felt09_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt10_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt10_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt10_W400xH400_L151xV151.btf">felt10.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt10_resampled_W400xH400_L151xV151.btf">felt10_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt10_renderings.tar">felt10_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt11_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt11_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt11_W400xH400_L151xV151.btf">felt11.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt11_resampled_W400xH400_L151xV151.btf">felt11_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt11_renderings.tar">felt11_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/felt12_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/felt12_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt12_W400xH400_L151xV151.btf">felt12.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt12_resampled_W400xH400_L151xV151.btf">felt12_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/felt/felt12_renderings.tar">felt12_renderings.tar</a></p>
                    </div>
                </div>

                <h3>LEATHER</h3>

                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather01_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather01_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather01_W400xH400_L151xV151.btf">leather01.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather01_resampled_W400xH400_L151xV151.btf">leather01_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather01_renderings.tar">leather01_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather02_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather02_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather02_W400xH400_L151xV151.btf">leather02.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather02_resampled_W400xH400_L151xV151.btf">leather02_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather02_renderings.tar">leather02_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather03_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather03_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather03_W400xH400_L151xV151.btf">leather03.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather03_resampled_W400xH400_L151xV151.btf">leather03_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather03_renderings.tar">leather03_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather04_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather04_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather04_W400xH400_L151xV151.btf">leather04.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather04_resampled_W400xH400_L151xV151.btf">leather04_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather04_renderings.tar">leather04_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather05_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather05_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather05_W400xH400_L151xV151.btf">leather05.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather05_resampled_W400xH400_L151xV151.btf">leather05_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather05_renderings.tar">leather05_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather06_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather06_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather06_W400xH400_L151xV151.btf">leather06.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather06_resampled_W400xH400_L151xV151.btf">leather06_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather06_renderings.tar">leather06_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather07_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather07_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather07_W400xH400_L151xV151.btf">leather07.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather07_resampled_W400xH400_L151xV151.btf">leather07_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather07_renderings.tar">leather07_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather08_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather08_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather08_W400xH400_L151xV151.btf">leather08.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather08_resampled_W400xH400_L151xV151.btf">leather08_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather08_renderings.tar">leather08_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather09_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather09_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather09_W400xH400_L151xV151.btf">leather09.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather09_resampled_W400xH400_L151xV151.btf">leather09_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather09_renderings.tar">leather09_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather10_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather10_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather10_W400xH400_L151xV151.btf">leather10.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather10_resampled_W400xH400_L151xV151.btf">leather10_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather10_renderings.tar">leather10_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather11_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather11_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather11_W400xH400_L151xV151.btf">leather11.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather11_resampled_W400xH400_L151xV151.btf">leather11_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather11_renderings.tar">leather11_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/leather12_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/leather12_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather12_W400xH400_L151xV151.btf">leather12.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather12_resampled_W400xH400_L151xV151.btf">leather12_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/leather/leather12_renderings.tar">leather12_renderings.tar</a></p>
                    </div>
                </div>

                <h3>STONE</h3>

                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone01_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone01_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone01_W400xH400_L151xV151.btf">stone01.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone01_resampled_W400xH400_L151xV151.btf">stone01_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone01_renderings.tar">stone01_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone02_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone02_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone02_W400xH400_L151xV151.btf">stone02.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone02_resampled_W400xH400_L151xV151.btf">stone02_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone02_renderings.tar">stone02_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone03_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone03_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone03_W400xH400_L151xV151.btf">stone03.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone03_resampled_W400xH400_L151xV151.btf">stone03_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone03_renderings.tar">stone03_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone04_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone04_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone04_W400xH400_L151xV151.btf">stone04.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone04_resampled_W400xH400_L151xV151.btf">stone04_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone04_renderings.tar">stone04_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone05_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone05_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone05_W400xH400_L151xV151.btf">stone05.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone05_resampled_W400xH400_L151xV151.btf">stone05_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone05_renderings.tar">stone05_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone06_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone06_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone06_W400xH400_L151xV151.btf">stone06.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone06_resampled_W400xH400_L151xV151.btf">stone06_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone06_renderings.tar">stone06_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone07_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone07_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone07_W400xH400_L151xV151.btf">stone07.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone07_resampled_W400xH400_L151xV151.btf">stone07_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone07_renderings.tar">stone07_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone08_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone08_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone08_W400xH400_L151xV151.btf">stone08.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone08_resampled_W400xH400_L151xV151.btf">stone08_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone08_renderings.tar">stone08_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone09_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone09_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone09_W400xH400_L151xV151.btf">stone09.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone09_resampled_W400xH400_L151xV151.btf">stone09_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone09_renderings.tar">stone09_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone10_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone10_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone10_W400xH400_L151xV151.btf">stone10.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone10_resampled_W400xH400_L151xV151.btf">stone10_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone10_renderings.tar">stone10_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone11_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone11_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone11_W400xH400_L151xV151.btf">stone11.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone11_resampled_W400xH400_L151xV151.btf">stone11_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone11_renderings.tar">stone11_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/stone12_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/stone12_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone12_W400xH400_L151xV151.btf">stone12.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone12_resampled_W400xH400_L151xV151.btf">stone12_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/stone/stone12_renderings.tar">stone12_renderings.tar</a></p>
                    </div>
                </div>

                <h3>WALLPAPER</h3>

                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper01_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper01_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper01_W400xH400_L151xV151.btf">wallpaper01.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper01_resampled_W400xH400_L151xV151.btf">wallpaper01_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper01_renderings.tar">wallpaper01_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper02_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper02_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper02_W400xH400_L151xV151.btf">wallpaper02.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper02_resampled_W400xH400_L151xV151.btf">wallpaper02_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper02_renderings.tar">wallpaper02_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper03_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper03_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper03_W400xH400_L151xV151.btf">wallpaper03.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper03_resampled_W400xH400_L151xV151.btf">wallpaper03_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper03_renderings.tar">wallpaper03_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper04_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper04_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper04_W400xH400_L151xV151.btf">wallpaper04.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper04_resampled_W400xH400_L151xV151.btf">wallpaper04_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper04_renderings.tar">wallpaper04_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper05_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper05_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper05_W400xH400_L151xV151.btf">wallpaper05.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper05_resampled_W400xH400_L151xV151.btf">wallpaper05_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper05_renderings.tar">wallpaper05_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper06_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper06_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper06_W400xH400_L151xV151.btf">wallpaper06.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper06_resampled_W400xH400_L151xV151.btf">wallpaper06_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper06_renderings.tar">wallpaper06_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper07_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper07_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper07_W400xH400_L151xV151.btf">wallpaper07.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper07_resampled_W400xH400_L151xV151.btf">wallpaper07_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper07_renderings.tar">wallpaper07_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper08_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper08_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper08_W400xH400_L151xV151.btf">wallpaper08.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper08_resampled_W400xH400_L151xV151.btf">wallpaper08_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper08_renderings.tar">wallpaper08_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper09_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper09_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper09_W400xH400_L151xV151.btf">wallpaper09.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper09_resampled_W400xH400_L151xV151.btf">wallpaper09_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper09_renderings.tar">wallpaper09_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper10_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper10_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper10_W400xH400_L151xV151.btf">wallpaper10.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper10_resampled_W400xH400_L151xV151.btf">wallpaper10_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper10_renderings.tar">wallpaper10_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper11_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper11_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper11_W400xH400_L151xV151.btf">wallpaper11.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper11_resampled_W400xH400_L151xV151.btf">wallpaper11_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper11_renderings.tar">wallpaper11_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wallpaper12_W400xH400_L151xV151_topview_L25_thumbnail.png" alt=""/>
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wallpaper12_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper12_W400xH400_L151xV151.btf">wallpaper12.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper12_resampled_W400xH400_L151xV151.btf">wallpaper12_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wallpaper/wallpaper12_renderings.tar">wallpaper12_renderings.tar</a></p>
                    </div>
                </div>

                <h3>WOOD</h3>

                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood01_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood01_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood01_W400xH400_L151xV151.btf">wood01.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood01_resampled_W400xH400_L151xV151.btf">wood01_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood01_renderings.tar">wood01_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood02_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood02_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood02_W400xH400_L151xV151.btf">wood02.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood02_resampled_W400xH400_L151xV151.btf">wood02_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood02_renderings.tar">wood02_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood03_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood03_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood03_W400xH400_L151xV151.btf">wood03.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood03_resampled_W400xH400_L151xV151.btf">wood03_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood03_renderings.tar">wood03_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood04_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood04_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood04_W400xH400_L151xV151.btf">wood04.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood04_resampled_W400xH400_L151xV151.btf">wood04_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood04_renderings.tar">wood04_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood05_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood05_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood05_W400xH400_L151xV151.btf">wood05.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood05_resampled_W400xH400_L151xV151.btf">wood05_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood05_renderings.tar">wood05_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood06_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood06_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood06_W400xH400_L151xV151.btf">wood06.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood06_resampled_W400xH400_L151xV151.btf">wood06_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood06_renderings.tar">wood06_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood07_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood07_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood07_W400xH400_L151xV151.btf">wood07.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood07_resampled_W400xH400_L151xV151.btf">wood07_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood07_renderings.tar">wood07_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood08_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood08_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood08_W400xH400_L151xV151.btf">wood08.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood08_resampled_W400xH400_L151xV151.btf">wood08_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood08_renderings.tar">wood08_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood09_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood09_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood09_W400xH400_L151xV151.btf">wood09.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood09_resampled_W400xH400_L151xV151.btf">wood09_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood09_renderings.tar">wood09_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood10_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood10_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood10_W400xH400_L151xV151.btf">wood10.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood10_resampled_W400xH400_L151xV151.btf">wood10_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood10_renderings.tar">wood10_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood11_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood11_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood11_W400xH400_L151xV151.btf">wood11.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood11_resampled_W400xH400_L151xV151.btf">wood11_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood11_renderings.tar">wood11_renderings.tar</a></p>
                    </div>
                </div>
                <div class="sample">
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/topviews/wood12_W400xH400_L151xV151_topview_L25_thumbnail.png" alt="" />
                    <img src="https://cg.cs.uni-bonn.de/btf/img/project_images/btfdb/ubo2014/renderings/wood12_W400xH400_L151xV151_rendering_cylinder_thumbnail.png" alt="" />
                    <div class="sample-description">
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood12_W400xH400_L151xV151.btf">wood12.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood12_resampled_W400xH400_L151xV151.btf">wood12_resampled.btf</a></p>
                        <p><a href="https://cg.cs.uni-bonn.de/btf/UBO2014/wood/wood12_renderings.tar">wood12_renderings.tar</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BTFDB',
}
</script>

<style scoped>

.dataset-description {
    margin-left: 2em;
}

.sample-list {
    margin-left: 2em;
}

.sample {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-bottom: 1em;
    align-items: flex-start;
}

h2 {
    margin-top: 2em;
}

.with-image-on-the-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dome-images {
    margin-top: 2em;
    margin-left: 2em;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
}

.old-lab-image {
    margin-left: 2em;
}

.dome-images img {
    display: block;
}
</style>
