<template>
    <div class="banner-segment">
        <div class="banner-shadow">
            <div class="container">
                <div>
                    <h2>Institute of Computer Science II</h2>
                    <h1>Visual Computing</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="content-segment">
        <div class="container">
            <news />
        </div>
    </div>
</template>

<script>
import News from '@/components/News';

export default {
    name: 'Home',
    components: {
        News,
    },
}
</script>

<style scoped>
</style>
