<template>
    <div class="publications">
        <h1>{{ $t('publications.publications') }}</h1>
        <PublicationList />

        <button v-if="can_edit" class="add" @click="add">
            <plus-icon size="1x" /> {{ $t('generic.add') }}
        </button>
    </div>
</template>

<script>
import { PlusIcon } from '@zhuowenli/vue-feather-icons';
import PublicationList from '@/components/PublicationList.vue';

export default {
    components: {
        PlusIcon,
        PublicationList,
    },

    props: [
    ],

    data: function() {
        return {
        }
    },

    computed: {
        lang: function() {
            return this.$store.state.lang;
        },
        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
    },

    methods: {
        async add() {
            const now = new Date();
            const year = now.getFullYear().toString();
            const month = now.getMonth().toString();
            let new_pub = {
                year,
                month,
                type: 'Article',
                hidden: 1,
            };
            const { pub_id } = await this.$store.dispatch('publications/add', new_pub);
            this.$router.push({ name: 'Publication', params: { id: pub_id, edit: true, } });
        },
    },


    async mounted() {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    margin-bottom: 2em;
}

button.add {
    margin-top: 5em;
    background-color: var(--uni-blue);
    padding: 10px;
    color: white;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

button.add:deep(svg) {
    margin-right: 0.3em;
}
</style>

