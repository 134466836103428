import { get, put, post } from '@/services/backend'

export const auth = {
    namespaced: true,

    state: {
        user: {},
        token: undefined,
    },
    getters: {
        token: (state) => {
            return state.token;
        },
        authenticated: (state) => {
            return state.user.user_id !== undefined;
        },
        can_edit: (state) => {
            return state.user.can_edit === 1;
        },
        can_see: (state) => {
            return state.user.can_see;
        },
        is_admin: (state) => {
            return state.user.is_admin === 1;
        },
        username: (state) => {
            return state.user.username;
        },
    },
    mutations: {
        init_token(state) {
            const token = window.localStorage.getItem('token');
            if (token) {
                state.token = token;
            }
        },
        set_token(state, token) {
            window.localStorage.setItem('token', token);
            state.token = token;
        },
        unset_token(state) {
            window.localStorage.removeItem('token');
            state.token = undefined;
        },
        set_user(state, user) {
            state.user = user;
        },
    },
    actions: {
        async init({ commit, state, dispatch }) {
            commit('init_token')
            if (state.token) {
                dispatch('check');
            }
        },
        async check({ commit, state, dispatch }) {
            const user = await get('/auth/authenticated', state.token);
            commit('set_user', user);
        },
        async login({ dispatch, commit }, login_data) {
            return new Promise((resolve, reject) => {
                post('/auth/login', login_data).then((user) => {
                    commit('set_token', user.token);
                    user.token = undefined;
                    dispatch('check');
                    dispatch('fetch_data', null, { root: true });
                    resolve();
                }).catch(e => reject(`Failed to login: ${e}`));
            });
        },
        async logout({ dispatch, commit, state }) {
            const token = window.localStorage.getItem('token');
            commit('unset_token');
            if (token !== undefined) {
                post('/auth/logout', {}, token).then(() => {
                    dispatch('check');
                    dispatch('fetch_data', null, { root: true });
                });
            }
        },
    },
};
