<template>
    <div class="courses">
        <h1>{{ $t('courses.courses') }}</h1>
        <div class="semester-selector-bar">
            <template v-for="semester of semesters">
                <span v-if="semester.key == active_semester" class="semester-selector">
                    {{ semester.year }} {{ semester.semester.toUpperCase() }}
                </span>
                <a href="#" v-else class="semester-selector" @click.prevent="active_semester = semester.key">
                    {{ semester.year }} {{ semester.semester.toUpperCase() }}
                </a>
            </template>
        </div>
        <template v-if="active_semester && courses[active_semester][0].length">
            <h2 class="curr-header">Bachelor</h2>
            <div v-for="course of courses[active_semester][0]" class="course">
                <a :class="{ 'course-link': true, hidden: course.hidden}" href="#" @click.prevent="$router.push(`/course/${course.course_id}`)">
                    {{ $t('courses.' + course.type) }}:
                    {{ lang === 'de' ? (course.title_de || course.title_en) : (course.title_en || course.title_de) }}
                </a>
            </div>
        </template>
        <template v-if="active_semester && courses[active_semester][1].length">
            <h2 class="curr-header">Master</h2>
            <div v-for="course of courses[active_semester][1]" class="course">
                <a :class="{ 'course-link': true, hidden: course.hidden}" href="#" @click.prevent="$router.push(`/course/${course.course_id}`)">
                    {{ $t('courses.' + course.type) }}:
                    {{ lang === 'de' ? (course.title_de || course.title_en) : (course.title_en || course.title_de) }}
                </a>
            </div>
        </template>
        <template v-if="active_semester && courses[active_semester][2].length">
            <h2 class="curr-header">B-IT Master Life Science Informatics</h2>
            <div v-for="course of courses[active_semester][2]" class="course">
                <a :class="{ 'course-link': true, hidden: course.hidden}" href="#" @click.prevent="$router.push(`/course/${course.course_id}`)">
                    {{ $t('courses.' + course.type) }}:
                    {{ lang === 'de' ? (course.title_de || course.title_en) : (course.title_en || course.title_de) }}
                </a>
            </div>
        </template>
        <template v-if="active_semester && courses[active_semester][3].length">
            <h2 class="curr-header">B-IT Master Media Informatics</h2>
            <div v-for="course of courses[active_semester][3]" class="course">
                <a :class="{ 'course-link': true, hidden: course.hidden}" href="#" @click.prevent="$router.push(`/course/${course.course_id}`)">
                    {{ $t('courses.' + course.type) }}:
                    {{ lang === 'de' ? (course.title_de || course.title_en) : (course.title_en || course.title_de) }}
                </a>
            </div>
        </template>
        <template v-if="active_semester && courses[active_semester][4].length">
            <h2 class="curr-header">Diploma</h2>
            <div v-for="course of courses[active_semester][4]" class="course">
                <a :class="{ 'course-link': true, hidden: course.hidden}" href="#" @click.prevent="$router.push(`/course/${course.course_id}`)">
                    {{ $t('courses.' + course.type) }}:
                    {{ lang === 'de' ? (course.title_de || course.title_en) : (course.title_en || course.title_de) }}
                </a>
            </div>
        </template>
        <!-- <div v-for="year of publication_years" class="publication-year"> -->
        <!--     <h3 v-if="!collapsed">{{ year }}</h3> -->
        <!--     <div v-if="!collapsed || (year &#38;&#38; year == active_year)" class="publication-list"> -->
        <!--         <template v-for="pub of publications[year]"> -->
        <!--             <a :href="`/publication/${pub.pub_id}`"> -->
        <!--                 <div class="teaser"> -->
        <!--                     <img class="teaser" v-if="pub.teaser" :src="$store.getters['publications/teaser'](pub)" /> -->
        <!--                 </div> -->
        <!--             </a> -->
        <!--             <div class="publication-data"> -->
        <!--                 <div class="publication-meta"> -->
        <!--                     <b>{{ pub.title }}</b><br /> -->
        <!--                     <div v-if="editor_lines[pub.pub_id]" v-html="editor_lines[pub.pub_id]" /> -->
        <!--                     <div v-if="author_lines[pub.pub_id]" v-html="author_lines[pub.pub_id]" /> -->
        <!--                     <i>{{ pubmeta(pub) }}</i> -->
        <!--                 </div> -->
        <!--                 <div class="publication-links"> -->
        <!--                     <a class="publication-link" :href="`/publication/${pub.pub_id}`"> -->
        <!--                         <b>[Details]</b> -->
        <!--                     </a> -->
        <!--                     <a class="publication-link" v-if="pub.pdf" :href="$store.getters['publications/paper'](pub)"> -->
        <!--                         <b>[PDF]</b> -->
        <!--                     </a> -->
        <!--                 </div> -->
        <!--             </div> -->
        <!--         </template> -->
        <!--     </div> -->
        <!-- </div> -->
        <button v-if="can_edit" class="add" @click="add">
            <plus-icon size="1x" /> {{ $t('generic.add') }}
        </button>
    </div>
</template>

<script>
import { PlusIcon } from '@zhuowenli/vue-feather-icons';

export default {
    components: {
        PlusIcon,
    },

    props: [
    ],

    data: function() {
        return {
            courses: {},
            semesters: [],
            active_semester: undefined,
        }
    },

    computed: {
        lang: function() {
            return this.$store.state.lang;
        },
        edit: function() {
            return this.can_edit && this.edit_mode;
        },
        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
        // status: function() {
        //     if (this.saved) return "Saved.";
        //     if (this.saving) return "Saving...";
        //     if (this.editing) return "Editing...";
        //     return undefined;
        // },
    },

    methods: {
        async add() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth();
            let semester = 'ss';
            if (month >= 5 && month <= 10) {
                // from June till November assume winter semester for new courses
                semester = 'ws';
            }
            let new_course = {
                year,
                semester,
                curriculum: 'bachelor',
                type: 'lecture',
                hidden: 1,
            };
            // if (this.$store.state.lang == 'de') {
            //     new_course.title_de = 'Neue Lehrveranstaltung';
            // } else {
            //     new_course.title_en = 'New Course';
            // }
            const { course_id } = await this.$store.dispatch('courses/add', new_course);
            this.$router.push({ name: 'Course', params: { id: course_id, edit: true, } });
        },
    },


    async mounted() {
        await this.$store.dispatch('courses/fetch');
        const curr_map = {
            'bachelor': 0,
            'master': 1,
            'bit_master_lsi': 2,
            'bit_master_mi': 3,
            'diploma': 4,
        };
        const keys = new Set();
        for (const course_idx in this.$store.state.courses.courses) {
            const course = this.$store.state.courses.courses[course_idx];
            let key = parseInt(course.year) * 10;
            if (course.semester === 'ws') {
                key += 5;
            }
            if (this.courses[key] === undefined) {
                this.courses[key] = [[], [], [], [], []];
            }
            for (const curriculum of course.curriculum.split(/\s*[,]\s*/)) {
                this.courses[key][curr_map[curriculum]].push(course);
            }
            if (!keys.has(key)) {
                this.semesters.push({
                    year: course.year,
                    semester: course.semester,
                    key,
                });
                keys.add(key);
            }
        }

        this.semesters.sort((a,b) => b.key - a.key);
        this.active_semester = this.semesters[0].key;
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.teaser {
    height: 120px;
    width: 160px;
    min-height: 100px;
    border: 1px solid #bbb;
}

img.teaser {
    height: 120px;
    width: 160px;
    /* height: 100%; */
}

div.publication-year {
    padding-left: 20px;
}

div.publication-list {
    display: grid;
    grid-template-columns: 150px auto;
    grid-gap: 1.5em;
    padding: 1em 1em;
}

div.publication-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
}

a.publication-link {
    margin-right: 10px;
}

.semester-selector {
    margin-left: 20px;
    font-weight: 600;
}

span.semester-selector {
    color: var(--uni-yellow);
}

.semester-selector-bar {
    margin: 2em 0 2em 0;
}

.course {
    margin-left: 2em;
}

.course-link {
    font-size: 1.1em;
}

div.course {
    margin-bottom: 0.5em;
}

.curr-header {
    margin-top: 2em;
}

button.add {
    margin-top: 5em;
    background-color: var(--uni-blue);
    padding: 10px;
    color: white;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

button.add:deep(svg) {
    margin-right: 0.3em;
}

a.hidden {
    color: var(--uni-grey);
}
</style>

