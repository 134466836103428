import { get, put, post, del, upload, file_url } from '@/services/backend'

export const courses = {
    namespaced: true,

    state: {
        courses: {},
        has_materials: {},
    },
    getters: {
        course: (state, getters, rootState, rootGetters) => (id) => {
            return state.courses[parseInt(id)];
        },
        courses: (state, getters, rootState, rootGetters) => {
            return state.courses;
        },
        material_thumbnail: (state) => (material) => {
            if (!material.thumbs.length) {
                return '';
            }
            return `${file_url}/${material.thumbs[0].path}`;
        },
        material_download: (state) => (material) => {
            return `${file_url}/${material.path}`;
        },
    },
    mutations: {
        set(state, courses) {
            console.log('setting');
            console.log(courses);
            for (const course of courses) {
                state.courses[parseInt(course.course_id)] = course;
            }
        },
        set_materials(state, {course_id, materials}) {
            if (materials === undefined) return;
            console.log(state.courses);
            console.log(course_id);
            state.courses[course_id].materials = materials.materials;
            state.courses[course_id].has_materials = materials.has_materials;
        },
        add_material(state, {course_id, material}) {
            if (state.courses[course_id].materials === undefined) {
                state.courses[course_id].materials = [];
            }
            state.courses[course_id].materials.push(material);
        },
        set_by_id(state, { id, course }) {
            state.courses[id] = course;
        },
        remove_by_id(state, id) {
            if (id in state.courses) {
                delete state.courses[id];
            }
        },
    },
    actions: {
        async fetch({ commit, state, rootGetters }) {
            const token = rootGetters['auth/token'];
            const courses = await get('/courses', token);
            commit('set', courses.courses);
        },
        async update({ commit, state, rootGetters }, data) {
            const token = rootGetters['auth/token'];
            const res = await put(`/courses/${data.course.course_id}`, data, token);
            commit('set_by_id', { id: parseInt(res.course.course_id),
                                  course: res.course });
            return res.course;
        },
        async remove({ commit, state, rootGetters }, id) {
            const token = rootGetters['auth/token'];
            await del(`/courses/${id}`, {}, token);
            commit('remove_by_id', parseInt(id));
        },
        async add({ commit, state, rootGetters }, course) {
            const token = rootGetters['auth/token'];
            const res = await post(`/courses`, course, token);
            commit('set_by_id', { id: parseInt(res.course_id),
                                  course: res.course });
            return res.course;
        },
        async fetch_materials({ commit, state, rootGetters }, course_id) {
            const token = rootGetters['auth/token'];
            const materials = await get(`/courses/materials/${course_id}`, token);
            commit('set_materials', { course_id, materials: materials });
        },
        async add_material({commit, state, rootGetters}, data) {
            const { file, course_id, material_type, name, belongs_to, progress_callback } = data;
            const token = rootGetters['auth/token'];
            const form_data = new FormData();
            form_data.append('file', file);
            form_data.append('course_id', course_id);
            form_data.append('type', 'course_material');
            form_data.append('name', name);
            form_data.append('material_type', material_type);
            form_data.append('belongs_to', belongs_to);
            form_data.append('hidden', 1);
            const material = await upload('/courses/materials', form_data, token, 'multipart/form-data', progress_callback);
            commit('add_material', { course_id, material });
            return material;
        },
        async update_material({commit, state, rootGetters, dispatch}, material) {
            const token = rootGetters['auth/token'];
            const res = await put(`/courses/materials/${material.material_id}`, material, token);
            await dispatch('fetch_materials', res.material.course_id);
            return res.material;
        },
        async remove_material({commit, state, rootGetters, dispatch}, material) {
            const token = rootGetters['auth/token'];
            const res = await del(`/courses/materials/${material.material_id}`, {}, token);
        },
    },
};
