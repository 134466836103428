import { get, put, post, del, api_url } from '@/services/backend'

export const users = {
    namespaced: true,

    state: {
        users: {},
        for_course: {},
    },
    getters: {
    },
    mutations: {
        set(state, users) {
            state.users = users;
        },
        set_for_course(state, { username, can_see }) {
            state.for_course[can_see] = { username };
        },
        unset_for_course(state, id) {
            delete state.for_course[id];
        },
    },
    actions: {
        async fetch({ commit, state, rootGetters }) {
            const token = rootGetters['auth/token'];
            const users = await get(`/users`, token);
            console.log(users);
            commit('set', users.users);
        },
        async update({ commit, state, rootGetters, dispatch }, user) {
            const token = rootGetters['auth/token'];
            await put(`/users`, user, token);
            // dispatch('users/fetch');
        },
        async fetch_for_course({ commit, state, rootGetters }, course_id) {
            const token = rootGetters['auth/token'];
            const user = await get(`/users/for-course/${course_id}`, token);
            if (user.user !== undefined) {
                commit('set_for_course', user.user);
            }
        },
        async set_for_course({ commit, state, rootGetters }, { course_id, user }) {
            const token = rootGetters['auth/token'];
            const res = await post(`/users/for-course/${course_id}`, user, token);
            commit('set_for_course', res.user);
            return res.user;
        },
        async unset_for_course({ commit, state, rootGetters }, course_id) {
            const token = rootGetters['auth/token'];
            await del(`/users/for-course/${course_id}`, {}, token);
            commit('unset_for_course', course_id);
        },
        // async update({ commit, state, rootGetters }, data) {
        //     const token = rootGetters['auth/token'];
        //     const res = await put(`/courses/${data.course.course_id}`, data, token);
        //     commit('set_by_id', { id: parseInt(res.course.course_id),
        //                           course: res.course });
        //     return res.course;
        // },
        // async remove({ commit, state, rootGetters }, id) {
        //     const token = rootGetters['auth/token'];
        //     await del(`/courses/${id}`, {}, token);
        //     commit('remove_by_id', parseInt(id));
        // },
        // async add({ commit, state, rootGetters }, course) {
        //     const token = rootGetters['auth/token'];
        //     const res = await post(`/courses`, course, token);
        //     commit('set_by_id', { id: parseInt(res.course_id),
        //                           course: res.course });
        //     return res.course;
        // },
    },
};
