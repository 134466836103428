<template>
    <div class="separator-segment" />
    <div class="content-segment">
        <div class="container">
            <PersonFull :id="$route.params.id" />
        </div>
    </div>
</template>

<script>
import PersonFull from '@/components/PersonFull.vue';

export default {
    name: 'Person',
    components: {
        PersonFull,
    },
    computed: {
    },
    mounted() {
    }
}
</script>

