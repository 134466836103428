<template>
    <div class="separator-segment" />
    <div class="content-segment">
        <div class="container">
            <PeopleGrid />
        </div>
    </div>
</template>

<script>
import PeopleGrid from '@/components/PeopleGrid.vue';

export default {
    name: 'People',
    components: {
        PeopleGrid,
    },
}
</script>
