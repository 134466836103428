<template>
    <div class="separator-segment" />
    <div class="content-segment">
        <div class="container">
            <PublicationsByYear />
        </div>
    </div>
</template>

<script>
import PublicationsByYear from '@/components/PublicationsByYear.vue';

export default {
    name: 'Publications',
    components: {
        PublicationsByYear,
    },
}
</script>
