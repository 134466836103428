<template>
    <div class="separator-segment" />
    <div class="content-segment">
        <div class="container">
            <Settings />
        </div>
    </div>
</template>

<script>
import Settings from '@/components/Settings.vue';

export default {
    components: {
        Settings,
    },
}
</script>
