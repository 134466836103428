import { get, put, post, del, file_url } from '@/services/backend'

export const persons = {
    namespaced: true,

    state: {
        persons: [],
        course_persons: {},
    },
    getters: {
        person: (state) => (id) => {
            return state.persons.find(persons => persons.person_id === parseInt(id));
        },
        for_course: (state) => (id) => {
            return state.course_persons[id];
        },
        avatar: (state) => (person) => {
            if (person.image === undefined) {
                return undefined;
            }
            return `${file_url}/${person.image}`;
        },
    },
    mutations: {
        set(state, persons) {
            state.persons = persons;
        },
        add(state, person) {
            state.persons.push(person);
        },
        set_for_course(state, {id, persons}) {
            state.course_persons[id] = persons;
            state.course_persons[id].sort((a, b) => {
                return a.rank - b.rank;
            });
        },
        update(state, data) {
            const id = parseInt(data.person_id);
            const idx = state.persons.findIndex(p => p.person_id === id);
            state.persons[idx] = data;
        },
    },
    actions: {
        async fetch({ commit, state, rootGetters }) {
            const token = rootGetters['auth/token'];
            const persons = await get('/persons', token);
            commit('set', persons.persons);
        },
        async fetch_for_course({ commit }, id) {
            const persons = await get(`/persons/for-course/${id}`);
            commit('set_for_course', {id, persons:persons.persons});
        },
        async add({commit, state, rootGetters}, person) {
            const token = rootGetters['auth/token'];
            let new_person = await post(`/persons`, person, token);
            commit('add', new_person.person);
            return new_person.person;
        },
        async update({commit, state, rootGetters}, person) {
            const token = rootGetters['auth/token'];
            const pid = person.person_id;
            const copy = { ...person };
            copy.publications = undefined;
            let updated = await put(`/persons/${pid}`, copy, token);
            updated.publications = person.publications;
            commit('update', updated);
            return updated.person;
        },
        async upload_avatar({commit, state, rootGetters}, data) {
            const token = rootGetters['auth/token'];
            const form_data = new FormData();
            for (const key in data) {
                form_data.append(key, data[key]);
            }
            const path = await post('/files/upload', form_data, token, 'multipart/form-data');
            return path;
        },
    },
};
