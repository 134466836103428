<template>
    <div v-if="person" class="person-full">
        <div :class="{ infobox: true, error: error_msg, saved: !error_msg && saved, editing: (!error_msg && !saved && (saving || editing)) }">{{ status || '&nbsp;' }}</div>

        <div v-if="edit" class="visible-toggle">
            <label for="hidden">
                <input type="checkbox"
                       id="hidden"
                       :true-value="1"
                       :faĺse-value="0"
                       v-model="person.hidden"
                       @change="changed()" />
                {{ $t('generic.hidden') }}
            </label>
        </div>
        <input v-if="edit"
               type="text"
               class="title"
               placeholder="Title"
               v-model="person.title"
               :size="person.title ? (person.title.length-2) : 10"
               @input="changed()">
        <input v-if="edit"
               type="text"
               class="fullname"
               placeholder="First Name"
               v-model="person.firstname"
               :size="person.firstname ? (person.firstname.length-2) : 10"
               @input="changed()">
        <input v-if="edit"
               type="text"
               class="fullname"
               placeholder="Last Name"
               v-model="person.lastname"
               :size="person.lastname ? (person.lastname.length-2) : 10"
               @input="changed()">
        <h2 v-if="!edit" class="title">{{ person.title }}</h2>
        <h1 v-if="!edit" class="fullname">{{ person.firstname || $t('people.new') }} {{ person.lastname || $t('people.person') }}</h1>
        <a v-if="can_edit" href="#" @click.prevent="edit_mode = !edit_mode">
            <edit-icon size="1.5x" />
        </a>
        <input v-if="edit && $store.state.lang === 'de'"
               type="text"
               class="role"
               placeholder="Rolle"
               v-model="person.role_de"
               :size="30"
               @input="changed()">
        <input v-if="edit && $store.state.lang === 'en'"
               type="text"
               class="role"
               placeholder="Rolle"
               v-model="person.role_en"
               :size="30"
               @input="changed()">
        <h3 v-if="!edit" class="role">{{ role }}</h3>
        <div v-if="person.person_id" class="card">
            <image-cropper :aspectRatio="0.83"
                           :width="150"
                           :editable="edit"
                           :initial="$store.getters['persons/avatar'](person)"
                           @accept="accept_crop"
            />
            <div class="card-info">
                <select v-if="edit" class="staff-type" v-model="person.type" @change="changed()">
                    <option value="Group Leaders">{{$t('people.groupleaders')}}</option>
                    <option value="Administrative Office">{{$t('people.adminoffice')}}</option>
                    <option value="Research Staff">{{$t('people.researchstaff')}}</option>
                    <option value="PhD Students">{{$t('people.phdstudents')}}</option>
                    <option value="Alumni">{{$t('people.alumni')}}</option>
                    <option value="Former Student Researchers">{{$t('people.formerstudentres')}}</option>
                </select>
                <div v-if="has_address" class="address">
                    <div>
                        <span class="address">{{ address.street }}</span>
                        <span class="address-right">{{ address.street_number }},</span>
                        Room
                        <span v-if="!edit">{{ address.room }}</span>
                        <input v-if="edit" type="text" class="room" placeholder="3.042" v-model="person.room" :size="person.room ? (person.room.length-2) : 10" @input="changed()" />
                    </div>
                    <div>
                        <span class="address">{{ address.zipcode }}</span>
                        <span class="address-right">{{ address.city }}</span>
                    </div>
                    <div>
                        <span class="address">{{ address.state }}</span>
                    </div>
                </div>
                <div class="person-contact">
                    <div>Phone:</div>
                    <div>
                        <span v-if="!edit" class="address">{{ person.tel }}</span>
                        <input v-if="edit" type="text" class="address" v-model="person.tel" :size="person.tel ? (person.tel.length-2) : 20" @input="changed()" />
                    </div>
                    <div>Fax:</div>
                    <div>
                        <span v-if="!edit" class="address">{{ person.fax }}</span>
                        <input v-if="edit" type="text" class="address" v-model="person.fax" :size="person.fax ? (person.fax.length-2) : 20" @input="changed()" />
                    </div>
                    <div>Email:</div>
                    <div>
                        <a v-if="!edit" href="mailto:{{ person.email }}">{{ person.email }}</a>
                        <input v-if="edit" type="text" class="address" v-model="person.email" :size="person.email ? (person.email.length-2) : 20" @input="changed()" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="edit" class="address-list">
            <list-entry v-for="(address, key, idx) of addresses"
                        :first="idx == 0"
                        :deletable="false"
                        :selectable="true"
                        :active="key == person.address_id"
                        @select="change_address(key)">
                <div class="address">
                    <div>
                        <span class="address">{{ address.street }}</span>
                        <span class="address-right">{{ address.street_number }},</span>
                    </div>
                    <div>
                        <span class="address">{{ address.zipcode }}</span>
                        <span class="address-right">{{ address.city }}</span>
                    </div>
                    <div>
                        <span class="address">{{ address.state }}</span>
                    </div>
                </div>
            </list-entry>
        </div>

        <textarea v-if="edit && $store.state.lang === 'de'" class="infotext" placeholder="Weitere Informationen" v-model="person.infotext_de" @input="changed()" />
        <textarea v-if="edit && $store.state.lang === 'en'" class="infotext" placeholder="Additional information" v-model="person.infotext_en" @input="changed()" />
        <div v-if="!edit" class="infotext" v-html="infotext" />

        <h2 v-if="has_pubs">{{ $t('publications.publications') }}</h2>
        <PublicationList :id="id" />

        <template v-if="has_diss">
            <h2 >{{ $t('people.supervised') }}</h2>
            <div class="diss-list">
                <template v-for="diss of dissertations">
                    <div class="diss-author" v-html="person_link(diss)" />
                    <div class="diss-topic">{{ diss.topic }}</div>
                    <div class="diss-year">{{ diss.year }}</div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import MarkdownIt from 'markdown-it';
import PublicationList from './PublicationList.vue';
import ImageCropper from './ImageCropper.vue';
import ListEntry from '@/components/ListEntry';
import { EditIcon } from '@zhuowenli/vue-feather-icons';

export default {
    components: {
        PublicationList,
        ListEntry,
        ImageCropper,
        EditIcon,
    },

    props: [
        'id',
    ],

    data: function() {
        return {
            person: {},
            original: {},
            addresses: {},
            dissertations: [],
            publicPath: process.env.BASE_URL,
            edit_mode: false,
            edit_timeout: undefined,
            saved_timeout: undefined,
            saved: false,
            editing: false,
            saving: false,
            error_msg: undefined,
        }
    },

    computed: {
        has_address() {
            return this.addresses[this.person.address_id] !== undefined;
        },
        address() {
            return this.addresses[this.person.address_id];
        },
        edit: function() {
            return this.$store.getters['auth/can_edit'] && this.edit_mode;
        },
        can_edit: function() {
            return this.$store.getters['auth/can_edit'];
        },
        status: function() {
            if (this.error_msg) return this.error_msg;
            if (this.saved) return "Saved.";
            if (this.saving) return "Saving...";
            if (this.editing) return "Editing...";
            return undefined;
        },
        infotext: function() {
            let text = this.person.infotext_de || this.person.infotext_en;
            if (this.$store.state.lang === 'en') {
                text = this.person.infotext_en || this.person.infotext_de;
            }
            return MarkdownIt().render(text || "");
        },
        has_pubs() {
            return this.$store.getters['publications/author_has_publications'](this.person.person_id);
        },
        has_diss() {
            return this.dissertations.length > 0;
        },
        role() {
            return this.$store.state.lang === 'de' ?
                (this.person.role_de || this.person.role_en) :
                (this.person.role_en || this.person.role_de);
        },
    },

    methods: {
        changed: function() {
            this.saved = false;
            this.saving = false;
            this.editing = true;
            this.error_msg = undefined;
            if (this.edit_timeout !== undefined) {
                clearTimeout(this.edit_timeout);
            }
            if (this.saved_timeout !== undefined) {
                clearTimeout(this.saved_timeout);
            }
            this.edit_timeout = setTimeout(() => {
                this.save();
                this.edit_timeout = undefined;
            }, 2000);
        },
        save: function() {
            this.editing = false;
            this.saving = true;
            this.$store.dispatch('persons/update', this.person).then((p) => {
                this.person = p;
                this.original = { ...this.person };
                this.saving = false;
                this.saved = true;
                this.saved_timeout = setTimeout(() => {
                    this.saved = false;
                }, 3000);
            }).catch((e) => {
                this.person = this.original;
                this.saving = false;
                this.saved = false;
                this.error_msg = e;
            });
        },
        async accept_crop({ file, x, y, w, h }) {
            const { path } = await this.$store.dispatch('persons/upload_avatar', {
                file,
                x,
                y,
                w,
                h,
                type: 'avatar',
                name: `${this.person.lastname.toLowerCase()}_${this.person.firstname.toLowerCase()}`,
            });
            this.person.image = path;
            this.changed();
        },
        change_address(id) {
            this.person.address_id = parseInt(id);
            this.changed();
        },
        person_link({person_id}) {
            const person = this.$store.getters['persons/person'](person_id);
            if (person === undefined) {
                console.log(`person with ID ${person_id} not found`);
                return "unknown";
            }
            const {firstname, lastname, title, type, hidden, email} = person;
            const name = `${title || ''} ${firstname} ${lastname}`;
            if (person_id !== undefined && type !== 'author' && !hidden) {
                return `<a href="/person/${person_id}">${name}</a>`;
            }
            if (email) {
                return `<a href="mailto:${email}">${name}</a>`;
            }
            return name;
        },
        init_data() {
            this.person = this.$store.getters['persons/person'](this.id);
            this.dissertations = this.$store.getters['dissertations/for_supervisor'](this.id);
            if (this.dissertations !== undefined) {
                this.dissertations.sort((a,b) => b.year - a.year);
            } else {
                this.dissertations = [];
            }
            this.addresses = this.$store.state.addresses.addresses;
        },
    },


    async mounted() {
        this.init_data();
        // this component might be mounted before any data arrived, so on arrival
        // reset data state
        this.$watch(() => this.$store.state.persons.persons,
            (newVal, oldVal) => {
                this.init_data();
            }
        );
        this.$watch(() => this.$store.state.addresses.addresses,
            (newVal, oldVal) => {
                this.init_data();
            }
        );
        this.$watch(() => this.$store.state.dissertations.dissertations,
            (newVal, oldVal) => {
                this.init_data();
            }
        );
        if (this.$route.params.edit) {
            this.edit_mode = true;
        }
    },
}
</script>

<style scoped>


.person-full {
    position: relative;
    padding-top: 50px;
}

.card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}

.card-info {
    margin-left: 40px;
}

select.staff-type {
    display: block;
}

h2.title {
    margin: 0;
}
h1.fullname {
    display: inline;
    margin: 0 1em 0 0;
}

div.address {
    margin: 20px 0;
}

a {
    color: var(--uni-blue);
}

input {
    border: 1px solid #a0a0a0;
}

input.title {
    display: block;
    font-size: 1.5em;
    margin-bottom: 5px;
    padding: 4px;
}

input.fullname {
    font-size: 2em;
    margin-right: 5px;
    padding: 4px;
}

input.role {
    display: block;
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 4px;
}

input.room {
    display: inline;
}

input.address {
    display: inline;
}

span.address-right {
    margin-left: 3px;
}

textarea.infotext {
    width: 100%;
    height: 10em;
    padding: 5px;
    resize: none;
}

.infotext {
    margin-top: 40px;
}

div.infotext::v-deep(h1) {
    font-size: 1.5em;
}

div.infotext::v-deep(h2) {
    font-size: 1.2em;
}

div.address-list {
    margin-top: 2em;
}

.visible-toggle {
    margin-bottom: 1em;
}

.diss-list {
    margin-left: 2em;
    display: inline-grid;
    grid-template-columns: auto auto auto;
    column-gap: 1em;
    row-gap: 0.5em;
}
</style>
