import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Settings from '../views/Settings.vue'
import Jobs from '../views/Jobs.vue'
import People from '../views/People.vue'
import Person from '../views/Person.vue'
import Publication from '../views/Publication.vue'
import Publications from '../views/Publications.vue'
import Course from '../views/Course.vue'
import Courses from '../views/Courses.vue'
import BTFDB from '../views/BTFDB.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/people',
        name: 'People',
        component: People
    },
    {
        path: '/publications',
        name: 'Publications',
        component: Publications
    },
    {
        path: '/jobs',
        name: 'Jobs',
        component: Jobs
    },
    {
        path: '/person/:id',
        name: 'Person',
        component: Person
    },
    {
        path: '/publication/:id',
        name: 'Publication',
        component: Publication
    },
    {
        path: '/btfdb',
        name: 'BTFDB',
        component: BTFDB
    },
    {
        path: '/schultz',
        redirect: '/person/1143',
    },
    {
        path: '/courses',
        name: 'Courses',
        component: Courses
    },
    {
        path: '/course/:id',
        name: 'Course',
        component: Course
    },
    {
        // path: '*',
        path: "/:catchAll(.*)",
        name: 'Fallback',
        component: Home
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
