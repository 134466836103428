<template>
<div class="author_edit">
    <div class="author-edit-list">
        <template v-for="(author, idx) of authors">
            <div class="author-edit-list-name" v-html="person_link(author)" />
            <a href="#" v-if="idx < (authors.length - 1)" @click.prevent="move_author(idx, false)">
                <chevron-down-icon size="1.5x" />
            </a>
            <div v-if="idx == (authors.length - 1)" />
            <a href="#" v-if="idx > 0" @click.prevent="move_author(idx, true)">
                <chevron-up-icon size="1.5x" />
            </a>
            <div v-if="idx == 0" />
            <a href="#" @click.prevent="remove_author(idx)">
                <user-x-icon class="remove-author" size="1.5x" />
            </a>
        </template>
    </div>
    <div class="add-author-line">
        <input class="search-author" placeholder="Search" v-model="query" @input="update_search" />
        <span class="oradd"> or add </span>
        <input class="add-author-name" placeholder="First Name" v-model="add_firstname" />
        <input class="add-author-name" placeholder="Last Name" v-model="add_lastname" />
        <a href="#" @click.prevent="add_author({firstname: add_firstname, lastname: add_lastname}, false)">
            <user-plus-icon class="add-author-button" size="1.5x" />
        </a>
    </div>
    <div v-if="search_results.length" class="search-results">
        <div v-for="author of search_results">
            <a href="#" @click.prevent="add_author(author, false)">{{ author.firstname }} {{ author.lastname }}</a>
        </div>
    </div>
</div>
</template>

<script>
import { ChevronUpIcon,
         ChevronDownIcon,
         UserXIcon,
         UserPlusIcon,
       } from '@zhuowenli/vue-feather-icons';

export default {
    props: [
        'id',
        'editors',
    ],

    components: {
        ChevronUpIcon,
        ChevronDownIcon,
         UserXIcon,
         UserPlusIcon,
    },

    emits: ['change'],

    data: function() {
        return {
            authors: [],
            searchable_authors: [],
            query: "",
            search_results: [],
            add_firstname: "",
            add_lastname: "",
        }
    },

    computed: {
        person_link: () => ({firstname, lastname, title, person_id, type, hidden}) => {
            const name = `${title || ''} ${firstname} ${lastname}`;
            return person_id !== undefined && type !== 'author' && !hidden
                 ? `<a href="/person/${person_id}">${name}</a>`
                 : name;
        },
    },

    methods: {
        move_author: function(idx, up) {
            const idx_a = idx;
            const idx_b = up ? idx - 1 : idx + 1;
            const a = this.authors[idx_a];
            const b = this.authors[idx_b];

            // swap ranks
            const tmp_rank = b.rank;
            b.rank = a.rank;
            a.rank = tmp_rank;

            // swap positions
            this.authors[idx_b] = a;
            this.authors[idx_a] = b;

            this.$emit('change', this.authors);
        },
        move_editor: function(idx, up) {
            const idx_a = idx;
            const idx_b = up ? idx - 1 : idx + 1;
            const a = this.editors[idx_a];
            const b = this.editors[idx_b];

            // swap ranks
            const tmp_rank = b.rank;
            b.rank = a.rank;
            a.rank = tmp_rank;

            // swap positions
            this.editors[idx_b] = a;
            this.editors[idx_a] = b;

            this.$emit('change', this.authors);
        },
        remove_author: function(idx) {
            let crt_rank = this.authors[idx].rank;
            for (let i = idx + 1; i < this.authors.length; i++) {
                const next_rank = this.authors[i].rank;
                this.authors[i].rank = crt_rank;
                crt_rank = next_rank;
            }
            this.authors.splice(idx, 1);
            this.$emit('change', this.authors);
        },
        update_search() {
            if (this.query.length < 2) {
                this.search_results = [];
                return;
            }
            this.search_results = this.searchable_authors.filter((author) => {
                const q = this.query.toLowerCase();
                return (author.firstname || "").toLowerCase().includes(q)
                    || (author.lastname || "").toLowerCase().includes(q);
            });
        },
        add_author: function(author, is_editor) {
            if (!author.firstname.length || !author.lastname.length) {
                return;
            }
            const next_rank = this.authors.length ? (this.authors[this.authors.length - 1].rank + 1) : 1;
            this.authors.push({ ...author, rank: next_rank, is_editor: this.editors });
            this.query = '';
            this.search_results = [];
            this.add_firstname = "";
            this.add_lastname = "";
            this.$emit('change', this.authors);
        },
    },

    async mounted() {
        this.$store.dispatch('authors/fetch').then(() => {
            const all_authors = this.$store.getters['authors/for_publication'](this.id);
            const is_editor = this.editors ? 1 : 0;
            this.authors = all_authors ? all_authors.filter((a) => (a.is_editor || 0) === is_editor) : [];
            this.searchable_authors = this.$store.state.authors.authors;
        });
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.author-edit-list {
    display: grid;
    grid-template-columns: fit-content(800px) 1em 1em 1em;
    column-gap: 1em;
    row-gap: 0.5em;
    margin-left: 2em;
}

.move-author {
    color: var(--uni-blue);
}
.remove-author {
    color: var(--red);
}

div.author-edit-list-name {
    padding: 0;
    padding-right: 50px;
}

input.search-author {
    margin-left: 2em;
}

span.oradd {
    margin: 0 1em;
}

.add-author-name {
    margin-right: 1em;
}

.add-author-line {
    margin-top: 0.5em;
    height: 1.5em;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
}

.add-author-button {
    color: var(--green);
}

div.search-results {
    max-height: 400px;
    border: 1px solid #333;
    overflow: auto;
    margin-left: 2em;
    margin-top: 0.5em;
    width: 400px;
}
</style>
