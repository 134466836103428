import Cite from 'citation-js';
import { get, put, post, del, api_url, file_url } from '@/services/backend'

function
toCSLJSON(pub, authors, editors) {
    // https://isg.beel.org/blog/2012/08/08/docear4word-mapping-bibtex-fields-and-types-with-the-citation-style-language/
    const pub_type = pub.type.toLowerCase();
    const type_map = {
        'article': 'article-journal',
        'book': 'book',
        'booklet': 'pamphlet',
        'inbook': 'chapter',
        'incollection': 'chapter',
        'inproceedings': 'paper-conference',
        'conference': 'paper-conference',
        'manual': 'book',
        'mastersthesis': 'thesis',
        'phdthesis': 'thesis',
        'misc': 'misc',
        'other': 'article',
        'proceedings': 'book',
        'techreport': 'report',
        'patent': 'patent',
        'standard': 'legislation',
        'unpublished': 'manuscript',
    };
    let genre = undefined;
    if (pub_type === 'phdthesis' || pub_type === 'mastersthesis') {
        genre = pub_type;
    }
    authors.sort((a,b) => { return a.rank - b.rank; });
    editors.sort((a,b) => { return a.rank - b.rank; });
    const author_list = authors.map(({firstname, lastname}) => {
        return { family: lastname, given: firstname };
    });
    const editor_list = editors.map(({firstname, lastname}) => {
        return { family: lastname, given: firstname };
    });
    let data = {
        'abstract': pub.abstract,
        'author': author_list,
        'citation-label': pub.bibtex_id,
        'chapter-number': pub.chapter,
        'collection-editor': editor_list,
        'collection-title': pub.journal || pub.series || pub.booktitle,
        'container-author': editor_list,
        'DOI': pub.doi,
        'edition': pub.edition,
        'editor': editor_list,
        'id': pub.bibtex_id,
        'ISBN': pub.isbn,
        'issue': pub.number,
        'issued': pub.year ? {
            'date-parts': [[parseInt(pub.year),], ]
        } : undefined,
        'ISSN': pub.issn,
        'keyword': pub.keywords,
        'note': pub.note,
        'number': pub.number,
        'page-first': pub.firstpage,
        'publisher': pub.publisher,
        'school': pub.school,
        'institution': pub.institution,
        'publisher-place': pub.location || pub.address,
        'title': pub.title || pub.chapter,
        'type': type_map[pub_type],
        'genre': genre,
        'URL': pub.url,

    };
    if (pub.month) {
        data.issued['date-parts'][0].push(parseInt(pub.month));
    }
    if (pub.lastpage && pub.firstpage) {
        data['number-of-pages'] = parseInt(pub.lastpage)
                                - parseInt(pub.firstpage);
        data['page'] = `${pub.firstpage}-${pub.lastpage}`;
    }

    return [data,];
}

export const publications = {
    namespaced: true,

    state: {
        publications: {},
        for_author: {},
    },
    getters: {
        all: (state) => {
            return Object.values(state.publications);
        },
        single: (state) => (id) => {
            return state.publications[parseInt(id)];
        },
        author_has_publications: (state) => (id) => {
            return state.for_author[id]?.length ? true : false;
        },
        for_author: (state) => (id) => {
            const ids = state.for_author[id];
            if (ids === undefined) {
                return [];
            }
            return ids.map(id => state.publications[id]);
        },
        publication: (state) => (id) => {
            return state.publications[parseInt(id)];
        },
        teaser: (state) => (publication) => {
            if (publication.teaser === undefined) {
                return undefined;
            }
            return `${file_url}/${publication.teaser}`;
        },
        paper: (state) => (publication) => {
            return `${api_url}/files/${publication.pdf}`;
        },
        bibtex: (state) => (publication, authors, editors) => {
            const cite = new Cite(toCSLJSON(publication, authors, editors));
            return cite.format('bibtex');
        },
    },
    mutations: {
        add(state, pub) {
            state.publications[parseInt(pub.pub_id)] = pub;
            const authors = pub.authors;
            if (authors) {
                for (const author of authors) {
                    const id = author.person_id;
                    if (state.for_author[id] === undefined) {
                        state.for_author[id] = [];
                    }
                    state.for_author[id].push(pub.pub_id);
                }
            }
        },
        set(state, pubs) {
            state.publications = pubs;
            for (const pub in pubs) {
                const authors = pubs[pub].authors;
                if (authors) {
                    for (const author of authors) {
                        const id = author.person_id;
                        if (state.for_author[id] === undefined) {
                            state.for_author[id] = [];
                        }
                        state.for_author[id].push(pub);
                    }
                }
            }
        },
        update(state, data) {
            const id = parseInt(data.pub_id);
            state.publications[id] = data;
        },
        set_for_author(state, {id, pubs}) {
            state.for_author[id] = pubs;
            for (const pub of pubs) {
                if (state.publications[pub.pub_id] === undefined) {
                    state.publications[pub.pub_id] = pub;
                }
            }
        },
    },
    actions: {
        async fetch({ commit, state, rootGetters }) {
            const token = rootGetters['auth/token'];
            const pubs = await get(`/publications/`, token);
            if (pubs === undefined || pubs.pubs === undefined || !Object.values(pubs.pubs).length) return;
            commit('set', pubs.pubs);
        },
        async add({commit, state, rootGetters}, data) {
            const token = rootGetters['auth/token'];
            let updated = await post(`/publications`, data, token);
            commit('add', updated.pub);
            return updated.pub;
        },
        async update({commit, state, rootGetters}, data) {
            const token = rootGetters['auth/token'];
            const pid = data.publication.pub_id;
            let updated = await put(`/publications/${pid}`, data, token);
            commit('update', updated.pub);
            return updated.pub;
        },
        async fetch_for_author({ commit }, id) {
            const pubs = await get(`/publications/for-author/${id}`);
            if (pubs === undefined || pubs.pubs === undefined || !pubs.pubs.length) return;
            commit('set_for_author', {id, pubs:pubs.pubs});
        },
    },
};
